import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';

export const UPDATE_ENV_FORM_ID = 'update-env-form';

export function UpdateEnvForm(props) {
  const [name, setName] = useState(props.name);
  const [description] = useState('');
  const [url, setUrl] = useState(props.url);
  const [isProduction] = useState(props.isProduction);

  const handleNameChange = (e, { value }) => setName(value);
  const handleUrlChange = (e, { value }) => setUrl(value);

  const handleSubmit = e => {
    e.preventDefault();
    const { envId: id, updateEnvironment, toggleModal } = props;
    updateEnvironment({
      variables: {
        id,
        name,
        description,
        url,
        isProduction,
      },
    }) && toggleModal();
  };

  const { loading, errors } = props;
  return (
    <Form
      onSubmit={handleSubmit}
      id={UPDATE_ENV_FORM_ID}
      loading={loading}
      error={errors.length > 0}
    >
      <Form.Field>
        <Form.Input
          id="name"
          label="Environment Name"
          placeholder={props.name}
          required
          autoFocus
          value={name}
          onChange={handleNameChange}
        />
        <Form.Input
          id="url"
          label="Environment URL"
          placeholder={props.url}
          value={url}
          onChange={handleUrlChange}
        />
        <Form.Radio
          id="isProduction"
          toggle
          label="Production Environment"
          checked={isProduction === true}
        />
      </Form.Field>
    </Form>
  );
}

UpdateEnvForm.propTypes = {
  appId: PropTypes.string.isRequired,
  envId: PropTypes.string.isRequired,
  updateEnvironment: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  errors: PropTypes.array,
};
UpdateEnvForm.defaultProps = {
  errors: [],
};

export default UpdateEnvForm;
