import React from 'react';
import { useQuery } from 'react-apollo';
import MiniAppCard from '../../components/MiniCard/MiniAppCard';
import CardsLoader from '../../components/CardsLoader';
import Error from '../../components/Error';
import { Message } from 'semantic-ui-react';
import { Grid, Button, Card as SemanticCard } from 'semantic-ui-react';
import { StyledHeader, StyledSection } from '../../styles/typed_components';
import { Link } from 'react-router-dom';
import gql from 'graphql-tag';

const GET_USER_APPS = gql`
  query UserApps_GetUserApps($id: ID!) {
    user(id: $id) {
      apps {
        id
        name
        appType {
          id
          name
        }
      }
    }
  }
`;

interface IUser {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  jobFamily: string;
  isAdmin: boolean;
}

interface IApp {
  id: string;
  name: string;
  appType: {
    name: string;
  };
}

const UserApps = ({ user }: { user: IUser }) => {
  const { loading, error, data } = useQuery(GET_USER_APPS, { variables: { id: user.id } });

  if (loading) return <CardsLoader />;

  if (error) {
    return <Error header="An error occurred while fetching your apps" error={error} />;
  }

  const appsCount = data.user.apps.length;

  if (appsCount === 0) {
    return (
      <Message
        size="large"
        color="teal"
        content="You are not an admin on any apps. If you were, they would be listed here!"
      />
    );
  }

  // Reverse the apps array which is sorted by creation date, oldest to newest,
  // so we can display most recently created apps
  const userApps: IApp[] = data.user.apps.reverse();
  // Change this to change the number of apps displayed
  const displayLimit = 16;
  // if the user's app count is less than the display limit, display all their apps
  let appsToDisplay = userApps;

  if (appsCount > displayLimit) {
    appsToDisplay = userApps.slice(0, displayLimit);
  }

  return (
    <StyledSection background={'rgba(0,0,0,0)'}>
      <StyledHeader padding={'0 0.75em 0 0.75em;'}>
        My Apps
        {appsCount > displayLimit && (
          <Button
            content="View All"
            as={Link}
            to={'/person/' + user.id}
            basic
            size="mini"
            floated="right"
          />
        )}
      </StyledHeader>
      <Grid container title="MyApps">
        <Grid.Row style={{ marginTop: '0.5rem' }}>
          <Grid.Column width={16}>
            <SemanticCard.Group itemsPerRow={4} stackable>
              {appsToDisplay.map((app: IApp) => {
                return <MiniAppCard key={app.id} app={app} />;
              })}
            </SemanticCard.Group>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      {appsCount > displayLimit && (
        <div style={{ display: 'flex', justifyContent: 'right' }}>
          <div style={{ paddingRight: '1em', paddingTop: '1.2em', marginLeft: 'auto' }}>
            Showing {appsToDisplay.length} of {appsCount}
          </div>
        </div>
      )}
    </StyledSection>
  );
};

export default UserApps;
