import React, { Fragment, FC, MouseEvent } from 'react';
import { Header, Grid, DropdownItemProps } from 'semantic-ui-react';
import { IClient } from './ClientUsage';
import ChooseClientUsageDropdown from './ChooseClientUsageDropdown';
import ClientUsageDetail from './ClientUsageDetail';
import UsageHeader from './UsageHeader';

interface IProps {
  client: IClient;
  clients: IClient[];
  handleButtonClick: (event: MouseEvent<HTMLDivElement>, data: DropdownItemProps) => void;
}

const ClientUsagePane: FC<IProps> = ({ client, clients, handleButtonClick }: IProps) => {
  return (
    client && (
      <Fragment>
        <Grid.Row columns="2">
          <Grid.Column floated="right" width="11" textAlign="right" verticalAlign="middle">
            <Header as="h3">Select a client:</Header>
          </Grid.Column>
          <Grid.Column floated="right" width="5">
            <ChooseClientUsageDropdown
              clients={clients}
              handleClick={handleButtonClick}
              selectedClient={client}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <UsageHeader client={client} />
        </Grid.Row>
        <Grid.Row>
          <ClientUsageDetail client={client} clientId={client.clientId} />
        </Grid.Row>
      </Fragment>
    )
  );
};

export default ClientUsagePane;
