import React from 'react';
import { Container } from 'semantic-ui-react';
import ReactMarkdown from 'react-markdown';

const input = `# Marvin FAQ ⚡️

- [How do I register my team?](#register-team)
- [How do I register my app?](#register-app)
- [Auth all the things!](#auth)
- [What is the idea behind the Approval Workflow?](#approval)
- [Implementation](#implementation)
- [Other Questions](#question)




## <a id="register-team"></a>How do I register my team?
Go to [Marvin](/teams) to register a team. You'll need to sign in with your RV account and then grant Marvin access. Complete the necessary information. Example:
- **Team name:**  Developer Experience
- **Description:** The Developer Experience team makes things for devs!
- **Members:** Select team members. If they don't show up, ask them to log in to [Marvin](/).
## <a id="register-app"></a>How do I register my app?
After registering or joining your team, go to [Marvin](/apps) to register an app.
Multiple app types can be registered in Marvin. (APIs/service/SPAs) We will refer to them collectively as "Marvin Apps".
Marvin Apps belong to teams and have admins. A Marvin App admin can do things like edit the app and accept/reject access requests (what requests? we'll get to that). 🤓
A Marvin App can have multiple environments. Environments can be prod or non-prod. A good rule of thumb is that a prod environment uses prod data. Example:
- **Name:** marvin-directory-api
- **Description:** A Go API that handles all things related to registering apps, teams, and users.
- **Repo URL:** https://github.com/RedVentures/marvin-directory-api
- **Team:** Developer Experience
- **Admins:** Jon Hester, Meredith Fronk
## <a id="auth"></a>Auth all the things!
Authentication is a two-part process. A Marvin App asks for access to data owned by another Marvin App and a client requests access to a resource.
We call the Marvin App requesting access a **client**. 💻
We call the Marvin App that owns the data a **resource**. 🔒
A client requests access to a resource. 💻 ➡ 🔒
### How do I create a Resource?
- **Environment:** Make an entry for each environment your application will need for development, testing, and production. You can enter multiple production and non-production environments. An environment is considered production if it deals with production data. Apps in non-prod environments *cannot* talk to apps in prod environments and vice versa.
- **Name:** Friendly name.
- **Identifier:** A slug for your api, it's unique and can't be updated. It can be helpful to assign a prefix noting the environment like \`dev-directory-api\`.  This is the **audience** as well.
- **Scopes:** Scopes are how we handle authorization. Authorization is what level of access your resource will grant clients. You can create read-only scopes, admin scopes, or any other construct that works for your resource.
### How do I create a Client?
- **Environment:** You set these up in your app. The thing to note here is that the environment between client and resource *needs to match*. They are both prod or both non-prod.
- **Name:** Friendly name.
- **Description:** A description of the client.
#### API only fields
- **Client App Type:** Right now there's only one choice, so easy! Currently we are supporting "Machine to Machine" which means no users. 😔
- **Resource:** Choose the resource this client is going to request access to.
#### SPA only fields
- **Callbacks:** After the user authenticates we will only call back to these URLs. You can specify multiple valid URLs (typically to handle different environments like QA or testing). Make sure to specify the protocol, http:// or https://, otherwise the callback may fail.
- **Logout URLs:** A set of valid URLs to redirect to after logout. When a user logs out, you can redirect them with the returnTo query parameter. The URL that you use in returnTo must be listed here.

### What is a Tenant?
- **Tenant:** An isolated sub-account in Auth0. This name needs to be unique and is used to create your Auth0 domain.  We have separate tenants for production and non-production.
- **Tenant Domain:** Your tenant name appended to auth0.com. Example-  redventures-dev is the Tenant Domain for  https://redventures-dev.auth0.com
- **Tenant URL:** The full tenant URL. Example-  https://redventures-dev.auth0.com

### So this client secret thing, what is it exactly?
Think of a client secret as another name for a password, and a client ID as another name for a username.
- 😬 Client ID = Username
- 🔒 Client Secret = Password
### So I’m not supposed to store the client secret client side… then how does the server get it??
Great question! The client secret actually isn’t the secret for your client at all. 🤯 Confused? Just wait.
Let’s say ARVY is the client, requesting data from Marvin (for example):
- Arvy would have a client id
- Marvin would have a resource id
- 🎉 Surprise! Marvin also has a client id, because… it too has a resource! It’s called Directory. So to directory, Marvin has to have a client id to “log in” (I mean, it is a user after all, right?)
That means that Marvin also gets assigned a client secret to go along with its client id.

In this scenario, when Arvy requests data from Marvin, Marvin has to provide its client ID and client secret to Directory, so Directory knows it’s really him. So that’s why the client secret lives in the resource, because the resource can also be a client. Or as @calbert phrased it, “Anything can be a door if you put a lock on it…” or something like that. Don’t quote me quoting him.

Once Directory knows Marvin is who he says he is, Directory can provide Marvin with the information he requested, so that Arvy can get that info from Marvin.
### That’s great if I have a client and a resource, but what about if I have a back-end for front-end? What if my client and resource are the same thing?
That is a great question. As it stands, it seems like it would just make sense to pass up user creds, check the creds, give access, and call it a day right? Well, with Auth0 you have more options than that. If you’d like to understand the best flow for your app, checkout Auth0's documentation here for some tips --> https://auth0.com/docs/flows

## <a id="approval"></a>What is the idea behind the Approval Workflow?
### Why?
In many cases your team may own all the clients and resources you want to set up. In that case this step is unnecessary. Think about creating a resource with HR data that lots of teams across the org want access to. You will probably want to know who's asking for what access and to be able to deny access to sensitive data. ✅
### When?
When you request access to a resource from your client, a request is created for the Marvin App admins (we mentioned this 👆).
### How?
A Marvin App admin can log in to [Marvin requests](/requests) and click approve.



## <a id="implementation"></a>Implementation
- [nodejs](https://github.com/RedVentures/sdk-node/tree/master/packages/auth) @oosby @towalker
- [go](https://github.com/RedVentures/sdk-go/tree/master/auth) @mfronk @jhester
- [C#](https://github.com/RedVentures/csharp-sdk-auth) @calbert
## <a id="question"></a>Don't see your question answered here? Contact the [Platform Tools] (mailto:platform-tools@redventures.com) team!
`;

const Docs = () => (
  <Container>
    <ReactMarkdown source={input} escapeHtml={false} />
  </Container>
);

export default Docs;
