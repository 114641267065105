import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';
import { Query } from 'react-apollo';
import getErrorMessages from '../util/errors';

import Error from './Error';
import { DocumentNode } from 'graphql';

interface IProps {
  query: DocumentNode;
  children: Function;
  label: string;
  variables: any[];
}

const QueryField = ({ query, variables, children, label }: IProps) => (
  <Query<any, any> query={query} variables={variables}>
    {({ loading, error, data }) => {
      const errors = getErrorMessages(error);
      if (errors.length > 0) {
        return (
          <Form.Field required>
            <label>{label}</label>
            <Error errors={errors} />
          </Form.Field>
        );
      }

      return children(loading, data);
    }}
  </Query>
);

QueryField.propTypes = {
  query: PropTypes.object.isRequired,
  children: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
};

export default QueryField;
