import React, { FC, ReactElement } from 'react';
import { Message, Icon } from 'semantic-ui-react';

interface IProps {
  clientId: string;
  tenant: string;
}

const NoUsageMessage: FC<IProps> = ({ clientId, tenant }: IProps): ReactElement => {
  return (
    <Message key={`client-${clientId}`}>
      <Icon name="info" circular />
      No token usage data for the last 2 weeks was found in the {tenant} tenant for this client.
    </Message>
  );
};

export default NoUsageMessage;
