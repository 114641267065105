import React, { Component, Fragment } from 'react';
import { List } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import AppAccordion from '../../../components/AppAccordion';
import Gravatar from '../../../components/Gravatar';

class AdminTab extends Component {
  render() {
    const { members } = this.props;

    const accordionItems = [
      {
        accordionDetails: '',
        accordionTitle: 'What can Admins do?',
        accordionItemText: [
          {
            key: 'register',
            title: 'Register',
            details: 'Permissions, Clients, and Resources',
          },
          {
            key: 'approve',
            title: 'Approve',
            details: 'Resource Access',
          },
          {
            key: 'remove',
            title: 'Remove',
            details: 'Clients, Resources, or the Application itself',
          },
        ],
      },
    ];

    return (
      <Fragment>
        <AppAccordion accordionItems={accordionItems} />
        <List relaxed divided>
          {members.map(({ id, firstName, lastName, email }) => (
            <List.Item id={id} key={`member-${id}`}>
              <Gravatar email={email} />
              <List.Content>
                <List.Header as={Link} to={`/person/${id}`}>
                  {`${firstName} ${lastName}`}
                </List.Header>
              </List.Content>
            </List.Item>
          ))}
        </List>
      </Fragment>
    );
  }
}

AdminTab.displayName = 'AdminTab';

AdminTab.propTypes = {
  members: PropTypes.arrayOf(PropTypes.object),
};

AdminTab.defaultProps = {
  members: [],
};

export default AdminTab;
