import React, { FC } from 'react';
import { Header, Container } from 'semantic-ui-react';
import { IClient } from './ClientUsage';
import { Link } from 'react-router-dom';

interface IProps {
  client: IClient;
}

const UsageHeader: FC<IProps> = ({ client }) => (
  <Container fluid>
    <Header textAlign="center" as={'h1'}>
      <Header.Content>{client.name} Auth0 Token Usage</Header.Content>
      {client.app && (
        <Header.Subheader>
          Application: <Link to={`/app/${client.app.id}`}>{client.app.name}</Link>
        </Header.Subheader>
      )}
      <Header.Subheader>Tenant: {client.tenant}</Header.Subheader>
    </Header>
  </Container>
);

export default UsageHeader;
