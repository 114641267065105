import React from 'react';
import Card from '.';

interface IApp {
  id: string;
  name: string;
  appType: {
    name: string;
  };
}

const MiniAppCard = ({ app }: { app: IApp }) => (
  <Card key={app.id} title={app.name} subtitle={app.appType.name} linkto={`/app/${app.id}`} />
);

export default MiniAppCard;
