import { SemanticICONS } from 'semantic-ui-react';

export const statusColors = {
  approved: 'green',
  cancelled: 'grey',
  failed: 'purple',
  pending: 'yellow',
  rejected: 'red',
};
export const statusIcons = {
  approved: 'check',
  cancelled: 'trash',
  failed: 'cancel',
  pending: 'wait',
  rejected: 'cancel',
};
export const statuses = ['pending', 'approved', 'cancelled', 'rejected', 'failed'];
// TODO: find better way match this
export const requestTypeIds = {
  AUTH_ROLE: '01DAW3QP1JBJJNA6SGMNSCXF5V',
};
export const registryIcons: {
  apps: SemanticICONS;
  teams: SemanticICONS;
  blog: SemanticICONS;
  overflow: SemanticICONS;
  users: SemanticICONS;
  directory: SemanticICONS;
  unknown: SemanticICONS;
  [key: string]: SemanticICONS;
} = {
  apps: 'cogs',
  teams: 'users',
  blog: 'newspaper outline',
  overflow: 'discussions',
  users: 'user',
  directory: 'cogs',
  unknown: 'question circle outline',
};

export enum ROUTE_MAP {
  apps = 'app',
  teams = 'team',
  users = 'user',
}
