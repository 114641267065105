import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Card as SemanticCard } from 'semantic-ui-react';
import { withTheme } from 'emotion-theming';
import { ArrowRight } from 'react-feather';
import { Link } from 'react-router-dom';
import { PropStyles } from '../../styles/helpers';
import { Header } from '../../styles/components';
import styled from '../../styled';

interface IColors {
  colors: {
    primary: string;
    secondary: string;
    tertiary: string;
    textColor: string;
    white: string;
    error: string;
  };
}

interface IProps {
  title: string;
  details: any[];
  linkto: string;
}

/*
 ** Variations of Cards Based on props.variant
 */
const CardVariants = PropStyles('variant', ({ colors }: IColors) => {
  return {
    positive: {
      '.status-icons svg': {
        color: colors.secondary,
        '&:last-child': {
          fill: colors.secondary,
        },
      },
      '&:hover, &:active, &:focus': {
        border: `1px solid ${colors.secondary}`,
      },
    },
    negative: {
      '.status-icons svg': {
        color: colors.error,
        '&:last-child': {
          fill: colors.error,
        },
      },
      '&:hover, &:active, &:focus': {
        border: `1px solid ${colors.error}`,
      },
    },
  };
});

const StyledCard = styled(SemanticCard)`
  &&&& {
    box-shadow: none;
    overflow: hidden;
    border-radius: 0.25rem;
    padding: 1rem 0.5rem 1rem;
    border: 1px solid ${props => props.theme.colors.lightgrey};
    transition: all 0.1s ease-in-out;
    text-align: center;
    color: ${props => props.theme.colors.textColor};
    ${CardVariants};
    .view-details {
      display: flex;
      align-items: center;
    }
    .details {
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    ${Header} {
      font-size: 1.75rem;
      margin-top: 0.5rem;
    }
    p.view-details {
      position: relative;
      color: ${props =>
        props.variant === 'positive' ? props.theme.colors.secondary : props.theme.colors.error};
      justify-content: center;
      transition: all 0.1s ease-in-out;
      transform: translateY(1rem);
      opacity: 0;
    }
    &:hover {
      /* If used AS a Link Semantic has additional styles we need to override since we will probably be doing that */
      transform: none;
      box-shadow: none;
      p.view-details {
        transform: translateY(0);
        opacity: 1;
      }
    }
  }
`;

class Card extends Component<IProps> {
  static propTypes = {
    title: PropTypes.string,
    variant: PropTypes.oneOf(['positive', 'negative']),
    details: PropTypes.arrayOf(PropTypes.string),
    linkto: PropTypes.string,
  };

  static defaultProps = {
    title: 'Monocle',
    variant: 'positive',
    details: [],
    linkto: '',
  };

  render() {
    const { title, details, linkto } = this.props;
    return (
      <StyledCard {...this.props} as={Link} to={linkto}>
        <Header textAlign="center">{title}</Header>
        {details.map((d, i) => (
          <p key={i} className="details">
            {d}
          </p>
        ))}
        <p className="view-details">
          View Details <ArrowRight />
        </p>
      </StyledCard>
    );
  }
}

export default withTheme(Card);
