import React from 'react';

import CreateApp from '../scenes/app/CreateApp';
import TabPane from './TabPane';
import AppCard from './Card/AppCard';

interface IApp {
  id: string;
  name: string;
  description: string;
  appType: {
    id: string;
    name: string;
  };
  team?: {
    id: string;
    name: string;
  };
}

interface IProps {
  me?: {
    id: string;
  };
  apps?: IApp[] | undefined;
  defaultTeam?: {
    id: string;
    name: string;
  };
  showCreateApp: boolean;
}

class AppPane extends React.Component<IProps> {
  filteredApps = (filterValue: string) => {
    const { apps } = this.props;
    const term = filterValue.toLowerCase();

    if (term.length === 0) {
      return apps;
    }

    return (
      apps &&
      apps.filter(
        app =>
          app.name.toLowerCase().includes(term) ||
          app.description.toLowerCase().includes(term) ||
          (app.team && app.team.name.toLowerCase().includes(term))
      )
    );
  };

  render() {
    const { me, defaultTeam, showCreateApp } = this.props;

    return (
      <TabPane
        filterFn={this.filteredApps}
        createButton={showCreateApp && <CreateApp me={me} defaultTeam={defaultTeam} />}
      >
        {(app: IApp) => <AppCard app={app} key={`card-${app.id}`} />}
      </TabPane>
    );
  }
}

export default AppPane;
