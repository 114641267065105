import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Button, Icon } from 'semantic-ui-react';
import { Mutation } from 'react-apollo';
import { toast } from 'react-toastify';
import gql from 'graphql-tag';
import getErrorMessages from '../../util/errors';

import ModalState from '../../layouts/ModalState';
import ModalForm from '../../layouts/ModalForm';
import AppForm, { APP_FORM_ID } from './AppForm';

const GET_APP = gql`
  query UpdateApp_GetApp($id: ID!) {
    app(id: $id) {
      id
      name
      description
      repoUrl
      appType {
        id
        name
        description
        showRoles
        showEnvironmentsTab
        showClientTab
        authClientType
        showResourceTab
        showClientGrants
        showCallbacksField
        showLogoutURLsField
        showAllowedOriginsField
      }
      team {
        id
        name
        description
      }
      members {
        id
        firstName
        lastName
        email
        jobFamily
      }
      environments {
        id
        name
        url
        isProduction
        tenant
      }
    }
  }
`;

const ME = gql`
  query UpdateApp_GetMe {
    me {
      id
      isAdmin
      firstName
      lastName
      email
      jobFamily
      hash
      teams {
        id
        name
        description
      }
    }
  }
`;

const UPDATE_APP = gql`
  mutation UpdateApp_UpdateApp(
    $id: ID!
    $name: String!
    $description: String!
    $repoUrl: String!
    $teamId: ID!
    $members: [String!]!
    $appTypeId: ID!
  ) {
    updateApp(
      id: $id
      app: {
        name: $name
        description: $description
        repoUrl: $repoUrl
        teamId: $teamId
        members: $members
        appTypeId: $appTypeId
      }
    ) {
      success
      error {
        message
        code
      }
      app {
        id
      }
    }
  }
`;

class UpdateApp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { app } = this.props;

    return (
      <ModalState>
        {(modalOpen, toggleModal) => (
          <Fragment>
            <Button id="edit-app" size="tiny" onClick={toggleModal}>
              <Icon name="pencil" />
              Edit
            </Button>
            {modalOpen && (
              <Mutation
                mutation={UPDATE_APP}
                refetchQueries={[
                  {
                    query: GET_APP,
                    variables: { id: app.id },
                  },
                  {
                    query: ME,
                  },
                ]}
                awaitRefetchQueries
                onCompleted={() => {
                  toggleModal();
                  toast.success(`Successfully updated ${app.appType.name}!`);
                }}
                onError={error =>
                  toast.error(`Error encountered: ${getErrorMessages(error)}`, {
                    autoClose: false,
                  })
                }
              >
                {(updateApp, { loading, error }) => {
                  const errors = getErrorMessages(error);

                  return (
                    <ModalForm
                      header={`Update ${app.appType.name}: ${app.name}`}
                      actionAttr={{
                        positive: true,
                        type: 'submit',
                        form: APP_FORM_ID,
                      }}
                      loading={loading}
                      open={modalOpen}
                      toggleModal={toggleModal}
                      errors={errors}
                      actionText={`Update ${app.appType.name}`}
                    >
                      <AppForm
                        onSubmit={updateApp}
                        loading={loading}
                        app={app}
                        errors={errors}
                        updating={true}
                      />
                    </ModalForm>
                  );
                }}
              </Mutation>
            )}
          </Fragment>
        )}
      </ModalState>
    );
  }
}

UpdateApp.propTypes = {
  app: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    appType: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default UpdateApp;
