import React, { Fragment, FC } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Link, NavLink } from 'react-router-dom';
import Logo from './marvin-logo.svg';
import { Menu, Dropdown, Icon, Image } from 'semantic-ui-react';
import { Query } from 'react-apollo';
import {
  StyledNavBar,
  StyledLink,
  StyledExternalLink,
  StyledSup,
  StyledHeader,
} from '../../styles/typed_components';
import gql from 'graphql-tag';
import RequestsBadge from './RequestsBadge';
import HighTokenUsageBadge from '../TokenUsage/HighTokenUsageBadge';
import { useFlags } from 'launchdarkly-react-client-sdk';

export const GET_AUTH_DATA = gql`
  query NavBar_GetAuth {
    userName @client
    isAuthenticated @client
    userImage @client
    jobFamily @client
  }
`;

const ME = gql`
  query NavBar_GetMe {
    me {
      id
      isAdmin
      firstName
      lastName
      email
      jobFamily
      hash
      teams {
        id
        name
        description
      }
    }
  }
`;

interface IProps extends RouteComponentProps {
  onLogout: Function;
  location: any;
}

type NavLinks = {
  to: string;
  text: string;
  slug: string;
  superScriptText?: string;
  external?: boolean;
};

const navigationLinks: Array<NavLinks> = [
  {
    to: '/apps',
    text: 'Apps',
    slug: 'apps',
  },
  {
    to: '/teams',
    text: 'Teams',
    slug: 'teams',
  },
  {
    to: 'https://marvin.rvdocs.io',
    text: 'Docs',
    slug: 'docs',
    external: true,
  },
];

const NavBar: FC<IProps> = props => {
  const {
    location: { pathname },
    onLogout,
  } = props;

  const { userTokenUsage, highUsageAdmin } = useFlags();

  return (
    <StyledNavBar>
      <Link to="/" className="logo-wrapper">
        <Logo />
      </Link>
      <input type="checkbox" id="nav_toggle_cb" />
      <label htmlFor="nav_toggle_cb" id="nav_toggle_btn">
        <Icon name="bars" />
      </label>
      <div className="menu__container">
        <ul>
          {navigationLinks.map(link => {
            return (
              <li key={link.slug}>
                {link.external ? (
                  <StyledExternalLink href={link.to} target="_blank" rel="noopener">
                    {link.text}{' '}
                    {link.superScriptText ? <StyledSup>{link.superScriptText}</StyledSup> : null}
                  </StyledExternalLink>
                ) : (
                  <StyledLink
                    className={
                      pathname.includes(link.slug) || pathname === link.to ? 'active' : 'inactive'
                    }
                    to={link.to}
                  >
                    {link.text}{' '}
                    {link.superScriptText ? <StyledSup>{link.superScriptText}</StyledSup> : null}
                  </StyledLink>
                )}
              </li>
            );
          })}
        </ul>

        <Query query={GET_AUTH_DATA}>
          {({ data }: { data: any }) => {
            const { isAuthenticated, userName, userImage } = data;
            if (!isAuthenticated) return null;
            return (
              <Menu.Menu>
                <Dropdown
                  trigger={
                    <Fragment>
                      <Image size="mini" avatar src={userImage} />
                      <RequestsBadge />
                      {userTokenUsage && <HighTokenUsageBadge floating="left" />}
                    </Fragment>
                  }
                  pointing="top right"
                  icon={null}
                >
                  <Dropdown.Menu>
                    <Query<any, any> query={ME}>
                      {({ loading, error, data }) => {
                        if (loading || error) return null;
                        return (
                          <Fragment>
                            <Dropdown.Header>
                              <Menu.Item as={NavLink} to={`/person/${data.me.id}`}>
                                <Icon name="user" />
                                {userName}
                              </Menu.Item>
                            </Dropdown.Header>
                            <Menu.Item as={NavLink} to="/requests">
                              <Icon name="paper plane" />
                              <RequestsBadge />
                              Requests
                            </Menu.Item>
                            {userTokenUsage && (
                              <Menu.Item as={NavLink} to={`/person/${data.me.id}`}>
                                <Icon name="chart line" />
                                <HighTokenUsageBadge floating="right" />
                                Token Usage
                              </Menu.Item>
                            )}
                            {highUsageAdmin && data.me.isAdmin && (
                              <Menu.Item as={NavLink} to={`/usage`}>
                                <Icon name="chart pie" />
                                High Token Usage
                              </Menu.Item>
                            )}
                          </Fragment>
                        );
                      }}
                    </Query>
                    <Menu.Item onClick={() => onLogout()} name="Logout" icon="log out" />
                  </Dropdown.Menu>
                </Dropdown>
              </Menu.Menu>
            );
          }}
        </Query>
        <div className="nav__header">
          <label htmlFor="nav_toggle_cb" id="nav_untoggle_btn">
            <Icon name="close" />
          </label>
          <StyledHeader as={'h2'}>Menu</StyledHeader>
        </div>
      </div>
    </StyledNavBar>
  );
};

export default withRouter(NavBar);
