import React from 'react';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';
import GridLayout from '../../layouts/GridLayout';
import TeamDetail from './TeamDetail';
import Error from '../../components/Error';
import ContainerLoader from '../../components/ContainerLoader';
import gql from 'graphql-tag';
import { Item, Icon, Grid } from 'semantic-ui-react';
import UpdateTeam from './UpdateTeam';
import DeleteTeam from './DeleteTeam';
import DetailPaneContent from '../../components/DetailPane';

const GET_TEAM_AND_APP_TYPES = gql`
  query Team_GetTeam($id: ID!) {
    team(id: $id) {
      id
      name
      description
      apps {
        id
        name
        description
        appType {
          id
          name
        }
        environments {
          id
          isProduction
        }
      }
      members {
        id
        isAdmin
        firstName
        lastName
        email
        jobFamily
      }
    }
  }
`;

const Team = ({
  match: {
    params: { id },
  },
  me,
}) => {
  return (
    <GridLayout>
      <Query query={GET_TEAM_AND_APP_TYPES} variables={{ id }}>
        {({ loading, error, data }) => {
          if (loading) return <ContainerLoader />;

          const ids = data.team.members.map(member => member.id);

          const detailPaneContent = {
            description: data.team.description,
            descriptionTitle: 'ABOUT',
          };

          let isMember = false;

          if (ids.includes(me.id)) {
            isMember = true;
          }

          if (error) {
            return <Error header="I’m not getting you down at all am I?" error={error} />;
          }
          return (
            <Grid>
              <Grid.Row>
                <Grid.Column>
                  <Item.Header
                    style={{
                      fontWeight: 'bold',
                      fontSize: '2em',
                      marginBottom: '.5em',
                    }}
                  >
                    <Icon name="users" size="large" color="grey" />
                    <p>{data.team.name}</p>
                  </Item.Header>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={3}>
                  <DetailPaneContent content={detailPaneContent} />
                  {(isMember || me.isAdmin) && (
                    <div style={{ marginBottom: 20 }}>
                      <UpdateTeam team={data.team} />
                      <DeleteTeam team={data.team} />
                    </div>
                  )}
                </Grid.Column>
                <Grid.Column width={13}>
                  <TeamDetail me={me} team={data.team} appTypes={data.appTypes} />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          );
        }}
      </Query>
    </GridLayout>
  );
};

Team.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  me: PropTypes.object.isRequired,
};

export default Team;
