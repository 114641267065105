import React from 'react';
import { Container, Tab } from 'semantic-ui-react';
import GridLayout from '../../layouts/GridLayout';
import RequestsPane from './RequestsPane';

const Requests = () => {
  const panes = [
    {
      menuItem: { key: 'incoming', content: 'Incoming' },
      render: () => <RequestsPane />,
    },
    {
      menuItem: { key: 'outgoing', content: 'Outgoing' },
      render: () => <RequestsPane outgoing />,
    },
  ];

  return (
    <GridLayout title="Requests" icon="paper plane" iconColor="grey">
      <Container fluid>
        <Tab menu={{ secondary: true, pointing: true }} panes={panes} />
      </Container>
    </GridLayout>
  );
};

export default Requests;
