import React, { Component, SyntheticEvent } from 'react';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';
import _ from 'lodash';

import QueryDropdown from '../QueryDropdown';
import gql from 'graphql-tag';
import Error from '../Error';

const GET_ENV = gql`
  query ChooseResource_GetEnv($id: ID!) {
    environment(id: $id) {
      name
      description
      url
      isProduction
      createdAt
      updatedAt
    }
  }
`;

const GET_TENANT_RESOURCES = gql`
  query ChooseResource_GetTenantResources($envId: ID!) {
    tenantResources(envId: $envId) {
      resourceId: resource_id
      name
      identifier
      envId: environment_id
      tenant
      app {
        id
        name
      }
    }
  }
`;

interface IProps {
  envId: string;
  excludedResources: string[];
  onChange(event: SyntheticEvent<HTMLElement, Event>, data: any): void;
  resourceId: string;
}

interface IApp {
  name: string;
}

interface ITenantResources {
  resourceId: string;
  name: string;
  identifier: string;
  tenant: string;
  app: IApp;
}

class ChooseResource extends Component<IProps> {
  /**
   * there doesn't seem to be a way to bind option data
   * to the onChange callback
   * so i'm using stringify to bundle it
   * ¯\_(ツ)_/¯
   */
  getOptions = (options: { tenantResources: any[] }) => {
    const { tenantResources = [] } = options || {};
    const { excludedResources } = this.props;

    return tenantResources
      .reduce((acc: any[], { resourceId, name, identifier, tenant, app }: ITenantResources) => {
        !excludedResources.includes(resourceId) &&
          app &&
          acc.push({
            text: app.name,
            description: name,
            value: JSON.stringify({ resourceId, tenant, identifier }),
          });

        return acc;
      }, [])
      .sort((optionA: { text: string }, optionB: { text: string }) => {
        const appA = optionA.text.toUpperCase();
        const appB = optionB.text.toUpperCase();
        if (appA > appB) return 1;
        if (appA < appB) return -1;
        return 0;
      });
  };

  render() {
    const { envId, resourceId, onChange } = this.props;

    return (
      <Query<any, any> query={GET_ENV} variables={{ id: envId }}>
        {({ loading, error, data }) => {
          if (error) return <Error error={error} />;

          const isProduction = _.get(data, 'environment.isProduction');
          if (isProduction === null || isProduction === undefined) return null;

          return (
            <QueryDropdown
              query={GET_TENANT_RESOURCES}
              variables={{ envId }}
              placeholder="Select Resource"
              id="resource"
              name="resourceId"
              noResultsMessage="No resources for this tenant were found"
              optionsFn={this.getOptions}
              value={resourceId}
              loading={loading}
              onChange={onChange}
              label="Request data from:"
              fluid
              search
              selection
            />
          );
        }}
      </Query>
    );
  }

  static propTypes = {
    envId: PropTypes.string.isRequired,
    excludedResources: PropTypes.array,
    onChange: PropTypes.func,
    resourceId: PropTypes.string,
  };

  static defaultProps = {
    excludedResources: [],
    onChange: () => {},
    resourceId: '',
  };
}

export default ChooseResource;
