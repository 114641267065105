import React, { Fragment, FC, ReactElement } from 'react';
import { Divider, List } from 'semantic-ui-react';
import UsageStats, { AllowedRatings } from './UsageStats';
import UsageChart, { IChartData } from './UsageChart';
import NoUsageMessage from './NoUsageMessage';
import UsageHeader from './UsageHeader';

export interface ITokenCost {
  chartData: IChartData[];
  averageDailyCost: string;
  averageDailyTokens: number;
  estimatedYearlyCost: number;
  isHighUsage: boolean;
  rating: AllowedRatings;
  totalTokens: number;
  highUsageDays: number;
}

export interface IClient {
  clientId: string;
  envId: string;
  name: string;
  tenant: string;
  tokenCost?: ITokenCost;
  app?: {
    id: string;
    name: string;
  };
}

interface IProps {
  clients: IClient[];
  envId: string;
}

const UsageChartList: FC<IProps> = ({ clients, envId }: IProps): ReactElement => {
  return (
    <List size="large" relaxed key={`env-${envId}`}>
      {clients.map(client => {
        const { clientId, tokenCost, tenant } = client;

        // if we don't get any data, don't show any data.
        if (!tokenCost) {
          return (
            <Fragment key={`client-${clientId}`}>
              <List.Item>
                <List.Content>
                  <UsageHeader client={client} />
                  <NoUsageMessage clientId={clientId} tenant={tenant} />
                </List.Content>
              </List.Item>
              <Divider hidden />
            </Fragment>
          );
        }

        const {
          chartData,
          isHighUsage,
          estimatedYearlyCost,
          rating,
          totalTokens,
          highUsageDays,
        } = tokenCost;

        return (
          <Fragment key={`client-${clientId}`}>
            <List.Item>
              <List.Content>
                <UsageHeader client={client} />
                <Divider hidden />
                <UsageStats
                  estimatedYearlyCost={estimatedYearlyCost}
                  rating={rating}
                  totalTokens={totalTokens}
                  highUsageDays={highUsageDays}
                  isHighUsage={isHighUsage}
                />
                <Divider hidden />
                <UsageChart chartData={chartData} isHighUsage={isHighUsage} />
              </List.Content>
            </List.Item>
            <Divider hidden />
          </Fragment>
        );
      })}
    </List>
  );
};
export default UsageChartList;
