import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Icon, Popup } from 'semantic-ui-react';

import Error from '../components/Error';

// named ModalForm b/c it has Form actions (Save / Cancel) by default
const ModalForm = props => {
  const {
    children,
    header,
    scrolling,
    closeOnDimmerClick,
    loading,
    cancel,
    actionText,
    actionAttr,
    actionDisabled,
    trigger,
    open,
    toggleModal,
    errors,
    closeOnEscape,
    ...rest
  } = props;

  let modalTrigger = null;
  if (trigger !== null) {
    modalTrigger = React.cloneElement(trigger, { onClick: toggleModal });
  }

  return (
    <Modal
      trigger={modalTrigger}
      open={open}
      onClose={toggleModal}
      closeOnDimmerClick={closeOnDimmerClick}
      {...rest}
      closeOnEscape={closeOnEscape}
    >
      <Modal.Header>{header}</Modal.Header>
      <Modal.Content scrolling={scrolling}>{children}</Modal.Content>
      <Modal.Actions>
        <Error
          errors={errors}
          wrapper={
            <Popup
              trigger={<Icon color="red" name="warning sign" size="large" />}
              position="top center"
            />
          }
        />
        {cancel && (
          <Button basic onClick={toggleModal} disabled={loading}>
            Cancel
          </Button>
        )}
        <Button id="submit" disabled={loading || actionDisabled} {...actionAttr}>
          {actionText}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

ModalForm.propTypes = {
  children: PropTypes.node.isRequired,
  header: PropTypes.node.isRequired,
  toggleModal: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  trigger: PropTypes.element,
  scrolling: PropTypes.bool,
  closeOnDimmerClick: PropTypes.bool,
  loading: PropTypes.bool,
  cancel: PropTypes.bool,
  actionText: PropTypes.string,
  actionAttr: PropTypes.object,
  actionDisabled: PropTypes.bool,
  errors: PropTypes.array,
  closeOnEscape: PropTypes.bool,
};

ModalForm.defaultProps = {
  trigger: null,
  scrolling: true,
  closeOnDimmerClick: false,
  loading: false,
  cancel: true,
  actionText: 'Save',
  actionAttr: {},
  actionDisabled: false,
  errors: [],
  closeOnEscape: true,
};

export default ModalForm;
