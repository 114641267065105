import React, { ReactNode, FC, useContext, useState, useEffect } from 'react';
import createAuth0Client, {
  Auth0ClientOptions,
  Auth0Client,
  LogoutOptions,
  GetTokenSilentlyOptions,
} from '@auth0/auth0-spa-js';

interface IProps {
  children: ReactNode;
  clientOptions: Auth0ClientOptions;
  /**
   * The url to return to after logging out.
   */
  returnTo: string;
}

interface IContext {
  authClient: Auth0Client;
  isAuthenticated: boolean;
  user: {
    firstName: string | undefined;
    lastName: string | undefined;
    jobFamily: string | undefined;
    userImage: string | undefined;
    userName: string | undefined;
  };
  getTokenSilently(options?: GetTokenSilentlyOptions): Promise<string>;
  logout(options: LogoutOptions): void;
}

export const AuthContext = React.createContext({});

export const useAuth = () => useContext(AuthContext) as IContext;

export const onRedirectCallback = (appState: { targetUrl: string }) => {
  // Temporary Firefox workaround
  window.location.hash = window.location.hash; // eslint-disable-line no-self-assign

  window.history.replaceState(
    {},
    document.title,
    appState && appState.targetUrl ? appState.targetUrl : window.location.pathname
  );
};

export const AuthConsumer = AuthContext.Consumer;

export const AuthProvider: FC<IProps> = (props: IProps) => {
  const [authContext, setAuthContext] = useState<IContext | { isAuthenticated: boolean }>({
    isAuthenticated: false,
  });

  // Setup Auth. We only want this to run once so we pass an empty array as the second argument.
  useEffect(() => {
    (async () => {
      const client = await createAuth0Client(props.clientOptions);

      if (window.location.pathname === '/login') {
        const { appState } = await client.handleRedirectCallback();
        onRedirectCallback(appState);
      }

      const isAuthenticated = await client.isAuthenticated();

      // we aren't authenticate so we need to exit early and login
      if (!isAuthenticated) {
        await client.loginWithRedirect({
          appState: { targetUrl: `${window.location.pathname}` },
        });
        return;
      }

      const userFromID = await client.getUser();
      const token = await client.getTokenSilently();
      const claims = JSON.parse(atob(token.split('.')[1]));

      setAuthContext({
        authClient: client,
        isAuthenticated,
        user: {
          firstName: userFromID?.given_name,
          lastName: userFromID?.family_name,
          jobFamily: claims['https://jobFamily'],
          userImage: userFromID?.picture,
          userName: userFromID?.nickname,
        },
        getTokenSilently: options => client.getTokenSilently(options),
        logout: options => client.logout(options),
      });
    })();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (!authContext.isAuthenticated) {
    return null;
  }

  return <AuthContext.Provider value={authContext}>{props.children}</AuthContext.Provider>;
};
