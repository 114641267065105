import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Container, Tab, Divider, Menu, Icon } from 'semantic-ui-react';

import AppAccordion from '../../components/AppAccordion';
import AppPane from '../../components/AppPane';
import UserList from '../../components/UserList';
import Usage from './Usage';
import { useFlags } from 'launchdarkly-react-client-sdk';
import TeamHighTokenUsageBadge from '../../components/TokenUsage/TeamHighTokenUsageBadge';

interface IApp {
  id: string;
  name: string;
  description: string;
  appType: {
    id: string;
    name: string;
  };
  environments: {
    id: string;
    isProduction: boolean;
  }[];
}

interface IUser {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  jobFamily: string;
  isAdmin: boolean;
}

interface ITeam {
  id: string;
  name: string;
  apps: IApp[];
  members: IUser[];
}

const accordionItems = [
  {
    accordionDetails: '',
    accordionTitle: 'What can Team Members do?',
    accordionItemText: [
      {
        key: 'register-apps',
        title: 'Register',
        details: 'apps for your team',
      },
    ],
  },
];

const buildPanes = (me: IUser, team: ITeam, isMember: boolean, userTokenUsage: boolean) => {
  const panes = [];

  panes.push({
    menuItem: {
      key: 'apps',
      icon: 'snowflake outline',
      content: `Apps`,
    },
    render: () => (
      <AppPane me={me} apps={team.apps} defaultTeam={team} showCreateApp={isMember || me.isAdmin} />
    ),
  });

  panes.push({
    menuItem: {
      key: 'members',
      icon: 'user circle',
      content: 'Members',
    },
    render: () => (
      <Fragment>
        <AppAccordion accordionItems={accordionItems} />
        <Divider hidden />
        <UserList team={team} />
      </Fragment>
    ),
  });

  userTokenUsage &&
    isMember && // only show usage for team members
    panes.push({
      menuItem: (
        <Menu.Item key="tokenUsage">
          <Icon name="chart line" /> Token Usage{' '}
          <TeamHighTokenUsageBadge id={team.id} circular={false} floating={false} />
        </Menu.Item>
      ),
      render: () => <Usage team={team} />,
    });

  return panes;
};

const TeamDetail = ({ me, team }: { me: IUser; team: ITeam }) => {
  const { userTokenUsage } = useFlags();
  const ids = team.members.map((member: IUser) => member.id);
  let isMember = false;
  if (ids.includes(me.id)) {
    isMember = true;
  }

  const panes = buildPanes(me, team, isMember, userTokenUsage);

  return (
    <Container fluid>
      <Tab menu={{ secondary: true, pointing: true }} panes={panes} />
    </Container>
  );
};

TeamDetail.propTypes = {
  me: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  team: PropTypes.shape({
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    apps: PropTypes.array.isRequired,
    members: PropTypes.array.isRequired,
  }).isRequired,
};

export default TeamDetail;
