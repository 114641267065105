import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Form, Checkbox } from 'semantic-ui-react';
import InputDescription from '../../components/InputDescription';
import Scopes from '../../components/Scopes';
import { useFlags } from 'launchdarkly-react-client-sdk';

export const RESOURCE_FORM_ID = 'update-resource-form-id';

const UpdateResourceForm = props => {
  const {
    name: origName,
    scopes: origScopes,
    allowUsers: origAllowUsers,
    resourceId,
    updateResource,
    tenant,
  } = props;
  const { allowUsers: allowUsersFlag } = useFlags();
  const [name, setName] = useState(origName);
  const [allowUsers, setAllowUsers] = useState(origAllowUsers);
  const [scopes, setScopes] = useState(origScopes);

  const handleAllowUsersChange = (e, data) => {
    setAllowUsers(data.checked);
  };

  const handleChange = (e, data) => {
    setName(data.value);
  };

  const handleScopesChange = (e, data) => {
    setScopes(data.value);
  };

  const handleSubmit = e => {
    e.preventDefault();

    // looping over scopes to remove the __filetype
    // temp fix and we should research a better way
    updateResource({
      variables: {
        resourceId,
        name,
        scopes: scopes.map(({ value, description }) => ({
          value,
          description,
        })),
        tenant,
        allowUsers,
      },
    });
  };

  return (
    <Fragment>
      <Form onSubmit={handleSubmit} id={RESOURCE_FORM_ID}>
        <Form.Input
          id="name"
          placeholder="Resource Name"
          name="name"
          label="Name"
          value={name}
          required
          autoFocus
          onChange={handleChange}
        />
        <InputDescription description="A friendly name for the API." />
        {allowUsersFlag && (
          <Fragment>
            <Form.Field
              style={{
                fontSize: '.92857143em',
                fontWeight: 700,
              }}
            >
              User Access
            </Form.Field>
            <Form.Input>
              <Checkbox
                id="allowUsers"
                placeholder="Allow Users"
                name="allowUsers"
                label={allowUsers ? 'Enabled' : 'Disabled'}
                toggle
                checked={allowUsers}
                onChange={handleAllowUsersChange}
              />
            </Form.Input>
            <InputDescription>
              <Fragment>
                <strong>Enable ability for users to access this resource directly</strong> instead
                of only allowing machine-to-machine connections
              </Fragment>
            </InputDescription>
          </Fragment>
        )}
      </Form>
      <Scopes onChange={handleScopesChange} scopes={scopes} />
    </Fragment>
  );
};

UpdateResourceForm.propTypes = {
  name: PropTypes.string,
  resourceId: PropTypes.string.isRequired,
  updateResource: PropTypes.func.isRequired,
  allowUsers: PropTypes.bool,
  tenant: PropTypes.string,
  scopes: PropTypes.array,
};

UpdateResourceForm.defaultProps = {
  name: '',
  allowUsers: false,
  scopes: [],
};

UpdateResourceForm.displayName = 'UpdateResourceForm';

export default UpdateResourceForm;
