/* eslint-disable react/destructuring-assignment */
import React, { Component, Fragment } from 'react';
import { toast } from 'react-toastify';
import { Card, Icon, Header, Button, Message, Confirm } from 'semantic-ui-react';
import { Query, Mutation } from 'react-apollo';

import gql from 'graphql-tag';
import getErrorMessages from '../../util/errors';

import { statusColors, statusIcons } from '../../constants';
import ChooseResourceScopes from './ChooseResourceScopes';
import Error from '../Error';

const CANCEL_REQUEST = gql`
  mutation PendingClientGrants_CancelRequest($id: ID!) {
    cancelRequest(id: $id) {
      success
      error {
        message
        code
      }
    }
  }
`;

const GET_PENDING_CLIENT_GRANTS = gql`
  query PendingClientGrants_GetPendingClientGrants($clientId: ID!) {
    pendingClientGrants(client_id: $clientId) {
      id
      audience
      clientId: client_id
      scope
      tenant
      resource {
        resourceId: resource_id
      }
      status
    }
  }
`;

class PendingClientGrants extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isConfirmVisible: false,
    };
  }

  handleConfirmCancel = () => {
    this.setState({ isConfirmVisible: false });
  };

  handleConfirmConfirm = () => {
    const { confirmCallback, confirmCallbackArgs } = this.state;

    this.setState({ isConfirmVisible: false }, () => {
      confirmCallback(confirmCallbackArgs);
    });
  };

  render() {
    const { clientId, tenant, meIsOwner } = this.props;
    const { isConfirmVisible } = this.state;

    return (
      <Fragment>
        <Query query={GET_PENDING_CLIENT_GRANTS} variables={{ clientId, tenant }}>
          {({ loading, error, data }) => {
            if (loading) return <p>Loading...</p>;
            if (error) return <Error error={error} />;

            return data.pendingClientGrants.map(
              ({ clientId, id, resource, scope, status, tenant }) => (
                <Card key={`column-${id}`}>
                  <Card.Content extra>
                    <Header sub>
                      <Icon color={statusColors[status]} name={statusIcons[status]} />
                      {status}
                    </Header>
                  </Card.Content>
                  <Card.Content>
                    <ChooseResourceScopes
                      disabled // can't edit pending client grants
                      tenant={tenant}
                      resourceId={resource.resourceId}
                      selectedScopes={scope.map(s => ({
                        value: s,
                      }))}
                    />
                  </Card.Content>
                  <Confirm
                    id="confirm-cancellation"
                    open={isConfirmVisible}
                    content="Removing this request will prevent your client from accessing this resource. Are you sure you want to continue?"
                    onCancel={this.handleConfirmCancel}
                    onConfirm={this.handleConfirmConfirm}
                  />
                  {meIsOwner && (
                    <Card.Content extra>
                      <Mutation
                        mutation={CANCEL_REQUEST}
                        refetchQueries={[
                          {
                            query: GET_PENDING_CLIENT_GRANTS,
                            variables: { clientId, tenant },
                          },
                        ]}
                        onCompleted={() => {
                          toast.success('Successfully cancelled resource request.');
                        }}
                        onError={err => toast.error(`Error encountered: ${getErrorMessages(err)}`)}
                      >
                        {cancelResourceRequest => (
                          <Button
                            id="cancel-resource-req-button"
                            basic
                            color={statusColors.cancelled}
                            fluid
                            onClick={() =>
                              this.setState({
                                isConfirmVisible: true,
                                confirmCallback: cancelResourceRequest,
                                confirmCallbackArgs: {
                                  variables: { id },
                                },
                              })
                            }
                          >
                            <Icon name="trash" />
                            Cancel
                          </Button>
                        )}
                      </Mutation>
                    </Card.Content>
                  )}
                  <Message icon warning attached="bottom">
                    <Icon name="warning sign" />
                    Please contact the admin(s) of this resource directly to request approval.
                  </Message>
                </Card>
              )
            );
          }}
        </Query>
      </Fragment>
    );
  }
}

export default PendingClientGrants;
