import React, { FC, Fragment } from 'react';
import { useQuery } from 'react-apollo';
import { Grid, Container, Message } from 'semantic-ui-react';
import gql from 'graphql-tag';

import BlogCard from '../../components/Card/BlogCard';
import Error from '../../components/Error';

import { IBlogPost } from './interfaces';
import PageLoader from '../PageLoader';

const LIST_BLOG_POSTS = gql`
  query Highlights_GetPostCategoriesAndPosts(
    $perPage: Int
    $pageNum: Int
    $activeFilters: [String]
  ) {
    postCategories {
      id
      name
      slug
    }
    posts(perPage: $perPage, pageNum: $pageNum, activeFilters: $activeFilters) {
      pagedPosts {
        id
        title
        featuredImage
        thumbnail
        excerpt
        content
        author {
          id
          name
          email
        }
        categories {
          id
          name
          slug
        }
        tags {
          id
          name
          slug
        }
        link
        createdDate
        updatedDate
      }
      totalPages
    }
  }
`;

interface IHighlightsProps {
  count: number;
}

const noPostsMessage = `We don't have any posts, but we have space... Space is big. You just won't believe how vastly, hugely, mind-bogglingly big it is. I mean, you may think it's a long way down the road to the chemist's, but that's just peanuts to space.`;

const Highlights: FC<IHighlightsProps> = (props: IHighlightsProps) => {
  const { count } = props;
  const { loading, error, data } = useQuery(LIST_BLOG_POSTS, {
    variables: { perPage: count },
  });

  if (loading)
    return (
      <Container style={{ height: '90vh' }}>
        <PageLoader />
      </Container>
    );

  if (error) {
    return <Error header="I’m not getting you down at all am I?" error={error} />;
  }

  const posts: IBlogPost[] = data.posts.pagedPosts;

  // return a message if no posts, or count is less than one
  if (posts.length === 0 || count < 1) {
    return (
      <Grid container stackable centered stretched doubling>
        <Grid.Column>
          <Container fluid>
            <Message size="big">{noPostsMessage}</Message>
          </Container>
        </Grid.Column>
      </Grid>
    );
  }

  // remove the first & grab the remaining posts in the array
  const remainingPosts = posts.slice(1, count);
  return (
    <Fragment>
      <Grid container stackable centered stretched doubling>
        <Grid.Column width={8} stretched>
          <BlogCard
            showExcerpt
            featured
            post={posts[0]}
            key={`post-${posts[0].id}`}
            width={'100%'}
          />
        </Grid.Column>
        {remainingPosts.length > 0 && count > 1 && (
          <Grid.Column width={8}>
            {remainingPosts.map(post => {
              return (
                <BlogCard
                  showExcerpt
                  featured={false}
                  post={post}
                  key={`post-${post.id}`}
                  width={'100%'}
                />
              );
            })}
          </Grid.Column>
        )}
      </Grid>
    </Fragment>
  );
};

export default Highlights;
