import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Confirm, Icon, Message, Form, Grid, Segment, FormProps } from 'semantic-ui-react';
import InputDescription from './InputDescription';
import { toast } from 'react-toastify';

interface IScope {
  description: string;
  value: string;
}

interface IProps {
  onChange: Function;
  scopes: IScope[];
  showDeleteWarning: boolean;
}

interface IState {
  inputScopeName: string;
  inputScopeDescription: string;
  scopes: IScope[];
  confirmOpen: boolean;
  confirmData: {
    e?: Event;
    data?: any;
  };
  error: boolean;
}

class Scopes extends Component<IProps, IState> {
  state = {
    inputScopeName: '',
    inputScopeDescription: '',
    scopes: [],
    confirmOpen: false,
    confirmData: {},
    error: false,
  };

  componentDidMount() {
    const { scopes: propScopes } = this.props;
    this.setState({
      scopes: [...propScopes],
    });
  }

  handleRemove = (e: Event, data: any) => {
    // show Modal
    this.setState({
      confirmOpen: true,
      confirmData: { e, data },
    });
  };

  handleCancel = () => {
    this.setState({ confirmOpen: false, confirmData: {} });
  };

  handleConfirm = () => {
    const {
      confirmData: { e, data },
    }: any = this.state;
    this.setState({ confirmOpen: false, confirmData: {} }, () => this.handleRemoveScope(e, data));
  };

  handleFormChange = ({ target: { name, value } }: { target: { name: string; value: string } }) => {
    this.setState({ [name]: value.toLowerCase() } as any);
  };

  handleAddScope = (e: FormProps) => {
    e.preventDefault();
    const { onChange } = this.props;
    const { scopes, inputScopeName, inputScopeDescription } = this.state;

    // if duplicate scope, return error
    if (scopes.find((scope: IScope) => scope.value === inputScopeName)) {
      this.setState({ error: true });
      toast.error(`Error encountered: Scope must be unique.`, {
        autoClose: false,
      });
      return null;
    }

    this.setState(
      {
        error: false,
        scopes: [
          ...scopes,
          {
            value: inputScopeName,
            description: inputScopeDescription,
          },
        ],
      },
      () => {
        const { scopes: updatedScopes } = this.state;
        this.clearForm();
        onChange(e, { name: 'scopes', value: updatedScopes });
      }
    );
  };

  handleRemoveScope = (e: Event, { options, value }: { options: any; value: any[] }) => {
    const { onChange } = this.props;
    const updatedScopes = options.reduce(
      (acc: any[], option: { value: string; description: string }) => {
        // if value array contains this scope, add it
        if (value.find((scope: string) => scope === option.value)) {
          acc.push({
            value: option.value,
            description: option.description,
          });
        }
        return acc;
      },
      []
    );
    this.setState({ scopes: updatedScopes }, () => {
      const { scopes: updatedScopes } = this.state;
      this.clearForm();
      onChange(e, { name: 'scopes', value: updatedScopes });
    });
  };

  clearForm = () => {
    this.setState({ inputScopeName: '', inputScopeDescription: '' });
  };

  render() {
    const { confirmOpen, inputScopeName, inputScopeDescription, scopes, error } = this.state;
    const { showDeleteWarning } = this.props;
    const options = scopes.map((scope: any) => ({
      text: scope.value,
      id: scope.value,
      value: scope.value,
      description: scope.description,
    }));
    const scopeIds = scopes.map((scope: IScope) => scope.value);
    return (
      <Segment.Group>
        <Form onSubmit={this.handleAddScope}>
          <Message attached="top" info>
            <Message.Content>
              <Icon name="info circle" />
              This section is optional, your resource will have
              <strong> &apos;read:all&apos;</strong> and
              <strong> &apos;write:all&apos; </strong> scopes by default.
            </Message.Content>
          </Message>
          <Segment basic>
            <Confirm
              open={confirmOpen}
              content="Removing this scope will also remove the scope from all associated clients. Are you sure you want to remove it?"
              onCancel={this.handleCancel}
              onConfirm={this.handleConfirm}
            />
            <p>
              Does your API need authorization? For example, are some clients allowed to write data
              while others can only read? If so, you might want to set up custom &apos;read&apos;
              and &apos;write&apos; scopes. Add additional scopes to implement more fine-grained
              access control.
            </p>
          </Segment>
          <Segment basic>
            <Grid>
              <Grid.Row>
                <Grid.Column width={8}>
                  <Form.Input
                    id="scope"
                    name="inputScopeName"
                    label="Scope"
                    value={inputScopeName}
                    onChange={this.handleFormChange}
                    error={error}
                  />
                  <InputDescription description="The naming convention is <action>:<resource>, example: 'write:order'. This needs to be lowercase." />
                </Grid.Column>
                <Grid.Column width={8}>
                  <Form.Input
                    id="scopeName"
                    name="inputScopeDescription"
                    label="Name"
                    value={inputScopeDescription}
                    onChange={this.handleFormChange}
                  />
                  <InputDescription description="A friendly name for the scope, example 'write orders'" />
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <Form.Button
              id="add-scope"
              disabled={!inputScopeName.length || !inputScopeDescription.length}
              primary
            >
              <Icon name="plus" /> Add
            </Form.Button>
          </Segment>
          {!scopeIds.length && (
            <Segment basic>
              <Message color="yellow">
                <Message.Content>
                  <Icon name="warning" />
                  Your API will need at least one scope if SPAs will be connecting to your
                  application. Otherwise SPA requests will be rejected.
                </Message.Content>
              </Message>
            </Segment>
          )}
          <Segment basic>
            <Form.Dropdown
              multiple
              required
              selection
              options={options}
              value={scopeIds}
              label="Scopes"
              icon={null}
              id="chosenScopes"
              onChange={showDeleteWarning ? this.handleRemove : (this.handleRemoveScope as any)}
            />
          </Segment>
        </Form>
      </Segment.Group>
    );
  }

  static propTypes = {
    scopes: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
      })
    ),
    showDeleteWarning: PropTypes.bool,
  };

  static defaultProps = {
    scopes: [],
    showDeleteWarning: true,
  };
}

export default Scopes;
