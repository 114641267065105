import React, { SyntheticEvent } from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';

import QueryDropdown from './QueryDropdown';

const ME_TEAMS = gql`
  query TeamsDropdown_GetMyTeams {
    me {
      teams {
        id
        name
        description
      }
    }
  }
`;

interface IProps {
  id: string;
  placeholder: string;
  label: string;
  name: string;
  value: string;
  onChange(event: SyntheticEvent<HTMLElement, Event>, data: any): void;
}

const optionsFn = (data: any) => {
  let options = [];
  if (data.me.teams) {
    options = data.me.teams.map((team: any) => ({
      text: team.name,
      id: team.id,
      value: team.id,
    }));
  }

  return options;
};

const TeamsDropdown = (props: IProps) => (
  <QueryDropdown
    query={ME_TEAMS}
    optionsFn={optionsFn}
    noResultsMessage="No teams found."
    clearable
    required
    {...props}
  />
);

TeamsDropdown.propTypes = {
  id: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default TeamsDropdown;
