import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Button, Icon, Form } from 'semantic-ui-react';
import { Mutation } from 'react-apollo';
import { toast } from 'react-toastify';
import gql from 'graphql-tag';
import getErrorMessages from '../../util/errors';

import ModalState from '../../layouts/ModalState';
import ModalForm from '../../layouts/ModalForm';
import DisabledButtonPopup from '../../components/DisabledButtonPopup';

const DELETE_TEAM = gql`
  mutation DeleteTeam_DeleteTeam($id: ID!) {
    deleteTeam(id: $id) {
      success
      error {
        message
        code
      }
      team {
        id
      }
    }
  }
`;

const GET_ALL_TEAMS_DETAILED = gql`
  query DeleteTeam_GetTeams {
    teams {
      id
      name
      description
      apps {
        id
      }
      members {
        id
      }
    }
  }
`;

const ME = gql`
  query DeleteTeam_GetMe {
    me {
      id
      isAdmin
      firstName
      lastName
      email
      jobFamily
      hash
      teams {
        id
        name
        description
      }
    }
  }
`;

const DELETE_FORM_ID = 'delete-team-form';

class DeleteTeam extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      teamName: '',
    };
  }

  handleChange = (e, { name, value }) => this.setState({ [name]: value });

  clearTeamName = () => this.setState({ teamName: '' });

  handleDeleteTeam = deleteTeam => () => {
    const { team } = this.props;

    deleteTeam({
      variables: {
        id: team.id,
      },
    });
  };

  handleMutationComplete = () => {
    const { history } = this.props;
    history.push('/teams');
  };

  render() {
    const { team } = this.props;
    const { teamName } = this.state;

    return (
      <ModalState toggleCallback={this.clearTeamName}>
        {(modalOpen, toggleModal) => (
          <Fragment>
            {team.apps.length > 0 ? (
              <DisabledButtonPopup content="To delete this Team, you must remove or reassign all of it's Apps first." />
            ) : (
              <Button size="tiny" basic color="red" onClick={toggleModal}>
                <Icon name="remove" />
                Delete
              </Button>
            )}
            {modalOpen && (
              <Mutation
                mutation={DELETE_TEAM}
                refetchQueries={[
                  { query: GET_ALL_TEAMS_DETAILED },
                  {
                    query: ME,
                  },
                ]}
                awaitRefetchQueries
                onCompleted={() => {
                  this.handleMutationComplete();
                  toast.success('Successfully deleted team!');
                }}
                onError={error =>
                  toast.error(`Error encountered: ${getErrorMessages(error)}`, {
                    autoClose: false,
                  })
                }
              >
                {(deleteTeam, { loading, error }) => {
                  const errors = getErrorMessages(error);

                  return (
                    <ModalForm
                      header={`Delete Team: ${team.name}`}
                      actionAttr={{
                        negative: true,
                        type: 'submit',
                        form: DELETE_FORM_ID,
                      }}
                      actionText="Delete Team"
                      actionDisabled={teamName.toLowerCase() !== team.name.toLowerCase()}
                      loading={loading}
                      open={modalOpen}
                      toggleModal={toggleModal}
                      errors={errors}
                    >
                      <Form
                        id={DELETE_FORM_ID}
                        onSubmit={this.handleDeleteTeam(deleteTeam)}
                        loading={loading}
                        error={errors.length > 0}
                      >
                        <p>Are you sure you want to delete this team?</p>
                        <Form.Input
                          id="delete-team"
                          label="Please type the name of the team to confirm:"
                          name="teamName"
                          value={teamName}
                          onChange={this.handleChange}
                          autoFocus
                        />
                      </Form>
                    </ModalForm>
                  );
                }}
              </Mutation>
            )}
          </Fragment>
        )}
      </ModalState>
    );
  }
}

DeleteTeam.propTypes = {
  team: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default withRouter(DeleteTeam);
