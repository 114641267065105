import React, { Fragment } from 'react';
import { Query } from 'react-apollo';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import { Grid, Item, List } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

import GridLayout from '../../layouts/GridLayout';
import UserDetail from './UserDetail';
import ContainerLoader from '../../components/ContainerLoader';
import Error from '../../components/Error';
import Gravatar from '../../components/Gravatar';

const GET_USER = gql`
  query User_GetUser($id: ID!) {
    user(id: $id) {
      id
      firstName
      lastName
      email
      jobFamily
      apps {
        id
        name
        description
        appType {
          id
          name
          description
          showClientGrants
        }
        team {
          id
          name
        }
      }
      teams {
        id
        name
        description
      }
    }
  }
`;

// only want to show job family section in user profile if it is not empty
const ViewJobFamily = props => {
  if (props.jobFamily) {
    return (
      <Item>
        <Item.Content>
          <Item.Description style={{ marginTop: '1em', fontWeight: 'bold' }}>
            <p>JOB FAMILY</p>
          </Item.Description>
          <Item.Meta>
            <p>{props.jobFamily}</p>
          </Item.Meta>
        </Item.Content>
      </Item>
    );
  } else {
    return null; // react doesn't render null components
  }
};

const User = ({
  me,
  match: {
    params: { id },
  },
}) => (
  <GridLayout>
    <Query query={GET_USER} variables={{ id }}>
      {({ loading, error, data }) => {
        if (loading) return <ContainerLoader />;

        if (error) {
          return (
            <Error header="I think you ought to know I’m feeling very depressed." error={error} />
          );
        }
        return (
          <Grid>
            <Grid.Row>
              <Grid.Column>
                <Item.Header
                  style={{
                    fontWeight: 'bold',
                    fontSize: '2em',
                    marginBottom: '.5em',
                    marginTop: '-1rem',
                  }}
                >
                  <Gravatar email={data.user.email} size="small" />{' '}
                  <p>{data.user.firstName + ' ' + data.user.lastName}</p>
                </Item.Header>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={3}>
                <Item.Group>
                  <Item>
                    <Item.Content>
                      <Item.Description style={{ marginTop: '1em', fontWeight: 'bold' }}>
                        <p>EMAIL</p>
                      </Item.Description>
                      <Item.Meta>
                        <p>{data.user.email}</p>
                      </Item.Meta>
                    </Item.Content>
                  </Item>
                  <ViewJobFamily jobFamily={data.user.jobFamily} />
                  <List>
                    <Item.Description style={{ marginTop: '1em', fontWeight: 'bold' }}>
                      <p>TEAMS</p>
                    </Item.Description>
                    <List.Item>
                      {data.user.teams.map(({ id, name }, i) => (
                        <Fragment key={id}>
                          {i > 0 && ', '}
                          <Link to={`/team/${id}`}>{`${name} Team`}</Link>
                        </Fragment>
                      ))}
                    </List.Item>
                  </List>
                </Item.Group>
              </Grid.Column>
              <Grid.Column width={13} style={{ marginTop: '-6rem' }}>
                <UserDetail me={me} user={data.user} key={`user-${id}`} />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        );
      }}
    </Query>
  </GridLayout>
);

User.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default User;
