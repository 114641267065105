import React, { Fragment, FC, ReactElement } from 'react';
import { Divider } from 'semantic-ui-react';
import { useQuery } from 'react-apollo';
import gql from 'graphql-tag';

import Error from '../../../components/Error';
import AppAccordion from '../../../components/AppAccordion';
import EmptyResults from '../../../components/EmptyResults';
import PageLoader from '../../../components/PageLoader';
import UsageChartList, { IClient } from '../../../components/TokenUsage/UsageChartList';

interface IEnvironment {
  id: string;
  name: string;
  tenant: string;
  isProduction: string;
}

const GET_ENV_CLIENTS_TOKEN_COST = gql`
  query UsageTab_GetEnvClientsTokenCost($envs: [ID]!) {
    envClients(envs: $envs) {
      clientId: client_id
      envId: environment_id
      name
      tenant
      tokenCost {
        chartData {
          date
          thisWeekTokens
          lastWeekTokens
          thisWeekDate
          lastWeekDate
          thisWeekCost
          lastWeekCost
          thisWeekUsage
          lastWeekUsage
          isHighUsage
        }
        estimatedYearlyCost
        percentageOfContract
        isHighUsage
        rating
        highUsageDays
        totalTokens
      }
    }
  }
`;

const accordionItems = [
  {
    accordionDetails:
      'Did you know that every token request costs us money? And did you know that we request a boat-load of tokens every day? Well, we can do better and here is how:',
    accordionTitle: 'How can I use tokens more effiently to save us money?',
    accordionItemText: [
      {
        key: 'read',
        title: 'Read This Article!',
        details:
          '[Effectively caching JWT access tokens saves us $$$ – Here’s how](https://arvy.io/news/effectively-caching-jwt-access-tokens-saves-us-heres-how)',
      },
    ],
  },
];

interface IProps {
  environments: IEnvironment[];
}

const UsageTab: FC<IProps> = ({ environments }: IProps): ReactElement => {
  const envs = environments.map(e => e.id);

  const { loading, error, data } = useQuery<{ envClients: IClient[] }>(GET_ENV_CLIENTS_TOKEN_COST, {
    variables: { envs },
  });

  if (!envs || !envs.length) {
    return <Error errors={['Please register an environment before setting up authentication.']} />;
  }

  if (loading) return <PageLoader />;
  if (error) return <Error error={error} />;
  if (!data) return <Fragment />;

  return (
    <Fragment>
      <AppAccordion accordionItems={accordionItems} />
      <Divider hidden />
      {envs.map(envId => {
        const clients = data.envClients.filter(c => c.envId === envId);
        if (!clients) {
          return <Fragment key={envId} />;
        }

        return <UsageChartList key={envId} clients={clients} envId={envId} />;
      })}
      {data.envClients.length < 1 && <EmptyResults />}
    </Fragment>
  );
};

export default UsageTab;
