import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';

import UsersDropdown from '../../components/UsersDropdown';
import AppAccordion from '../../components/AppAccordion';

// Import toast errors
import { toast } from 'react-toastify';

export const TEAM_FORM_ID = 'create-team-form';

class TeamForm extends React.Component {
  constructor(props) {
    super(props);
    const { me, team } = this.props;

    if (!team) {
      this.state = {
        name: '',
        description: '',
        members: [me.id],
      };
      return;
    }

    const { name, description, members } = team;

    this.state = {
      name,
      description,
      members: members.map(m => m.id),
    };
  }

  handleChange = (e, { name, value }) => this.setState({ [name]: value });

  handleSubmit = e => {
    e.preventDefault();
    const { name, description, members } = this.state;
    const { onSubmit, team } = this.props;

    // check if any members are on the team
    if (members.length === 0) {
      toast.error('Error encountered: teams must have at least one member');
      return;
    }

    const variables = {
      name,
      description,
      members,
    };

    if (team) {
      variables.id = team.id;
    }

    onSubmit({
      variables,
    });
  };

  render() {
    const { loading, errors } = this.props;
    const { name, description, members } = this.state;

    const accordionItems = [
      {
        accordionDetails: '',
        accordionTitle: 'What can Team Members do?',
        accordionItemText: [
          {
            key: 'members-register',
            title: 'Register',
            details: 'apps for your team',
          },
        ],
      },
    ];

    return (
      <Fragment>
        <Form
          onSubmit={this.handleSubmit}
          id={TEAM_FORM_ID}
          loading={loading}
          error={errors.length > 0}
        >
          <Form.Input
            id="team-name"
            placeholder="Name"
            name="name"
            label="Team Name"
            required
            autoFocus
            value={name}
            onChange={this.handleChange}
          />

          <Form.TextArea
            id="team-desc"
            placeholder="Description"
            name="description"
            label="Description"
            required
            value={description}
            onChange={this.handleChange}
          />

          <UsersDropdown
            id="team-members"
            placeholder="Member Name"
            label="Member(s)"
            name="members"
            value={members}
            onChange={this.handleChange}
          />
          <AppAccordion accordionItems={accordionItems} />
        </Form>
      </Fragment>
    );
  }
}

TeamForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  // used for CREATE
  me: PropTypes.shape({
    id: PropTypes.string,
  }),
  // used for UPDATE
  team: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    members: PropTypes.array,
  }),
  errors: PropTypes.array,
};
TeamForm.defaultProps = {
  me: {},
  team: null,
  errors: [],
};

export default TeamForm;
