import { css } from '@emotion/core';
import { up } from 'styled-breakpoints';
import { Header, Card } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { theme } from './theme';

import styled from '../styled';

const StyledNavBar = styled.nav`
  position: relative;
  z-index: 3;
  padding: 1rem 2rem;
  ul {
    flex: 1;
    list-style-type: none;
    display: flex;
    flex-grow: 1;
    margin: 0em 10em 0em 0em;
    padding: 0;
    align-items: center;
    justify-content: center;
  }
  li {
    margin: 0;
    padding: 0;
  }

  input#nav_toggle_cb {
    display: none;
  }

  i.bars, i.close {
    display: none;
  }

  div.nav__header {
    display: none;
  }

  div.menu__container {
    width: 100%;
    display: flex;
    flex-direction: row;
  }

  @media (max-width: ${props => props.theme.breakpoints.tablet}) {
    display: flex;
    justify-content: space-between;

    i.bars, i.close {
      display: block;
      color: ${props => props.theme.colors.secondary};
      margin-top: 5px;
      font-size: 2em;
    }

    ul {
      flex-direction: column;
      margin-right: 0;
      justify-content: start;
    }

    ul li {
      width: 100%;
      text-align: left;
    }

    #nav_toggle_cb:checked ~ div.menu__container {
      display: flex;
    }

    div.menu {
      margin: 20px auto;

      div img.ui.image {
        width: 40px;
        height: 40px;
      }

      div.floating.label {
        font-size: 0.85rem;
      }
    }

    div.menu__container {
      display: none;
      flex-direction: column-reverse;
      position: fixed;
      top: 0px;
      right 0px;
      width: 320px;
      height: 100%;
      z-index: 1;
      background-color: #373a47;
      box-shadow: rgba(32, 33, 36, 0.28) 0px 1px 6px 0px;
      transform: translateX(0%);
      transition: all 0.25s ease-in-out 0s;
    }

    div.nav__header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin: 10px;
      padding-bottom: 10px;
      border-bottom: 1px solid ${props => props.theme.colors.secondary};
    }

    div.nav__header h2 {
      color: ${props => props.theme.colors.white};
      margin: 0;
    }
  }

  @media (min-width: ${props => props.theme.breakpoints.tablet}) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

const StyledLink = styled(Link)`
  padding: 0.5rem 1rem;
  margin: 0 0.5rem;
  display: inline-block;
  color: white;
  font-family: ${props => props.theme.fonts.headerFamily};
  font-weight: ${props => props.theme.fonts.headerWeight};
  font-size: 1.25rem;
  &.active,
  &:active,
  &:hover,
  &:focus {
    color: ${props => props.theme.colors.secondary};
  }

  @media (max-width: ${props => props.theme.breakpoints.tablet}) {
    font-size: 1.5rem;
  }
`;

const StyledExternalLink = styled.a`
  padding: 0.5rem 1rem;
  margin: 0 0.5rem;
  display: inline-block;
  color: white;
  font-family: ${props => props.theme.fonts.headerFamily};
  font-weight: ${props => props.theme.fonts.headerWeight};
  font-size: 1.25rem;
  &.active,
  &:active,
  &:hover,
  &:focus {
    color: ${props => props.theme.colors.secondary};
  }

  @media (max-width: ${props => props.theme.breakpoints.tablet}) {
    font-size: 1.5rem;
  }
`;

const StyledDivLink = styled.div`
  color: white;
  font-family: ${props => props.theme.fonts.headerFamily};
  font-weight: ${props => props.theme.fonts.headerWeight};
  font-size: 1.25rem;
  &.active,
  &:active,
  &:hover,
  &:focus {
    color: ${props => props.theme.colors.secondary};
  }

  div.dropdown {
    padding: 0.5rem 1rem;
    margin: 0 0.5rem;
    display: inline-block;
  }

  @media (max-width: ${props => props.theme.breakpoints.tablet}) {
    width: 100%;
    text-align: left;
    font-size: 1.5rem;
  }
`;

const StyledSup = styled.sup`
  top: -0.8em;
  color: ${props => props.theme.colors.notificationYellow};
`;

interface IStyledGroupProps {
  width: number | string;
}

// Flex Grouping for Cards
const StyledGroup = styled.div<IStyledGroupProps>`
  margin: -1rem;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  .card {
    flex: 1 0 calc(${props => props.width} - 2rem);
    margin: 1rem;
    &:first-of-type {
      margin-top: 1rem;
    }
    &:last-of-type {
      margin-bottom: 1rem;
    }
    ${up('smallMonitor')} {
      max-width: calc(${props => props.width} - 2rem);
    }
  }
`;

interface IStyledHeaderProps {
  width?: string;
  textcolor?: string;
  action?: any;
  padding?: string;
}

// Header https://react.semantic-ui.com/elements/header/
const StyledHeader = styled(Header)<IStyledHeaderProps>`
  &&&& {
    font-family: ${theme.fonts.headerFamily};
    font-weight: ${theme.fonts.headerWeight};
    letter-spacing: 0.5px;
    ${props =>
      props.action &&
      `    display: flex;
    align-items: center;
    justify-content: space-between;`}
    ${props =>
      props.width &&
      css`
        width: ${props.width};
        margin: 1em auto;
      `};

    ${props =>
      props.textcolor &&
      css`
        color: ${props.textcolor};
      `};
    ${props =>
      props.padding
        ? css`
            padding: ${props.padding};
          `
        : css`
            padding: 0;
          `};
  }
`;

interface IPageSectionProps {
  backgroundImage?: string;
  spaced?: boolean;
}

const PageSection = styled.section<IPageSectionProps>`
  ${props =>
    props.spaced &&
    css`
      padding: 4rem 0 2rem;
      margin-bottom: 2rem;
    `};
  ${props => props.backgroundImage && `background-image: url(${props.backgroundImage})`};
`;

interface IStyledSectionProps {
  background?: string;
  spaced?: boolean;
}

// Section with light grey background color and spacing
const StyledSection = styled('section')<IStyledSectionProps>`
  background: ${props => (props.background ? props.background : props.theme.colors.white)};
  font-family: ${props => props.theme.fonts.bodyFamily};
  padding: 2rem 1rem;
  ${props => props.spaced && 'margin-top: 66px'};
`;

interface IStyledCardProps {
  width?: string;
  margin?: string;
  imageheight?: string;
}

// Card https://react.semantic-ui.com/views/card/
const StyledCard = styled(Card)<IStyledCardProps>`
  &&&& {
    min-width: calc(320px - 2rem);
    background: ${props => props.theme.colors.white};
    box-shadow: ${props => props.theme.shadows[1]};
    height: auto;

    ${props =>
      props.width &&
      css`
        width: ${props.width};
      `};

    ${props =>
      props.margin &&
      css`
        margin: ${props.margin};
      `};

    &.has-list {
      overflow: hidden;
    }

    .media-element-container {
      overflow: hidden;
      border-radius: 0.28571429rem 0.28571429rem 0 0;
    }

    .featured-image {
      position: relative;
      overflow: hidden;
      line-height: 0;

      ${props =>
        props.imageheight &&
        css`
          height: ${props.imageheight};

          & img {
            height: inherit;
          }
        `};
    }

    .content.no-padding {
      padding: 0;
    }

    .main .meta,
    .categories,
    .spaced-apart {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .categories {
      min-width: 36px;
    }

    .meta {
      text-transform: uppercase;
      font-size: 0.75rem;
      svg:not(.path),
      img {
        height: 16px;
        width: auto;
        margin-left: 0.5rem;
      }
    }

    h3.header {
      margin: 1rem 0;
      a {
        color: ${props => props.theme.colors.textColor};
        svg {
          margin-left: 0.5rem;
          height: 1rem;
          width: auto;
        }
        &:hover {
          color: ${props => props.theme.colors.primary};
        }
      }
    }

    &.quicklink {
      min-width: auto;
      background-color: transparent;
      border: 1px solid ${props => props.theme.colors.mediumgrey};
      box-shadow: none;
      transition: all 0.2s ease-in-out;
      font-family: ${props => props.theme.fonts.headerFamily};
      font-weight: ${props => props.theme.fonts.headerWeight};
      color: ${props => props.theme.colors.textColor};
      &:hover {
        border: 1px solid ${props => props.theme.colors.mediumgrey};
        color: ${props => props.theme.colors.textColor};
        box-shadow: ${props => props.theme.shadows[1]};
        position: relative;
        z-index: 1;
      }
      .content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        span {
          display: inline-block;
          flex: 1 0 auto;
          margin-left: 1rem;
        }
        svg,
        img {
          width: 24px;
          height: auto;
        }
      }
    }

    .publication-status {
      display: flex;
      align-items: center;
      line-height: 1;
    }

    &.news .content.main {
      position: relative;
      padding-bottom: 3.5rem;
    }

    .content .description {
      font-family: ${props => props.theme.fonts.bodyFamily};
      font-size: 1.2rem;
      letter-spacing: 0.5px;
      line-height: 1.5;
      & + .meta {
        margin-top: 1rem;
      }
      p a {
        color: ${props => props.theme.colors.primary};
      }
    }

    &.news .content .description + .meta {
      position: absolute;
      left: 1rem;
      bottom: 1rem;
      width: calc(100% - 2rem);
    }

    .image-placeholder {
      position: relative;
      width: 100%;
      max-width: 100%;
      height: auto;
      padding-top: 60%;
      background-color: ${props => props.theme.colors.black};
      svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: ${props => props.theme.colors.white};
        height: auto;
        width: 3rem;
        stroke-width: 1;
      }
    }

    .content.tags {
      text-align: left;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      padding: 0.25rem 0.5rem;
    }

    .deadline {
      text-align: center;
      font-family: ${props => props.theme.fonts.headerFamily};
      background: ${props => props.theme.colors.offwhite};
    }

    .actions {
      margin-top: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      .button {
        display: block;
        width: 100%;
        & + .button {
          margin: 1rem 0 0 0;
        }
        ${up('largeMonitor')} {
          display: inline-block;
          width: auto;
          & + .button {
            margin: 0 0 0 1rem;
          }
        }
      }
    }

    &:hover {
      box-shadow: ${props => props.theme.shadows[2]};
      position: relative;
      z-index: 1;
    }
  }
`;

interface IStyledBannerProps {
  image: string;
}

// Page Banner
const StyledBanner = styled.div<IStyledBannerProps>`
  margin-bottom: 4rem;
  min-height: 400px;
  background-image: url(${props => props.image});
  background-size: cover;
  background-position: center;
  position: relative;
  .content,
  &:after {
    content: '';
    position: absolute;
  }
  &:after {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: rgba(17, 21, 29, 0.75);
  }
  .content {
    top: 50%;
    left: 50%;
    width: calc(100% - 2rem);
    transform: translate(-50%, -50%);
    z-index: 2;
    text-align: center;
    color: ${props => props.theme.colors.white};
  }
  h1 {
    font-family: ${props => props.theme.fonts.headerFamily};
    font-weight: ${props => props.theme.fonts.headerWeight};
    font-size: 3.5rem;
  }
  p {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
  }

  @media (max-width: ${props => props.theme.breakpoints.tablet}) {
    .banner {
      margin-bottom: 2rem;
      max-height: 600px;
      position: static;
      padding-top: 0;
    }

    h1 {
      font-size: 2rem;
    }

    p {
      font-size: 1rem;
      margin-bottom: 0.5rem;
    }
  }
`;

const StyledBlogPost = styled.div`
  .banner {
    margin-bottom: 2rem;
    max-height: 600px;
    position: relative;
    padding-top: 30%;
  }
`;

interface ICategoryDotProps {
  key: string;
  color: string;
  text: string;
}

// Category Dot + Tooltip for News Posts
const CategoryDot = styled.span<ICategoryDotProps>`
  &&& {
    margin-right: 0.5rem !important;
  }
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${props => (props.color ? props.color : props.theme.colors.darkgrey)};
  position: relative;
  display: inline-block;
  cursor: pointer;
  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: ${props =>
      props.text === 'light' ? props.theme.colors.white : props.theme.colors.textColor};
    border-radius: 50%;
    width: 0;
    height: 0;
    transition: all 0.1s ease-in-out;
  }
  .tooltip {
    position: absolute;
    left: 50%;
    bottom: calc(100% + 0.5rem);
    transform: translate(-50%, 0);
    z-index: 3;
    background-color: ${props => (props.color ? props.color : props.theme.colors.darkgrey)};
    text-transform: uppercase;
    font-family: ${props => props.theme.fonts.bodyFamily};
    font-weight: 700;
    letter-spacing: 0.5px;
    color: ${props =>
      props.text === 'light' ? props.theme.colors.white : props.theme.colors.textColor};
    border-radius: 0.25rem;
    box-shadow: ${props => props.theme.shadows[1]};
    min-width: 100%;
    width: auto;
    white-space: nowrap;
    font-size: 0.75rem;
    display: inline-block;
    text-align: center;
    padding: 0.25rem 0.5rem;
    visibility: hidden;
    opacity: 0;
    transition: all 0.1s ease-in-out;
  }
  &:hover .tooltip {
    visibility: visible;
    opacity: 1;
  }
  &:hover :after {
    width: 4px;
    height: 4px;
  }
`;

interface IPostTagProps {
  key: number;
  label: string;
  children: any;
}

// Blog Post Tags
const PostTag = styled.span<IPostTagProps>`
  color: ${props => props.theme.colors.textColor};
  background: ${props =>
    props.label === 'true' ? props.theme.colors.white : props.theme.colors.lightgrey};
  padding: 0.25rem 0.5rem;
  font-size: 0.55rem;
  letter-spacing: 0.5px;
  border-radius: 0.5rem;
  text-transform: uppercase;
  font-family: ${theme.fonts.headerFamily};
  font-weight: ${theme.fonts.headerWeight};
  white-space: nowrap;
  margin: 0.25rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  svg {
    height: 0.85rem;
    width: auto;
    margin-right: 0.5rem;
  }
`;

export {
  StyledNavBar,
  StyledLink,
  StyledExternalLink,
  StyledDivLink,
  StyledSup,
  StyledGroup,
  PageSection,
  StyledHeader,
  StyledSection,
  StyledCard,
  StyledBanner,
  StyledBlogPost,
  CategoryDot,
  PostTag,
};
