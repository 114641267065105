import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Placeholder, Card, SemanticWIDTHS } from 'semantic-ui-react';

interface IProps {
  length: number;
  itemsPerRow: SemanticWIDTHS;
}

const CardsLoader = ({ length, itemsPerRow }: IProps) => (
  <Fragment>
    <div style={{ marginBottom: '1rem' }}>
      <Placeholder style={{ height: 36, width: 150 }}>
        <Placeholder.Image />
      </Placeholder>
    </div>
    <Card.Group stackable itemsPerRow={itemsPerRow}>
      {Array.from({ length }, (v, i) => i).map(p => (
        <Card key={p}>
          <Card.Content>
            <Card.Header>
              <Placeholder>
                <Placeholder.Header>
                  <Placeholder.Line />
                </Placeholder.Header>
              </Placeholder>
            </Card.Header>
            <Card.Description>
              <Placeholder>
                <Placeholder.Line />
                <Placeholder.Line />
                <Placeholder.Line />
                <Placeholder.Line />
                <Placeholder.Line />
              </Placeholder>
            </Card.Description>
          </Card.Content>
        </Card>
      ))}
    </Card.Group>
  </Fragment>
);

CardsLoader.propTypes = {
  length: PropTypes.number,
  itemsPerRow: PropTypes.number,
};
CardsLoader.defaultProps = {
  length: 7,
  itemsPerRow: 4,
};

export default CardsLoader;
