import React, { ComponentType } from 'react';
import { Route } from 'react-router-dom';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';

import ErrorBoundary from '../error-boundary';
import Error from '../components/Error';
import PageLoader from '../components/PageLoader';

const ME = gql`
  query PrivateRoute_GetMe {
    me {
      id
      isAdmin
      firstName
      lastName
      email
      jobFamily
      hash
      teams {
        id
        name
        description
      }
    }
  }
`;

interface IProps {
  me: any;
  match: any;
  location: any;
}

const PrivateRoute = ({
  component: Component,
  ...rest
}: {
  component: ComponentType<IProps>;
  path: string;
  exact?: boolean;
}) => (
  <Route
    {...rest}
    render={props => (
      <ErrorBoundary>
        <Query<any, any> query={ME}>
          {({ loading, error, data }) => {
            if (loading) {
              return <PageLoader content="Time is an illusion. Lunchtime, doubly so." />;
            }
            if (error) {
              return <Error error={error} />;
            }
            const { me } = data;
            return <Component me={me} {...props} {...rest} />;
          }}
        </Query>
      </ErrorBoundary>
    )}
  />
);

PrivateRoute.displayName = 'PrivateRoute';

PrivateRoute.defaultProps = {};

export default PrivateRoute;
