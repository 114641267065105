import React from 'react';
import PropTypes from 'prop-types';
import { Button, Icon, Popup } from 'semantic-ui-react';

interface IProps {
  content: any;
}

const DisabledButtonPopup = ({ content }: IProps) => (
  <Popup
    trigger={
      <Button size="tiny" basic>
        <Icon name="remove" />
        Delete
      </Button>
    }
    content={content}
  />
);

DisabledButtonPopup.propTypes = {
  content: PropTypes.string.isRequired,
};

export default DisabledButtonPopup;
