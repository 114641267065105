import React from 'react';
import PropTypes from 'prop-types';

class ModalState extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false,
    };
  }

  toggleModal = () => {
    const { toggleCallback } = this.props;

    this.setState(prev => ({ modalOpen: !prev.modalOpen }));

    if (toggleCallback) {
      toggleCallback();
    }
  };

  render() {
    const { children } = this.props;
    const { modalOpen } = this.state;

    return children(modalOpen, this.toggleModal);
  }
}

ModalState.propTypes = {
  children: PropTypes.func.isRequired,
  toggleCallback: PropTypes.func,
};

ModalState.defaultProps = {
  toggleCallback: null,
};

export default ModalState;
