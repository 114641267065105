import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Grid, Input, Card as SemanticCard } from 'semantic-ui-react';

import CreateTeam from '../team/CreateTeam';
import DetailPaneContent from '../../components/DetailPane';
import Card from '../../components/Card';

class TeamsDetail extends React.Component {
  state = {
    filterValue: '',
    filteredData: this.props.teams,
  };

  render() {
    const { me } = this.props;
    const { filteredData, filterValue } = this.state;

    const handleFilterChange = (e, { value }) => {
      const { teams } = this.props;
      const term = value.toLowerCase();

      this.setState({
        filteredData: teams.filter(
          team =>
            team.name.toLowerCase().indexOf(term) > -1 ||
            team.description.toLowerCase().indexOf(term) > -1
        ),
        filterValue: term,
      });
    };
    const clearFilter = () => {
      this.setState({ filterValue: '', filteredData: this.props.teams });
    };

    const detailPaneContent = {
      description:
        'A Team is a group of users that can be associated to applications registered in Marvin.',
      title: 'Teams',
      descriptionTitle: 'definition',
      lists: [
        {
          listTitle: 'Team Details',
          listItems: [
            {
              key: 1,
              text: 'Teams',
              subtext: 'Teams may be given a name and description',
            },
            {
              key: 2,
              text: 'Team Members',
              subtext:
                'Users that are assigned to a team can register new apps in Marvin for that team',
            },
          ],
        },
      ],
    };

    return (
      <Grid stackable>
        <Grid.Column width={11} floated="left">
          <CreateTeam me={me} />
        </Grid.Column>
        <Grid.Column floated="right" width={5}>
          <Input
            fluid
            icon={
              filterValue === '' ? (
                <Icon name="search" />
              ) : (
                <Icon name="close" link onClick={clearFilter} />
              )
            }
            placeholder="Filter"
            value={filterValue}
            onChange={handleFilterChange}
          />
        </Grid.Column>
        <Grid.Column width={3} floated="left">
          <DetailPaneContent content={detailPaneContent} />
        </Grid.Column>
        <Grid.Column width={13}>
          <SemanticCard.Group itemsPerRow={4} stackable>
            {filteredData.map(team => {
              return (
                <Card
                  key={team.id}
                  title={team.name}
                  details={[team.description]}
                  linkto={`./team/${team.id}`}
                />
              );
            })}
          </SemanticCard.Group>
        </Grid.Column>
      </Grid>
    );
  }
}

TeamsDetail.propTypes = {
  me: PropTypes.object.isRequired,
  teams: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default TeamsDetail;
