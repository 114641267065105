import React, { Component } from 'react';
import PropTypes from 'prop-types';

import SplashImage from './NewNewSplash.png';
import { withTheme } from 'emotion-theming';
import styled from '../../styled';

const StyledSplash = styled.div`
  position: relative;
  min-height: ${(props: any) => (props.size === 'large' ? '425px' : '100px')};
  background-color: ${props => props.theme.colors.black};
  background-image: url(${SplashImage});
  background-size: cover;
  background-position: center;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(17, 21, 29, 0.5);
    z-index: 1;
  }
`;

interface IProps {
  size: string;
}

class Splash extends Component<IProps> {
  render() {
    return <StyledSplash {...this.props}>{this.props.children}</StyledSplash>;
  }

  static propTypes = {
    children: PropTypes.node,
    size: PropTypes.oneOf(['large', 'small']),
  };

  static defaultProps = {
    size: window.location.pathname === '/' ? 'large' : 'small',
  };
}

export default withTheme(Splash);
