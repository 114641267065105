let config = {
  clientId: 'YrL3ILFLWD1WA68sRg2HIp1sRFDjXgQV',
  domain: 'redventures-prod.auth0.com',
  resource: 'http://prod-https://dev-marvin-graphql-api',
  gaTrackingId: 'UA-137375995-1',
};

if (window.location.host.includes('localhost')) {
  config = {
    ...config,
    clientId: 'Fsa6G7vh3y7HSgIopyGKU3zg4ltG8sJE',
    domain: 'redventures-local.auth0.com',
    resource: 'local-marvin-graphql',
    gaTrackingId: '',
  };
}

export default config;
