import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import gql from 'graphql-tag';
import getErrorMessages from '../../util/errors';
import { useMutation } from '@apollo/react-hooks';
import ModalForm from '../../layouts/ModalForm';
import ModalState from '../../layouts/ModalState';
import UpdateEnvForm, { UPDATE_ENV_FORM_ID } from './UpdateEnvForm';

const UPDATE_ENV = gql`
  mutation UpdateEnv_UpdateEnv(
    $id: ID!
    $name: String!
    $description: String!
    $url: String!
    $isProduction: Boolean!
  ) {
    updateEnvironment(
      id: $id
      environment: {
        name: $name
        description: $description
        url: $url
        isProduction: $isProduction
      }
    ) {
      success
      error {
        message
        code
      }
      environment {
        id
      }
    }
  }
`;

const GET_APP = gql`
  query UpdateEnv_GetApp($id: ID!) {
    app(id: $id) {
      id
      name
      description
      repoUrl
      appType {
        id
        name
        description
        showRoles
        showEnvironmentsTab
        showClientTab
        authClientType
        showResourceTab
        showClientGrants
        showCallbacksField
        showLogoutURLsField
        showAllowedOriginsField
      }
      team {
        id
        name
        description
      }
      members {
        id
        firstName
        lastName
        email
        jobFamily
      }
      environments {
        id
        name
        url
        isProduction
        tenant
      }
    }
  }
`;

export const UpdateEnv = props => {
  const { envId, appId, name, url, isProduction } = props;

  const [updateEnvironment, { loading, error }] = useMutation(UPDATE_ENV, {
    refetchQueries: [
      {
        query: GET_APP,
        variables: { id: appId },
      },
    ],
    onCompleted: () => {
      toast.success('Successfully updated environment!');
    },
    onError: error => {
      toast.error(`Error encountered: ${getErrorMessages(error)}`, {
        autoClose: false,
      });
    },
  });

  return (
    <ModalState>
      {(modalOpen, toggleModal) => (
        <Fragment>
          <Icon id="updateEnvBtn" link name="edit" onClick={toggleModal} />
          {modalOpen && (
            <ModalForm
              header={`Update Environment: ${name}`}
              actionAttr={{
                positive: true,
                type: 'submit',
                form: UPDATE_ENV_FORM_ID,
              }}
              loading={loading}
              open={modalOpen}
              toggleModal={toggleModal}
              errors={getErrorMessages(error)}
              actionText="Update Environment"
            >
              <UpdateEnvForm
                toggleModal={toggleModal}
                updateEnvironment={updateEnvironment}
                name={name}
                envId={envId}
                appId={appId}
                url={url}
                isProduction={isProduction}
                loading={loading}
                errors={getErrorMessages(error)}
              />
            </ModalForm>
          )}
        </Fragment>
      )}
    </ModalState>
  );
};

UpdateEnv.propTypes = {
  appId: PropTypes.string.isRequired,
  envId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
};

export default UpdateEnv;
