import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button as SemanticButton } from 'semantic-ui-react';
import { LightenDarkenColor, PropStyles } from '../../styles/helpers';
import { withTheme } from 'emotion-theming';
import Styled from '../../styled';

interface IColors {
  colors: {
    primary: string;
    secondary: string;
    tertiary: string;
    textColor: string;
    white: string;
  };
}

interface IProps {
  text: string;
}

/*
 ** Variations of Buttons Based on props.variant
 */
const ButtonVariants = PropStyles('variant', ({ colors }: IColors) => {
  return {
    primary: {
      border: `1px solid ${colors.primary}`,
      color: colors.white,
      backgroundColor: colors.primary,
      '&:hover, &:focus, &:active': {
        backgroundColor: LightenDarkenColor(colors.primary, -20),
      },
    },
    secondary: {
      border: `1px solid ${colors.secondary}`,
      color: colors.white,
      backgroundColor: colors.secondary,
      '&:hover, &:focus, &:active': {
        backgroundColor: LightenDarkenColor(colors.secondary, -20),
      },
    },
    ghost: {
      border: `1px solid ${colors.white}`,
      color: colors.white,
      backgroundColor: 'transparent',
      '&:hover, &:focus, &:active': {
        border: `1px solid ${colors.secondary}`,
        backgroundColor: LightenDarkenColor(colors.secondary, -20),
      },
    },
    tertiary: {
      border: `1px solid ${colors.tertiary}`,
      color: colors.textColor,
      backgroundColor: colors.tertiary,
      '&:hover, &:focus, &:active': {
        backgroundColor: LightenDarkenColor(colors.tertiary, -20),
      },
    },
  };
});

const StyledButton = Styled(SemanticButton)`
  &&& {
    border-radius: 0;
    font-family: ${props => props.theme.fonts.headerFamily};
    font-weight: ${props => props.theme.fonts.headerWeight};
    ${ButtonVariants}
  }
`;

class Button extends Component<IProps> {
  static propTypes = {
    text: PropTypes.string,
    variant: PropTypes.oneOf(['primary', 'secondary', 'ghost', 'tertiary']),
  };

  static defaultProps = {
    text: 'Button',
    variant: 'primary',
  };

  render() {
    const { text } = this.props;
    return <StyledButton {...this.props}>{text}</StyledButton>;
  }
}

export default withTheme(Button);
