import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Button, Icon } from 'semantic-ui-react';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import getErrorMessages from '../../util/errors';

import ModalState from '../../layouts/ModalState';
import ModalForm from '../../layouts/ModalForm';
import TeamForm, { TEAM_FORM_ID } from './TeamForm';

const GET_TEAM = gql`
  query UpdateTeam_GetTeam($id: ID!) {
    team(id: $id) {
      id
      name
      description
      apps {
        id
        name
        description
        appType {
          id
          name
          description
        }
      }
      members {
        id
        isAdmin
        firstName
        lastName
        email
        jobFamily
      }
    }
  }
`;

const ME = gql`
  query UpdateTeam_GetMe {
    me {
      id
      isAdmin
      firstName
      lastName
      email
      jobFamily
      hash
      teams {
        id
        name
        description
      }
    }
  }
`;

const UPDATE_TEAM = gql`
  mutation UpdateTeam_UpdateTeam(
    $id: ID!
    $name: String!
    $description: String!
    $members: [String!]!
  ) {
    updateTeam(id: $id, team: { name: $name, description: $description, members: $members }) {
      success
      error {
        message
        code
      }
      team {
        id
      }
    }
  }
`;

class UpdateTeam extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { team } = this.props;

    return (
      <ModalState>
        {(modalOpen, toggleModal) => (
          <Fragment>
            <Button size="tiny" onClick={toggleModal}>
              <Icon name="pencil" />
              Edit
            </Button>
            {modalOpen && (
              <Mutation
                mutation={UPDATE_TEAM}
                refetchQueries={[
                  {
                    query: GET_TEAM,
                    variables: { id: team.id },
                  },
                  {
                    query: ME,
                  },
                ]}
                awaitRefetchQueries
                onCompleted={toggleModal}
              >
                {(updateTeam, { loading, error }) => {
                  const errors = getErrorMessages(error);

                  return (
                    <ModalForm
                      header={`Update Team: ${team.name}`}
                      actionAttr={{
                        positive: true,
                        type: 'submit',
                        form: TEAM_FORM_ID,
                      }}
                      loading={loading}
                      open={modalOpen}
                      toggleModal={toggleModal}
                      errors={errors}
                    >
                      <TeamForm
                        onSubmit={updateTeam}
                        loading={loading}
                        team={team}
                        errors={errors}
                      />
                    </ModalForm>
                  );
                }}
              </Mutation>
            )}
          </Fragment>
        )}
      </ModalState>
    );
  }
}

UpdateTeam.propTypes = {
  team: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
};

export default UpdateTeam;
