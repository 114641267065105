import React from 'react';
import PropTypes from 'prop-types';
import { Button, Loader, SemanticCOLORS } from 'semantic-ui-react';

interface IProps {
  color: SemanticCOLORS;
  basic: boolean;
}

const ButtonLoader = ({ color, basic }: IProps) => (
  <Button basic={basic} color={color} disabled>
    <Loader active inline size="mini" />
  </Button>
);

ButtonLoader.propTypes = {
  color: PropTypes.string,
  basic: PropTypes.bool,
};

ButtonLoader.defaultProps = {
  color: 'grey',
  basic: true,
};

export default ButtonLoader;
