import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Button, Checkbox } from 'semantic-ui-react';
import InputDescription from '../../components/InputDescription';
import Scopes from '../../components/Scopes';
import { useFlags } from 'launchdarkly-react-client-sdk';

export const RESOURCE_FORM_ID = 'create-resource-form-id';

const CreateResourceModal = props => {
  const { allowUsers: allowUsersFlag } = useFlags();
  const [allowUsers, setAllowUsers] = useState(false);
  const [envId, setEnvId] = useState('');
  const [identifier, setIdentifier] = useState('');
  const [name, setName] = useState('');
  const [isProduction, setIsProduction] = useState(false);
  const [scopes, setScopes] = useState([...props.scopes]);

  const { environments, appName, addResource, appId } = props;

  // generate default resource name & identifier
  const generateDefaults = ({ envId, appName, envName }) => {
    const name = `${envName} ${appName} resource`.replace(/[^a-zA-Z0-9-/\s]/g, '');
    return {
      envId,
      name,
      // remove special characters from identifier
      identifier: name.replace(/[^a-zA-Z0-9]/g, '-').toLowerCase(),
    };
  };

  const handleNameChange = (e, { value }) => {
    setName(value);
  };

  const handleAllowUsersChange = (e, data) => {
    setAllowUsers(data.checked);
  };

  const handleIdentifierChange = (e, data) => {
    setIdentifier(data.value);
  };

  const handleEnvChange = (e, { value: envId }) => {
    const { isProduction } = environments.find(env => env.id === envId);

    // get default values for all envs
    const allDefaults = environments.map(env =>
      generateDefaults({ envId: env.id, appName, envName: env.name })
    );

    // get defaults for this env
    const envDefaults = allDefaults.find(d => d.envId === envId);

    // if name is empty or the default of another env, update to this env's defaults
    if (name === '' || allDefaults.find(d => d.name === name)) setName(envDefaults.name);
    // if identifier is empty or the default of another env, update to this env's defaults
    if (identifier === '' || allDefaults.find(d => d.identifier === identifier))
      setIdentifier(envDefaults.identifier);
    setEnvId(envId);
    setIsProduction(isProduction);
  };

  const handleScopesChange = (e, data) => {
    setScopes(data.value);
  };

  const handleSubmit = e => {
    e.preventDefault();

    addResource({
      variables: {
        name,
        identifier,
        envId,
        isProduction,
        scopes,
        appId,
        allowUsers,
      },
    });
  };

  const handleClear = (e, data) => {
    e.preventDefault();
    // eslint-disable-next-line default-case
    switch (data.name) {
      case 'name':
        setName('');
        break;
      case 'envId':
        setEnvId('');
        break;
      case 'identifier':
        setIdentifier('');
        break;
      case 'isProduction':
        setIsProduction(false);
        break;
      case 'scopes':
        setScopes([...scopes]);
        break;
    }
  };

  return (
    <Fragment>
      <Form onSubmit={handleSubmit} id={RESOURCE_FORM_ID}>
        <Form.Dropdown
          id="environment"
          placeholder="Select Environment"
          name="environment"
          label="Environment"
          options={environments.map(env => ({
            text: env.name,
            description: env.isProduction ? 'Production' : 'Non-Production',
            icon: {
              name: 'world',
              color: env.isProduction ? 'green' : 'grey',
            },
            value: env.id,
            content: env.name,
          }))}
          value={envId}
          fluid
          search
          selection
          required
          searchInput={{ autoFocus: true }}
          openOnFocus={false}
          onChange={handleEnvChange}
        />
        <InputDescription description="You can create one resource for each environment." />
        <Form.Input
          id="name"
          placeholder="Resource Name"
          name="name"
          label="Name"
          value={name}
          required
          action={<Button basic size="small" name="name" icon="close" onClick={handleClear} />}
          onChange={handleNameChange}
          // allow alphanumeric, dashes, & spaces
          pattern="[-A-Za-z0-9\s]+"
        />
        <InputDescription description="A friendly name for the API." />
        <Form.Input
          id="identifier"
          placeholder="Resource Identifier"
          name="identifier"
          label="Identifier"
          value={identifier}
          required
          action={
            <Button basic size="small" name="identifier" icon="close" onClick={handleClear} />
          }
          onChange={handleIdentifierChange}
          // allow alphanumeric & dashes
          pattern="[-A-Za-z0-9]+"
        />

        <InputDescription>
          <Fragment>
            A unique, logical identifier for this API that cannot contain spaces or special
            characters. <strong>This field cannot be modified once created.</strong>
          </Fragment>
        </InputDescription>

        {allowUsersFlag && (
          <Fragment>
            <Form.Field
              style={{
                fontSize: '.92857143em',
                fontWeight: 700,
              }}
            >
              User Access
            </Form.Field>
            <Form.Input>
              <Checkbox
                id="allowUsers"
                placeholder="Allow Users"
                name="allowUsers"
                label={allowUsers ? 'Enabled' : 'Disabled'}
                toggle
                checked={allowUsers}
                onChange={handleAllowUsersChange}
              />
            </Form.Input>
            <InputDescription>
              <Fragment>
                <strong>Enable ability for users to access this resource directly</strong> instead
                of only allowing machine-to-machine connections
              </Fragment>
            </InputDescription>
          </Fragment>
        )}
      </Form>
      <Scopes onChange={handleScopesChange} scopes={scopes} showDeleteWarning={false} />
    </Fragment>
  );
};

CreateResourceModal.propTypes = {
  addResource: PropTypes.func.isRequired,
  environments: PropTypes.arrayOf(PropTypes.object),
  appName: PropTypes.string.isRequired,
  appId: PropTypes.string.isRequired,
  scopes: PropTypes.arrayOf(PropTypes.object),
};

CreateResourceModal.defaultProps = {
  environments: [],
  scopes: [
    { description: 'read all', value: 'read:all' },
    { description: 'write all', value: 'write:all' },
  ],
};

CreateResourceModal.displayName = 'CreateResourceModal';

export default CreateResourceModal;
