import { FC, ReactElement } from 'react';
import { withLDProvider } from 'launchdarkly-react-client-sdk';

interface IProps {
  children: ReactElement;
}

const Wrapper: FC<IProps> = props => props.children;

export const LaunchDarklyProvider = withLDProvider({
  clientSideID: '5d5443832f46270880b35168',
})(Wrapper);
