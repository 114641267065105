import React from 'react';
import { Loader, Statistic, Icon } from 'semantic-ui-react';

import { useQuery } from 'react-apollo';
import gql from 'graphql-tag';

import { LoaderContainer, StyledStatsGroup } from '../../styles/components';

const GET_STATS = gql`
  query Stats_GetAppsTeamsUsersStats {
    apps {
      id
      usesAuth
    }
    teams {
      id
    }
    users {
      id
    }
  }
`;

const authenticatedAppsTotal = apps => {
  let count = 0;
  if (!apps || !apps.length) return count;

  apps.forEach(app => app.usesAuth && count++);

  return count;
};

const Stats = () => {
  const { data, loading } = useQuery(GET_STATS);

  if (loading)
    return (
      <LoaderContainer>
        <Loader active>Fetching stats...</Loader>
      </LoaderContainer>
    );

  return (
    <StyledStatsGroup widths="four">
      <Statistic>
        <Statistic.Value>{data.apps.length}</Statistic.Value>
        <Statistic.Label>
          <Icon name="server" />
          Apps
        </Statistic.Label>
      </Statistic>

      <Statistic>
        <Statistic.Value>{authenticatedAppsTotal(data.apps)}</Statistic.Value>
        <Statistic.Label>
          <Icon name="lock" />
          Authenticated Apps
        </Statistic.Label>
      </Statistic>

      <Statistic>
        <Statistic.Value>{data.teams.length}</Statistic.Value>
        <Statistic.Label>
          <Icon name="group" />
          Teams
        </Statistic.Label>
      </Statistic>

      <Statistic>
        <Statistic.Value>{data.users.length}</Statistic.Value>
        <Statistic.Label>
          <Icon name="user" />
          People
        </Statistic.Label>
      </Statistic>
    </StyledStatsGroup>
  );
};

export default Stats;
