import React from 'react';
import PropTypes from 'prop-types';
import { Container, Grid, Button } from 'semantic-ui-react';
import { HelpCircle, UserCheck, Server, Book } from 'react-feather';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Link } from 'react-router-dom';

import Particles from '../../components/Particles';
import Highlights from '../../components/blog/Highlights';
import { PageSection, StyledHeader, StyledSection } from '../../styles/typed_components';
import Callout from './Callout';
import Stats from './Stats';
import './index.css';

import UserApps from '../userApps';

const Home = props => {
  const { blogHighlightsEnabled } = useFlags();

  return (
    <div>
      <PageSection spaced>
        <Container>
          <UserApps user={props.me} />
        </Container>
      </PageSection>

      <div
        style={{
          position: 'fixed',
          bottom: '94px',
          width: '100%',
        }}
      >
        <Particles>
          <Container>
            <Stats />
          </Container>
        </Particles>
      </div>
    </div>
  );
};

Home.propTypes = {
  me: PropTypes.object.isRequired,
};

export default Home;
