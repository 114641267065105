import React, { Fragment, FC, MouseEvent, Dispatch } from 'react';
import { Icon, Header, Grid, DropdownItemProps, List } from 'semantic-ui-react';
import ChooseResourceDropdown from './ChooseResourceDropdown';
import ResourceDetail from './ResourceDetail';
import DeleteResource from '../../scenes/auth-resources/DeleteResource';
import UpdateResource from '../../scenes/auth-resources/UpdateResource';
import { IEnvResource } from './EnvResources';

interface IProps {
  env: { id: string; name: string; tenant: string; isProduction: boolean };
  envs: {
    id: string;
    name: string;
    tenant: string;
    isProduction: boolean;
    resources: IEnvResource[];
  }[];
  resource: IEnvResource;
  meIsOwner: boolean;
  onResourceUpdate: Dispatch<React.SetStateAction<IEnvResource | undefined | null>>;
  handleButtonClick: (event: MouseEvent<HTMLDivElement>, data: DropdownItemProps) => void;
}

const EnvResourcePane: FC<IProps> = ({
  env,
  envs,
  resource,
  meIsOwner,
  handleButtonClick,
  onResourceUpdate,
}: IProps) => {
  const envIds = envs.map(env => env.id);
  return (
    env && (
      <Fragment>
        <Grid.Row columns="2">
          <Grid.Column floated="right" width="11" textAlign="right" verticalAlign="middle">
            <Header as="h3">Select a resource:</Header>
          </Grid.Column>
          <Grid.Column floated="right" width="5">
            <ChooseResourceDropdown
              envs={envs}
              handleClick={handleButtonClick}
              selectedResourceName={resource.name}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Header as={'h1'}>
              <Header.Content>
                {resource.name}
                <Header.Subheader>
                  <Icon name="world" color={env.isProduction ? 'green' : 'grey'} />
                  {env.name}
                  {' - '}
                  {env.isProduction ? 'Production' : 'Non-Production'}
                </Header.Subheader>
              </Header.Content>
            </Header>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column>
            <Fragment>
              <Grid.Row>
                <Grid.Column>
                  <ResourceDetail env={env} resource={resource} resourceId={resource.resourceId} />
                </Grid.Column>
              </Grid.Row>
              {meIsOwner && (
                <List relaxed="very">
                  <List.Item>
                    <List.Header as={'h2'} style={{ marginBottom: '1.6em' }}>
                      Resource Settings
                    </List.Header>
                    <List.Content>
                      <Fragment>
                        <UpdateResource
                          envIds={envIds}
                          envId={env.id}
                          allowUsers={resource.allowUsers}
                          name={resource.name}
                          tenant={resource.tenant}
                          resourceId={resource.resourceId}
                          scopes={resource.scopes}
                          onResourceUpdate={onResourceUpdate}
                        />
                        <DeleteResource
                          envIds={envIds}
                          name={resource.name}
                          tenant={resource.tenant}
                          identifier={resource.identifier}
                          resourceId={resource.resourceId}
                          onResourceUpdate={onResourceUpdate}
                        />
                      </Fragment>
                    </List.Content>
                  </List.Item>
                </List>
              )}
            </Fragment>
          </Grid.Column>
        </Grid.Row>
      </Fragment>
    )
  );
};

export default EnvResourcePane;
