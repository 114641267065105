/*
 ** Marvin Brand Variables
 */
export interface ITheme {
  colors: {
    primary: string;
    secondary: string;
    tertiary: string;
    error: string;
    textColor: string;
    black: string;
    darkgrey: string;
    mediumgrey: string;
    lightgrey: string;
    white: string;
    green: string;
    teal: string;
    notificationYellow: string;
    purple: string;
    offwhite: string;
  };
  fonts: {
    bodyFamily: string;
    bodyWeight: string;
    headerFamily: string;
    headerWeight: string;
  };
  breakpoints: {
    tablet: string;
    smallMonitor: string;
    largeMonitor: string;
  };
  baseSize: string;
  materialShadow: string;
  shadows: {
    0: string;
    1: string;
    2: string;
    3: string;
    4: string;
    5: string;
  };
}

const theme: ITheme = {
  colors: {
    primary: '#1F2532',
    secondary: '#21ba45',
    tertiary: '#FFE66D',
    error: '#ED707F',
    textColor: '#11151D',
    black: '#343434',
    darkgrey: '#8D8D8F',
    mediumgrey: '#DADADA',
    lightgrey: '#F2F5F7',
    white: '#FFFFFF',
    green: '#21ba45',
    teal: '#1fbbbe',
    notificationYellow: '#fbbd08',
    purple: '#5D4890',
    offwhite: '#F5F2D0',
  },
  fonts: {
    bodyFamily: '"Lato", sans-serif',
    bodyWeight: '400',
    headerFamily: '"Catamaran", sans-serif',
    headerWeight: '900',
  },
  // Breakpoints From Semantic UI Container Docs https://semantic-ui.com/elements/container
  breakpoints: {
    tablet: '768px',
    smallMonitor: '933px',
    largeMonitor: '1127px',
  },
  baseSize: '14px',
  // Material Shadows
  materialShadow: '0 1px 6px 0 rgba(32,33,36,0.28)',
  shadows: {
    0: 'none',
    1: '0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2)',
    2: '0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.3)',
    3: '0 8px 17px 2px rgba(0,0,0,0.14), 0 3px 14px 2px rgba(0,0,0,0.12), 0 5px 5px -3px rgba(0,0,0,0.2)',
    4: '0 16px 24px 2px rgba(0,0,0,0.14), 0 6px 30px 5px rgba(0,0,0,0.12), 0 8px 10px -7px rgba(0,0,0,0.2)',
    5: '0 24px 38px 3px rgba(0,0,0,0.14), 0 9px 46px 8px rgba(0,0,0,0.12), 0 11px 15px -7px rgba(0,0,0,0.2)',
  },
};

export { theme };
