import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Form, Card, Divider } from 'semantic-ui-react';
import { Query } from 'react-apollo';
import { toast } from 'react-toastify';

import Error from '../../components/Error';
import TeamsDropdown from '../../components/TeamsDropdown';
import UsersDropdown from '../../components/UsersDropdown';
import AppAccordion from '../../components/AppAccordion';

import { theme } from '../../styles/theme';
import { getAppTypeIcon } from '../../util';
import gql from 'graphql-tag';

export const APP_FORM_ID = 'edit-app-form';

const GET_APP_TYPES = gql`
  query AppForm_GetAppTypes {
    appTypes {
      id
      name
      description
    }
  }
`;

class AppForm extends React.Component {
  constructor(props) {
    super(props);
    const { me, app, defaultTeam } = props;

    // this puts the form into a CREATE state
    if (!app) {
      // default to me's first team
      let defaultTeamId = '';
      if (me && me.teams && me.teams.length > 0) {
        defaultTeamId = me.teams[0].id;
      }
      if (defaultTeam) {
        defaultTeamId = defaultTeam.id;
      }

      this.state = {
        name: '',
        description: '',
        repoUrl: '',
        teamId: defaultTeamId,
        memberIds: me && me.id ? [me.id] : [],
        appTypeId: null,
      };
      return;
    }

    // Below puts the form into an UPDATE state
    const { name, description, repoUrl, team, members, appType } = app;

    this.state = {
      name,
      description,
      repoUrl,
      teamId: team.id,
      memberIds: members.map(m => m.id),
      appTypeId: appType.id,
    };
  }

  handleChange = (e, { name, value }) => this.setState({ [name]: value });

  handleSubmit = e => {
    e.preventDefault();
    const { name, description, repoUrl, teamId, memberIds, appTypeId } = this.state;
    const { app, onSubmit } = this.props;

    const variables = {
      name,
      description,
      repoUrl,
      teamId,
      members: memberIds,
      appTypeId,
    };

    if (app) {
      variables.id = app.id;
    }

    if (!appTypeId) {
      toast.error('Error encountered: Please select an app type.');
      return;
    }

    onSubmit({ variables });
  };

  render() {
    const { loading, errors, updating } = this.props;
    const { name, description, repoUrl, teamId, memberIds, appTypeId } = this.state;

    const adminAccordionItems = [
      {
        accordionTitle: 'What can Admins do?',
        accordionDetails: '',
        accordionItemText: [
          {
            key: 'admin-register',
            title: 'Register',
            details: 'Permissions, Clients, and Resources',
          },
          {
            key: 'admin-approve',
            title: 'Approve',
            details: 'Resource Access',
          },
          {
            key: 'admin-remove',
            title: 'Remove',
            details: 'Clients, Resources, or the Application itself',
          },
        ],
      },
    ];

    return (
      <Form
        onSubmit={this.handleSubmit}
        id={APP_FORM_ID}
        loading={loading}
        error={errors.length > 0}
      >
        {!updating && (
          <Query query={GET_APP_TYPES}>
            {({ loading, error, data }) => {
              if (error) {
                return (
                  <Error
                    error={error}
                    header='"I&#39;ve been talking to the main computer... it hates me."'
                  />
                );
              }
              if (loading) return null;

              return (
                <Fragment>
                  <label>
                    <b>Choose an application type:</b>
                  </label>
                  <Divider />
                  <Card.Group itemsPerRow="6" stackable>
                    {data.appTypes.map(type => (
                      <Card
                        key={type.id}
                        name="appTypeId"
                        value={type.id}
                        onClick={this.handleChange}
                        style={
                          appTypeId === type.id
                            ? { border: `1px solid ${theme.colors.purple}` }
                            : { border: `1px solid ${theme.colors.white}` }
                        }
                      >
                        {getAppTypeIcon(type.name)}
                        <Card.Content textAlign="center">
                          <Card.Header>{type.name}</Card.Header>
                          <Card.Description>{type.description}</Card.Description>
                        </Card.Content>
                      </Card>
                    ))}
                  </Card.Group>
                  <Divider hidden />
                </Fragment>
              );
            }}
          </Query>
        )}

        <Form.Input
          id="app-name"
          placeholder="Name"
          name="name"
          label="Name"
          required
          value={name}
          onChange={this.handleChange}
        />

        <Form.TextArea
          id="app-desc"
          placeholder="Description"
          name="description"
          label="Description"
          required
          value={description}
          onChange={this.handleChange}
        />

        <Form.Input
          id="app-repoUrl"
          placeholder="https://github.com/RedVentures/your-app"
          name="repoUrl"
          label="Repo URL"
          required
          value={repoUrl}
          onChange={this.handleChange}
        />

        <TeamsDropdown
          id="app-team"
          placeholder="Team Name"
          label="Team"
          name="teamId"
          value={teamId}
          onChange={this.handleChange}
        />

        <UsersDropdown
          id="app-admins"
          placeholder="Admin Name"
          label="Admin(s)"
          name="memberIds"
          value={memberIds}
          onChange={this.handleChange}
        />
        <AppAccordion id="adminAccordion" accordionItems={adminAccordionItems} />
      </Form>
    );
  }
}

AppForm.propTypes = {
  loading: PropTypes.bool.isRequired,
  updating: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  // app is used for EDIT only
  app: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    repoUrl: PropTypes.string,
    team: PropTypes.shape({
      id: PropTypes.string,
    }),
    members: PropTypes.array,
    appType: PropTypes.shape({
      id: PropTypes.string,
    }),
  }),
  // me is used for CREATE only
  me: PropTypes.shape({
    id: PropTypes.string,
    teams: PropTypes.array,
  }),
  // defaultTeam should only be used for CREATE
  defaultTeam: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  appTypeId: PropTypes.string,
  errors: PropTypes.array,
};

AppForm.defaultProps = {
  me: null,
  app: null,
  defaultTeam: null,
  appTypeId: null,
  errors: [],
  updating: false,
};

export default AppForm;
