import React, { Fragment, FC, MouseEvent } from 'react';
import { Dropdown, Icon, DropdownItemProps } from 'semantic-ui-react';
import { IEnvResource } from './EnvResources';

interface IProps {
  envs: {
    id: string;
    name: string;
    isProduction: boolean;
    tenant: string;
    resources: IEnvResource[];
  }[];
  selectedResourceName: string;
  handleClick: (event: MouseEvent<HTMLDivElement>, data: DropdownItemProps) => void;
}

const ChooseResourceDropdown: FC<IProps> = ({
  envs,
  selectedResourceName,
  handleClick,
}: IProps) => {
  return (
    <Dropdown
      placeholder="Select Resource"
      name="resourceId"
      text={selectedResourceName !== '' ? selectedResourceName : 'Select Resource'}
      scrolling
      fluid
      className="selection"
    >
      <Dropdown.Menu>
        {envs
          .filter(e => e.resources.length)
          .map(e => {
            const items = e.resources
              .filter(r => r.envId === e.id)
              .map(r => {
                return (
                  <Dropdown.Item
                    key={`item-${r.resourceId}`}
                    value={r.resourceId}
                    text={r.name}
                    env={e.id}
                    selected={r.name === selectedResourceName ? true : false}
                    onClick={handleClick}
                  />
                );
              });
            return (
              <Fragment key={e.id}>
                <Dropdown.Header
                  content={
                    <Fragment>
                      <Icon name="world" color={e.isProduction ? 'green' : 'grey'} />
                      {e.name}
                    </Fragment>
                  }
                />
                {items}
              </Fragment>
            );
          })}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ChooseResourceDropdown;
