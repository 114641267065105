import _ from 'lodash';
import React, { useState } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { useQuery } from 'react-apollo';
import {
  Search,
  SearchProps,
  Icon,
  SemanticICONS,
  SearchResultProps,
  Header,
} from 'semantic-ui-react';
import Error from '../../components/Error';
import gql from 'graphql-tag';
import { registryIcons } from '../../constants';
import styles from './index.module.css';
import { RawResult, NormalizedResult } from '../../types/search';
import { ROUTE_MAP } from '../../constants';

gql`
  input SearchOptions {
    indexName: String
    limit: Int
    fields: [String]
  }
`;

const SEARCH_TERM = gql`
  query SearchBar_SearchAllIndexes($query: String!, $options: SearchOptions) {
    searchAllIndexes(query: $query, options: $options) {
      hits {
        index
        type
        id
        score
        title
        description
        key
      }
    }
  }
`;

type PathParamsType = {};
type PropsType = RouteComponentProps<PathParamsType> & {
  header?: string;
  defaultValue?: string;
  queryLimit?: number;
  fields?: string[];
};

const resultRenderer = (resultItem: SearchResultProps) => {
  const { title, description, icon } = resultItem;
  return (
    <div key="content" className="content">
      {icon && <Icon className="price" name={icon} />}
      {title && <div className="title">{title}</div>}
      {description && <div className="description">{description}</div>}
    </div>
  );
};

const SearchBar = ({ history, header, queryLimit = 60, fields }: PropsType) => {
  const [searchValue, setValue] = useState('');
  const queryArgs = {
    variables: {
      query: searchValue,
      options: {
        limit: queryLimit,
        ...(fields && { fields: fields }),
      },
    },
    skip: !searchValue,
  };

  const { loading, error, data } = useQuery(SEARCH_TERM, queryArgs);
  if (error) {
    return (
      <Error error={error} header='"I&#39;ve been talking to the main computer... it hates me."' />
    );
  }

  // update our results set
  let searchResults = [];
  if (_.get(data, 'searchAllIndexes.hits', []).length > 0) {
    searchResults = data.searchAllIndexes.hits.map((result: RawResult) => {
      // get correct icon
      const resultType = result.index;
      const iconName: SemanticICONS = registryIcons[resultType];

      const shortDescription = _.truncate(result.description, {
        length: 70,
        separator: ' ,;.',
      });

      return {
        ...result,
        description: shortDescription,
        icon: iconName,
      };
    });

    // add a 'see more' results entry
    searchResults.push({
      key: 'more-results-item',
      title: `See all results...`,
    });
  }

  const handleResultSelect = (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
    { result: clickedResult }: { result: NormalizedResult }
  ) => {
    /** TODO: We'll need to figure out the correct logic for handling blogs we use a slug instead of an id, and the slug may not be indexed;
     * alternatively we can look at storing and indexing the subdomain. for no work at runtime?
     * */
    // build correct route
    const isShowMore = clickedResult.id === 'more-results-item';
    const routeType = (ROUTE_MAP as any)[clickedResult.index];
    const routeName = isShowMore ? '/search' : `/${routeType}/${clickedResult.id}`;

    // add state if this is the show more btn
    const routeState = isShowMore ? { searchValue: searchValue } : null;

    history.push(routeName, routeState);
  };

  return (
    <div className={styles.autosuggestParentContainer}>
      {!!header && (
        <Header as="p" size="huge" inverted>
          {header}
        </Header>
      )}
      {
        <Search
          loading={loading}
          resultRenderer={resultRenderer}
          onResultSelect={handleResultSelect}
          onSearchChange={(
            e: React.MouseEvent<HTMLElement, MouseEvent>,
            { value = '' }: SearchProps
          ) => setValue(value)}
          results={searchResults}
          value={searchValue}
          noResultsMessage="No results found."
          noResultsDescription="This part of the universe is kind of empty..."
          selectFirstResult
        />
      }
    </div>
  );
};

export default withRouter(SearchBar);
