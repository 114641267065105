import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Card as SemanticCard } from 'semantic-ui-react';
import { withTheme } from 'emotion-theming';
import { Link } from 'react-router-dom';

import { PropStyles } from '../../styles/helpers';
import { Header } from '../../styles/components';
import styled from '../../styled';

interface IColors {
  colors: {
    primary: string;
    secondary: string;
    tertiary: string;
    textColor: string;
    white: string;
    error: string;
  };
}

interface IProps {
  title: string;
  subtitle: string;
  linkto: string;
}

/*
 ** Variations of Cards Based on props.variant
 */
const CardVariants = PropStyles('variant', ({ colors }: IColors) => {
  return {
    positive: {
      '.status-icons svg': {
        color: colors.secondary,
        '&:last-child': {
          fill: colors.secondary,
        },
      },
      '&:hover, &:active, &:focus': {
        border: `1px solid ${colors.secondary}`,
      },
    },
    negative: {
      '.status-icons svg': {
        color: colors.error,
        '&:last-child': {
          fill: colors.error,
        },
      },
      '&:hover, &:active, &:focus': {
        border: `1px solid ${colors.error}`,
      },
    },
  };
});

const StyledCard = styled(SemanticCard)`
  &&&& {
    box-shadow: none;
    overflow: hidden;
    border-radius: 0.25rem;
    padding: 0.5rem 0.5rem 0.7rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    border: 1px solid ${props => props.theme.colors.mediumgrey};
    text-align: center;
    color: ${props => props.theme.colors.textColor};
    ${CardVariants};
    .details {
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .info {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      height: 0.85rem;
    }
    ${Header} {
      font-size: 1.2rem;
      margin-top: 0.2rem;
      margin-bottom: 0.2rem;
    }
    &:hover {
      /* If used AS a Link Semantic has additional styles we need to override since we will probably be doing that */
      transform: none;
      box-shadow: none;
      border: 1px solid ${props => props.theme.colors.secondary};
    }
  }
`;

class MiniCard extends Component<IProps> {
  static propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    linkto: PropTypes.string,
  };

  static defaultProps = {
    title: 'Monocle',
    subtitle: '',
    linkto: '',
  };

  render() {
    const { title, subtitle, linkto } = this.props;
    return (
      <StyledCard {...this.props} as={Link} to={linkto}>
        <Header textAlign="center">{title}</Header>
        <p className="details">{subtitle}</p>
      </StyledCard>
    );
  }
}

export default withTheme(MiniCard);
