import React, { FC, ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from 'react-apollo';
import Error from '../Error';
import { Placeholder } from 'semantic-ui-react';
import DataGrid from '../DataGrid';
import DeleteAuthRole from './DeleteAuthRole';
import gql from 'graphql-tag';

const GET_CLIENTS_BY_IDS = gql`
  query AuthRoleGrid_GetClientsByIDs($tenant: String!, $clientIds: [ID!]) {
    clientsByIDs(client_ids: $clientIds, tenant: $tenant) {
      clientId: client_id
      envId: environment_id
      name
      description
      app {
        id
        name
        team {
          id
          name
        }
      }
    }
  }
`;

interface IAuthRole {
  description: string;
  name: string;
  app: {
    id: string;
  };
  permissions: {
    name: string;
    description: string;
  }[];
  role_id: string;
  status: string;
  users: { user_id: string; email: string }[];
  resource_id: string;
}

interface IGridRole {
  id: string;
  rowId: number;
  users: string[];
  scope: string[];
  clientName: React.ReactElement;
  deleteButton: React.ReactElement;
}

interface IProps {
  tenant: string;
  authRoles: IAuthRole[];
}

const AuthRoleGrid: FC<IProps> = ({ tenant, authRoles }: IProps): ReactElement => {
  const clientIds = new Set();

  authRoles.forEach(({ name }) => {
    const [clientId] = name.split('::');
    clientIds.add(clientId);
  });

  const { loading, error, data } = useQuery(GET_CLIENTS_BY_IDS, {
    variables: { clientIds: Array.from(clientIds), tenant },
  });

  if (error) return <Error error={error} />;

  const gridData: IGridRole[] = [];

  // normalize data, if available
  if (data) {
    const { clientsByIDs } = data;
    authRoles.forEach((authRole, index) => {
      const [clientId] = authRole.name.split('::');

      const client = clientsByIDs.find(
        (c: { clientId: string; name: string; app: { id: string } }) => {
          if (c !== null) return clientId === c.clientId;
          else return false;
        }
      );

      // display the role; if an actual client for this role exists
      if (client) {
        const clientAppLink = <Link to={`/app/${client.app?.id}`}>{client.name}</Link>;
        gridData.push({
          id: authRole.role_id,
          rowId: index + 1,
          users: authRole.users.map(u => u.email),
          scope: authRole.permissions.map(p => p.name),
          clientName: clientAppLink,
          deleteButton: (
            <DeleteAuthRole
              id={authRole.role_id}
              clientId={client.clientId}
              tenant={tenant}
              resourceId={authRole.resource_id}
            />
          ),
        });
      }
    });
  }

  // A name for the user group that should have the permissions listed.
  const columns = [
    {
      id: 1,
      name: 'Group',
      dataField: 'rowId',
      dataType: 'string',
    },
    {
      id: 2,
      name: 'Group Permissions',
      dataField: 'scope',
      dataType: 'arrayOfValues',
      icon: 'info',
      iconText: 'Permissions assigned to the users that belong to the group.',
    },
    {
      id: 3,
      name: 'Group Users',
      dataField: 'users',
      dataType: 'arrayOfValues',
      icon: 'info',
      iconText: 'Users that should receive the permissions assigned to the group.',
    },
    {
      id: 4,
      name: 'Associated Client',
      dataField: 'clientName',
      dataType: 'component',
      icon: 'info',
      iconText: 'The client this group was requested for.',
    },
    {
      id: 5,
      name: '',
      dataField: 'deleteButton',
      dataType: 'component',
    },
  ];

  return (
    <DataGrid
      data={gridData}
      columns={columns}
      noItemsMessage={'No user groups found for your resource'}
      loading={loading}
      placeholder={
        <Placeholder>
          <Placeholder.Line />
          <Placeholder.Line />
          <Placeholder.Line />
          <Placeholder.Line />
          <Placeholder.Line />
        </Placeholder>
      }
    />
  );
};

export default AuthRoleGrid;
