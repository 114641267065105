import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';
import { Button, Icon } from 'semantic-ui-react';
import { toast } from 'react-toastify';

import gql from 'graphql-tag';
import UpdateClientForm, { CLIENT_FORM_ID } from './UpdateClientForm';
import ModalState from '../../layouts/ModalState';
import ModalForm from '../../layouts/ModalForm';
import getErrorMessages from '../../util/errors';

const GET_ENV_CLIENTS = gql`
  query UpdateClient_GetEnvClients($envs: [ID]!) {
    envClients(envs: $envs) {
      webOrigins: web_origins
      callbacks
      clientId: client_id
      description
      envId: environment_id
      logoutUrls: allowed_logout_urls
      name
      tenant
    }
  }
`;

const UPDATE_CLIENT = gql`
  mutation UpdateClient_UpdateClient(
    $webOrigins: [String]
    $callbacks: [String]
    $clientId: ID!
    $description: String
    $logoutUrls: [String]
    $name: String!
    $tenant: String!
  ) {
    updateClient(
      web_origins: $webOrigins
      allowed_logout_urls: $logoutUrls
      callbacks: $callbacks
      client_id: $clientId
      description: $description
      name: $name
      tenant: $tenant
    ) {
      clientId: client_id
    }
  }
`;

const UpdateClient = ({
  appType,
  webOrigins,
  callbacks,
  clientId,
  description,
  environments,
  logoutUrls,
  name,
  tenant,
}) => {
  const envs = environments.map(e => e.id);

  return (
    <ModalState>
      {(modalOpen, toggleModal) => (
        <Fragment>
          <Button size="tiny" onClick={toggleModal}>
            <Icon name="pencil" />
            Edit
          </Button>
          {modalOpen && (
            <Mutation
              mutation={UPDATE_CLIENT}
              refetchQueries={[
                {
                  query: GET_ENV_CLIENTS,
                  variables: { envs },
                },
              ]}
              awaitRefetchQueries
              onCompleted={() => {
                toggleModal();
                toast.success('Successfully updated client!');
              }}
              onError={error =>
                toast.error(`Error encountered: ${getErrorMessages(error)}`, {
                  autoClose: false,
                })
              }
            >
              {(updateClient, { loading, error }) => {
                const errors = getErrorMessages(error);

                return (
                  <ModalForm
                    header={`Update Client: ${name}`}
                    actionAttr={{
                      positive: true,
                      type: 'submit',
                      form: CLIENT_FORM_ID,
                    }}
                    actionText="Update Client"
                    errors={errors}
                    loading={loading}
                    open={modalOpen}
                    toggleModal={toggleModal}
                  >
                    <UpdateClientForm
                      appType={appType}
                      webOrigins={webOrigins}
                      callbacks={callbacks}
                      clientId={clientId}
                      description={description}
                      errors={errors}
                      logoutUrls={logoutUrls}
                      name={name}
                      updateClient={updateClient}
                      tenant={tenant}
                    />
                  </ModalForm>
                );
              }}
            </Mutation>
          )}
        </Fragment>
      )}
    </ModalState>
  );
};

UpdateClient.propTypes = {
  webOrigins: PropTypes.arrayOf(PropTypes.string),
  callbacks: PropTypes.arrayOf(PropTypes.string),
  clientId: PropTypes.string.isRequired,
  description: PropTypes.string,
  environments: PropTypes.arrayOf(PropTypes.object),
  logoutUrls: PropTypes.arrayOf(PropTypes.string),
  name: PropTypes.string.isRequired,
};

UpdateClient.defaultProps = {
  webOrigins: [],
  callbacks: [],
  description: '',
  environments: [],
  logoutUrls: [],
};

UpdateClient.displayName = 'UpdateClient';

export default UpdateClient;
