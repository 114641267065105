import React, { Fragment, FC } from 'react';
import { Divider } from 'semantic-ui-react';
import Error from '../../Error';
import AppAccordion from '../../AppAccordion';
import EnvResources from '../EnvResources';

const accordionItems = [
  {
    key: 'resource',
    accordionDetails:
      'A resource is an application that is capable of accepting and responding to protected requests made by clients.',
    accordionTitle: 'What is a resource?',
    accordionItemText: [],
  },
];

interface IProps {
  appId: string;
  appName: string;
  meIsOwner: boolean;
  environments: {
    id: string;
    name: string;
    tenant: string;
    isProduction: boolean;
  }[];
}

const AuthResourceTab: FC<IProps> = ({ appId, appName, meIsOwner, environments }: IProps) => {
  const envIds = environments.map(e => e.id);
  if (!envIds) {
    return <Error errors={['Please register an environment before setting up authentication.']} />;
  }

  return (
    <Fragment>
      <AppAccordion accordionItems={accordionItems} />
      <Divider hidden />
      <EnvResources
        appId={appId}
        appName={appName}
        meIsOwner={meIsOwner}
        environments={environments}
      />
    </Fragment>
  );
};

export default AuthResourceTab;
