import React, { Fragment, useState } from 'react';
import { Card, Image } from 'semantic-ui-react';
import PropTypes from 'prop-types';

import { StyledCard, CategoryDot, StyledHeader, PostTag } from '../../styles/typed_components';
import { IBlogPost, IBlogTaxonomy } from '../blog/interfaces';
import { convertDateStringToDate } from '../../util/date';
import { Redirect } from 'react-router-dom';
import readingTime from 'reading-time';

interface IBlogCardProps {
  post: IBlogPost;
  showExcerpt?: boolean;
  featured?: boolean;
  width?: string;
  margin?: string;
  imageheight?: string;
}

const displayCardImage = (thumbnail: string) => {
  if (thumbnail) {
    return <Image className="featured-image" src={thumbnail} wrapped ui={false} />;
  }
};

const displayCategories = (categories: IBlogTaxonomy[]) => {
  if (categories.length < 1) {
    return <Fragment />;
  }

  return categories.map(cat => {
    if (cat.slug === 'uncategorized') return <Fragment key={`category-${cat.id}`} />;
    return (
      <span key={`category-container-${cat.id}`} className="categories">
        <CategoryDot key={`category-${cat.id}`} color={cat.color} text={cat.text}>
          <span className="tooltip">{cat.name}</span>
        </CategoryDot>
      </span>
    );
  });
};

const displayTags = (tags: IBlogTaxonomy[]) => {
  if (tags.map.length < 1) {
    return <Fragment />;
  }

  return (
    <Card.Content extra className="tags">
      <PostTag key={0} label="true">
        Tags
      </PostTag>
      {tags.map(t => {
        if (t.slug === 'uncategorized') return <Fragment key={`tag-${t.id}`} />;

        return (
          <PostTag label="false" key={t.id}>
            {t.name}
          </PostTag>
        );
      })}
    </Card.Content>
  );
};

const parseSlug = (link: string) => {
  const regex = /(http|https):\/\/.*\/blog\/(.*)\/?$/;
  const matches = regex.exec(link);
  const slugIndex = 2;
  return matches ? matches[slugIndex] : '';
};

const BlogCard = (props: IBlogCardProps) => {
  const { post, showExcerpt, featured, margin, width, imageheight } = props;
  const [redirect, setRedirect] = useState(false);

  if (redirect) {
    return (
      <Redirect
        push
        to={{
          pathname: `/blog/${parseSlug(post.link)}`,
        }}
      />
    );
  }

  const stats = readingTime(post.content);
  return (
    <StyledCard
      key={post.id}
      onClick={() => setRedirect(true)}
      title={post.title}
      margin={margin}
      width={width}
      imageheight={imageheight}
    >
      {featured && displayCardImage(post.thumbnail)}
      <Card.Content className="main">
        <Card.Meta>{displayCategories(post.categories)}</Card.Meta>
        <StyledHeader as="h3">
          <span dangerouslySetInnerHTML={{ __html: post.title }} />
        </StyledHeader>
        {showExcerpt && <Card.Description dangerouslySetInnerHTML={{ __html: post.excerpt }} />}
        <Card.Meta>
          <span className="date">
            Published {convertDateStringToDate(post.createdDate)} | <strong>{stats.text}</strong>
          </span>
        </Card.Meta>
      </Card.Content>
      {displayTags(post.tags)}
    </StyledCard>
  );
};

BlogCard.propTypes = {
  post: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    featuredImage: PropTypes.string,
    excerpt: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    author: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
    }).isRequired,
    categories: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        slug: PropTypes.string.isRequired,
        color: PropTypes.string,
        text: PropTypes.string,
      })
    ),
    tags: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        slug: PropTypes.string.isRequired,
      })
    ),
    status: PropTypes.string,
    link: PropTypes.string.isRequired,
    createdDate: PropTypes.string.isRequired,
    updatedDate: PropTypes.string.isRequired,
  }).isRequired,
  fluid: PropTypes.bool,
  featured: PropTypes.bool,
  spaced: PropTypes.bool,
  showExcerpt: PropTypes.bool,
  css: PropTypes.string,
};

export default BlogCard;
