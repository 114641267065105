import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Item, List, SemanticICONS } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { SemanticCOLORS } from 'semantic-ui-react/dist/commonjs/generic';

interface IProps {
  content: {
    extra?: string;
    title?: string;
    descriptionTitle: string;
    description?: string;
    lists?: {
      listTitle?: string;
      listIcon?: SemanticICONS;
      listItems: {
        key: string;
        text: string;
        subtext?: string;
        linkTo?: string;
        iconColor?: SemanticCOLORS;
      }[];
    }[];
  };
}

const DetailPane = ({ content }: IProps) => {
  return (
    <Item.Group>
      <Item style={{ marginTop: '1em' }}>
        <Item.Content>
          {content.title && (
            <Item.Header
              style={{
                fontWeight: 'bold',
                fontSize: '2em',
                marginBottom: '.5em',
              }}
            >
              {content.title}
            </Item.Header>
          )}

          <Item.Content>
            {content.extra && <Item.Header as="h3">{content.extra}</Item.Header>}

            <Item.Description style={{ marginTop: '1em', fontWeight: 'bold' }}>
              <p>{content.descriptionTitle.toUpperCase()}</p>
            </Item.Description>
            {content.description && (
              <Item.Meta>
                <p>{content.description}</p>
              </Item.Meta>
            )}
            {content.lists &&
              content.lists.map((list, i) => (
                <Fragment key={i}>
                  <Item.Description style={{ marginTop: '1em', fontWeight: 'bold' }}>
                    {list.listTitle && <p>{list.listTitle.toUpperCase()}</p>}
                  </Item.Description>
                  <List>
                    {list.listItems.map((item, i) => (
                      <List.Item key={i}>
                        <List.Icon name="angle right" />
                        <List.Content style={{ wordBreak: 'break-word' }}>
                          <List.Header
                            content={
                              <Fragment>
                                {item.linkTo ? (
                                  <Link to={item.linkTo}>{item.text}</Link>
                                ) : (
                                  item.text + ' '
                                )}
                                {list.listIcon && (
                                  <List.Icon name={list.listIcon} color={item.iconColor} />
                                )}
                              </Fragment>
                            }
                          />
                          {item.subtext && <Item.Meta>{item.subtext}</Item.Meta>}
                        </List.Content>
                      </List.Item>
                    ))}
                  </List>
                </Fragment>
              ))}
          </Item.Content>
        </Item.Content>
      </Item>
    </Item.Group>
  );
};

DetailPane.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string,
    descriptionTitle: PropTypes.string.isRequired,
    description: PropTypes.string,
    lists: PropTypes.arrayOf(
      PropTypes.shape({
        listTitle: PropTypes.string,
        listIcon: PropTypes.string,
        listItems: PropTypes.arrayOf(
          PropTypes.shape({
            listTitle: PropTypes.string,
            listIcon: PropTypes.string,
            listItems: PropTypes.shape({
              key: PropTypes.string,
              text: PropTypes.string.isRequired,
              subtext: PropTypes.string,
              linkTo: PropTypes.string,
              iconColor: PropTypes.string,
            }),
          })
        ),
      })
    ),
  }),
};

DetailPane.defaultProps = {
  content: {},
};

export default DetailPane;
