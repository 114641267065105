import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { Form, Card, Button, Icon } from 'semantic-ui-react';
import { statusColors, statusIcons } from '../../constants';
import InputDescription from '../../components/InputDescription';
import ChooseResource from '../../components/auth/ChooseResource';
import ChooseResourceScopes from '../../components/auth/ChooseResourceScopes';

export const CLIENT_FORM_ID = 'client-create-form';

class CreateClientForm extends Component {
  constructor(props) {
    super(props);

    const { appDesc, appType } = props;
    this.state = {
      webOrigins: [],
      callbacks: [],
      authClientType: appType.authClientType,
      description: appDesc.length > 140 ? appDesc.substring(0, 140) : appDesc,
      envId: '',
      logoutUrls: [],
      name: '',
      resource: [],
    };
  }

  componentDidUpdate = () => {
    this.scrollToView();
  };

  scrollToView = () => {
    document.querySelector('#bottomOfmodal').scrollIntoView({
      behavior: 'smooth',
    });
  };

  // generate default client name
  generateDefaults = ({ envId, appName, envName }) => {
    return {
      envId,
      name: `${envName} ${appName} client`.replace(/[^a-zA-Z0-9-/\s]/g, ''),
    };
  };

  handleChange = (e, { name, value }) => {
    this.setState({ [name]: value });
  };

  handleEnvChange = (e, { value: envId }) => {
    const { environments, appName } = this.props;
    const { name } = this.state;

    // get defaults for all envs
    const allDefaults = environments.map(env =>
      this.generateDefaults({ envId: env.id, appName, envName: env.name })
    );

    // get defaults for this env
    const envDefaults = allDefaults.find(d => d.envId === envId);

    // if name is empty or the default of another env, update to this env's defaults
    if (name === '' || allDefaults.find(d => d.name === name))
      this.setState({ name: envDefaults.name });

    // reset resource array to prevent mismatched resource/client envs
    this.setState({ envId, resource: [] });
  };

  handleResourceChange = (e, { value }) => {
    const { tenant, resourceId, identifier } = JSON.parse(value);
    const { resource } = this.state;
    const newResource = [...resource];
    const doesRequestExist = newResource.find(r => r.resourceId === resourceId);

    !doesRequestExist &&
      newResource.push({
        resourceId,
        identifier,
        tenant,
        scopes: [],
      });
    this.setState({ resource: newResource });

    this.scrollToView();
  };

  handleScopeChange = (e, data) => {
    const { resourceId, value, description } = data;
    const { resource } = this.state;
    const resourceToUpdateIdx = resource.findIndex(r => r.resourceId === resourceId);
    const resourceToUpdate = resource[resourceToUpdateIdx];
    const updatedResource = { ...resourceToUpdate };
    const updatedScopes = [...updatedResource.scopes];
    const changedScopeIdx = updatedScopes.findIndex(ss => ss.value === value);

    if (changedScopeIdx > -1) {
      updatedScopes.splice(changedScopeIdx, 1);
    } else {
      updatedScopes.push({
        value,
        description,
      });
    }

    const updatedResources = [...resource];
    updatedResource.scopes = updatedScopes;
    updatedResources[resourceToUpdateIdx] = updatedResource;

    // update state
    this.setState({ resource: updatedResources });
  };

  handleResourceRemove = (e, { id }) => {
    e.preventDefault();
    const { resource } = this.state;
    const resourceToRemoveIdx = resource.findIndex(r => r.resourceId === id);
    const updatedResources = [...resource];

    updatedResources.splice(resourceToRemoveIdx, 1);

    this.setState({ resource: updatedResources });
  };

  handleSubmit = e => {
    e.preventDefault();
    const { appType, appId } = this.props;
    const { authClientType } = appType;

    const {
      webOrigins,
      callbacks,
      description,
      envId,
      identifier,
      logoutUrls,
      name,
      resource,
    } = this.state;

    if (authClientType && authClientType === 'spa' && (!callbacks || callbacks.length <= 0)) {
      toast.error(`Error encountered: At least one callback url must be provided.`);
      return;
    }

    const { addClient } = this.props;
    addClient({
      variables: {
        appId,
        webOrigins,
        callbacks,
        authClientType: appType.authClientType,
        description,
        envId,
        identifier,
        logoutUrls,
        name,
        resource,
      },
    });
  };

  handleClear = (e, { name }) => {
    e.preventDefault();
    this.setState({ [name]: '' });
  };

  render() {
    const { appType, appDesc, environments } = this.props;
    const {
      webOrigins,
      callbacks,
      description,
      envId,
      logoutUrls,
      name,
      resource,
      selectedResourceId,
    } = this.state;

    const getItemList = arr =>
      Array.isArray(arr)
        ? arr.map(item => ({
            key: item,
            text: item,
            value: item,
          }))
        : [];

    const callbacksList = getItemList(callbacks);
    const webOriginsList = getItemList(webOrigins);
    const logoutUrlsList = getItemList(logoutUrls);

    return (
      <Form onSubmit={this.handleSubmit} id={CLIENT_FORM_ID}>
        <Form.Dropdown
          id="environment"
          placeholder="Select Environment"
          name="environment"
          label="Environment"
          options={environments.map(env => ({
            text: env.name,
            description: env.isProduction ? 'Production' : 'Non-Production',
            icon: {
              name: 'world',
              color: env.isProduction ? 'green' : 'grey',
            },
            value: env.id,
            content: env.name,
          }))}
          value={envId}
          fluid
          search
          selection
          required
          searchInput={{ autoFocus: true }}
          openOnFocus={false}
          onChange={this.handleEnvChange}
        />
        <InputDescription description="You can register multiple clients in each environment." />
        <Form.Input
          id="name"
          placeholder="Client Name"
          name="name"
          label="Name"
          value={name}
          required
          action={<Button basic size="small" name="name" icon="close" onClick={this.handleClear} />}
          onChange={this.handleChange}
          // allow alphanumeric, dashes, & spaces
          pattern="[-A-Za-z0-9\s]+"
        />
        <InputDescription description="A friendly name for the Client." />
        {!appDesc && (
          <Fragment>
            <Form.Input
              id="description"
              placeholder="Description"
              name="description"
              label="Description"
              value={description}
              action={
                <Button
                  basic
                  size="small"
                  name="description"
                  icon="close"
                  onClick={this.handleClear}
                />
              }
              onChange={this.handleChange}
            />
            <InputDescription description="A free text description of the application. Max character count is 140." />
          </Fragment>
        )}
        {envId && appType.showClientGrants && (
          <Fragment>
            <ChooseResource
              envId={envId}
              resourceId={selectedResourceId}
              onChange={this.handleResourceChange}
            />
            <Card.Group itemsPerRow={3}>
              {!!resource.length &&
                resource.map(r => (
                  <Card key={`resource-${r.resourceId}`}>
                    <Card.Content>
                      <ChooseResourceScopes
                        tenant={r.tenant}
                        resourceId={r.resourceId}
                        selectedScopes={r.scopes}
                        onClick={this.handleScopeChange}
                      />
                    </Card.Content>
                    <Card.Content extra>
                      <Button
                        basic
                        fluid
                        id={r.resourceId}
                        onClick={this.handleResourceRemove}
                        color={statusColors.cancelled}
                      >
                        <Icon name={statusIcons.cancelled} />
                        Remove
                      </Button>
                    </Card.Content>
                  </Card>
                ))}
            </Card.Group>
          </Fragment>
        )}
        <div id="bottomOfmodal" />
        {appType.showCallbacksField && (
          <Fragment>
            <Form.Dropdown
              multiple
              selection
              allowAdditions
              required
              search
              id="callbacks"
              placeholder="http://mysite.com, https://myothersite.com"
              name="callbacks"
              label="Callbacks"
              options={callbacksList}
              value={callbacks}
              onChange={this.handleChange}
            />
            <InputDescription
              description="After the user authenticates we will only call back to any of
              these URLs. You can specify multiple valid URLs (typically to handle different environments
              like QA or testing). Make sure to specify the protocol, http:// or
              https://, otherwise the callback may fail in some cases."
            />
          </Fragment>
        )}
        {appType.showAllowedOriginsField && (
          <Fragment>
            <Form.Dropdown
              multiple
              selection
              allowAdditions
              search
              id="webOrigins"
              placeholder="http://mysite.com, https://myothersite.com"
              name="webOrigins"
              label="Allowed Web Origins"
              options={webOriginsList}
              value={webOrigins}
              onChange={this.handleChange}
            />
            <InputDescription
              description="List of allowed origin URLs for use with Cross-Origin Authentication,
              Device Flow, and web message response mode."
            />
          </Fragment>
        )}
        {appType.showLogoutURLsField && (
          <Fragment>
            <Form.Dropdown
              multiple
              selection
              allowAdditions
              search
              id="logoutUrls"
              placeholder="http://mysite.com, https://myothersite.com"
              name="logoutUrls"
              label="Logout URLs"
              value={logoutUrls}
              options={logoutUrlsList}
              onChange={this.handleChange}
              onAddItem={this.handleAddLogoutUrls}
            />
            <InputDescription
              description="A set of valid URLs to redirect to after logout.
              When a user logs out, you can redirect them with the returnTo
              query parameter. The URL that you use in returnTo must be listed
              here."
            />
          </Fragment>
        )}
      </Form>
    );
  }
}

CreateClientForm.propTypes = {
  appDesc: PropTypes.string,
  addClient: PropTypes.func.isRequired,
  environments: PropTypes.arrayOf(PropTypes.object),
  appType: PropTypes.object,
};

CreateClientForm.defaultProps = {
  appDesc: '',
  environments: [],
  appType: {},
};

CreateClientForm.displayName = 'CreateClientForm';

export default CreateClientForm;
