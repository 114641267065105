import React, { useState, MouseEvent, FC } from 'react';
import { DropdownItemProps, Grid } from 'semantic-ui-react';

import ClientUsagePane from './ClientUsagePane';
import EmptyResults from '../EmptyResults';
import { AllowedRatings } from './UsageStats';
import { IChartData } from './UsageChart';

export interface ITokenCost {
  chartData: IChartData[];
  averageDailyCost: string;
  averageDailyTokens: number;
  estimatedYearlyCost: number;
  isHighUsage: boolean;
  rating: AllowedRatings;
  totalTokens: number;
  highUsageDays: number;
}

export interface IClient {
  clientId: string;
  envId: string;
  name: string;
  tenant: string;
  tokenCost?: ITokenCost;
  app?: {
    id: string;
    name: string;
  };
  isHighUsage?: boolean;
}

interface IProps {
  clients: IClient[];
}

const ClientUsage: FC<IProps> = ({ clients }: IProps) => {
  const [client, setClient] = useState<IClient | undefined | null>();

  const handleButtonClick = (_: MouseEvent<HTMLDivElement>, data: DropdownItemProps): void => {
    const client = clients.find((c: IClient) => c.clientId === data.value);
    setClient(client);
  };

  // use first env with resources as default
  const first = clients[0];

  return (
    <Grid>
      {client ? (
        <ClientUsagePane client={client} clients={clients} handleButtonClick={handleButtonClick} />
      ) : first ? (
        <ClientUsagePane client={first} clients={clients} handleButtonClick={handleButtonClick} />
      ) : (
        <EmptyResults />
      )}
    </Grid>
  );
};

export default ClientUsage;
