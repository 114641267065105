import React from 'react';
import PropTypes from 'prop-types';
import md5 from 'md5';
import { Image, SemanticSIZES } from 'semantic-ui-react';
import { SemanticFLOATS } from 'semantic-ui-react/dist/commonjs/generic';

export function getGravatarUrl(email: string) {
  return `https://gravatar.com/avatar/${md5(email)}.jpg?s=100&d=identicon&r=pg`;
}

interface IProps {
  email: string;
  size: SemanticSIZES;
  bordered: boolean;
  floated: SemanticFLOATS;
  rounded: boolean;
}

const Gravatar = ({ email, size, bordered, floated, rounded }: IProps) => {
  const src = getGravatarUrl(email);
  return size.length > 0 ? (
    <Image avatar size={size} src={src} bordered={bordered} rounded={rounded} floated={floated} />
  ) : (
    <Image avatar src={src} />
  );
};

Gravatar.propTypes = {
  email: PropTypes.string.isRequired,
  size: PropTypes.string,
  bordered: PropTypes.bool,
  floated: PropTypes.string,
  rounded: PropTypes.bool,
};

Gravatar.defaultProps = {
  size: '',
  floated: null,
  bordered: false,
  rounded: false,
};

export default Gravatar;
