import React, { FC, MouseEvent } from 'react';
import { Dropdown, DropdownItemProps } from 'semantic-ui-react';
import { IClient } from './ClientUsage';

interface IProps {
  clients: IClient[];
  selectedClient: IClient;
  handleClick: (event: MouseEvent<HTMLDivElement>, data: DropdownItemProps) => void;
}

const ChooseClientUsageDropdown: FC<IProps> = ({
  clients,
  selectedClient,
  handleClick,
}: IProps) => {
  return (
    <Dropdown
      placeholder="Select Client"
      name="clientId"
      text={selectedClient ? selectedClient.name : 'Select Client'}
      scrolling
      fluid
      className="selection"
      labeled
    >
      <Dropdown.Menu>
        {clients.map(c => {
          return (
            <Dropdown.Item
              key={`item-${c.clientId}`}
              value={c.clientId}
              text={c.name}
              selected={c.name === selectedClient.name ? true : false}
              onClick={handleClick}
              description={c.app?.name}
              label={c.isHighUsage && { color: 'red', empty: true, circular: true }}
            />
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ChooseClientUsageDropdown;
