import React, { useState } from 'react';
import {
  Container,
  Header,
  Grid,
  Button,
  Checkbox,
  Form,
  Search as SemSearch,
} from 'semantic-ui-react';
import SearchResults from '../../components/SearchList';
import { registryIcons } from '../../constants';
import styles from './index.module.css';

const filterInit = {
  apps: false,
  teams: false,
  users: false,
};

const defaultFilters = Object.keys(filterInit);

const Search = props => {
  const [initialized, setInitialized] = useState(false);
  const [filterStates, setFilterStates] = useState(filterInit);

  // get referrer search value
  const referrerValue =
    props.location && props.location.state && !initialized ? props.location.state.searchValue : '';
  const [searchValue, setValue] = useState(referrerValue);

  const handleChange = (e, { value = '' }) => {
    setValue(value);
    setInitialized(true);
  };

  const handleFilterChange = (event, { checked, label }) => {
    setFilterStates({
      ...filterStates,
      [label.toLowerCase()]: checked,
    });
  };

  const getFilters = () => {
    let activeFilters = [];
    Object.keys(filterStates).forEach(filter => {
      if (filterStates[filter.toLowerCase()]) {
        activeFilters.push(filter.toLowerCase());
      }
    });

    if (activeFilters.length < 1) {
      activeFilters = [...defaultFilters];
    }
    return activeFilters;
  };

  const clearFilters = () => {
    setFilterStates({ ...filterInit });
  };

  return (
    <Container fluid>
      <Grid stretched padded textAlign="left">
        <Grid.Row>
          <Grid.Column className={styles.filtersColumn} width={3}>
            <Form>
              <Form.Field>
                <SemSearch
                  open={false}
                  onSearchChange={handleChange}
                  value={searchValue || referrerValue}
                />
              </Form.Field>
              <Header as="h3">Search Filters</Header>
              {Object.keys(registryIcons).map(categoryName => {
                let filterField = null;
                if (defaultFilters.includes(categoryName.toLowerCase())) {
                  filterField = (
                    <Form.Field className={styles.filterField} key={`searchFilter-${categoryName}`}>
                      <Checkbox
                        checked={filterStates[categoryName]}
                        label={categoryName.toUpperCase()}
                        onChange={handleFilterChange}
                      />
                    </Form.Field>
                  );
                }
                return filterField;
              })}
              <Button primary type="submit" onClick={clearFilters}>
                Clear Filters
              </Button>
            </Form>
          </Grid.Column>

          <Grid.Column className={styles.resultsColumn} width={13}>
            <SearchResults
              searchValue={searchValue || 'marvin'}
              filters={getFilters()}
              fields={['name^4', 'description^2']}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  );
};

export default Search;
