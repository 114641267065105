import React, { Component, Fragment } from 'react';
import { Confirm, Button, Icon } from 'semantic-ui-react';
import { Mutation } from 'react-apollo';
import { toast } from 'react-toastify';
import gql from 'graphql-tag';
import getErrorMessages from '../../util/errors';

const DELETE_CLIENT_GRANT = gql`
  mutation AuthResources_DeleteClientGrant_DeleteClientGrant(
    $tenant: String!
    $id: ID!
    $resourceId: ID!
    $clientId: ID!
  ) {
    deleteClientGrant(tenant: $tenant, id: $id, resource_id: $resourceId, client_id: $clientId) {
      success
      error {
        message
        code
      }
    }
  }
`;

const GET_CLIENT_GRANTS_BY_AUDIENCE = gql`
  query AuthResources_DeleteClientGrant_GetClientGrants($tenant: String!, $audience: String!) {
    clientGrants(tenant: $tenant, audience: $audience) {
      id
      audience
      clientId: client_id
      scope
      tenant
      resource {
        resourceId: resource_id
      }
      status
    }
  }
`;

interface IProps {
  id: string;
  clientId: string;
  tenant: string;
  resourceId: string;
  audience: string;
}

interface IState {
  confirmCallback: Function;
  confirmCallbackArgs: any;
  isConfirmVisible: boolean;
}

class DeleteClientGrant extends Component<IProps, IState> {
  state = {
    confirmCallback: (a: any) => a,
    confirmCallbackArgs: null,
    isConfirmVisible: false,
  };

  handleConfirmCancel = () => {
    this.setState({ isConfirmVisible: false });
  };

  handleConfirmConfirm = () => {
    const { confirmCallback, confirmCallbackArgs } = this.state;

    this.setState({ isConfirmVisible: false }, () => {
      confirmCallback(confirmCallbackArgs);
    });
  };

  showConfirm = ({
    confirmCallback,
    confirmCallbackArgs,
  }: {
    confirmCallback: Function;
    confirmCallbackArgs: any;
  }) => {
    this.setState({
      isConfirmVisible: true,
      confirmCallback,
      confirmCallbackArgs,
    });
  };

  render() {
    const { isConfirmVisible } = this.state;
    const { id, clientId, tenant, resourceId, audience } = this.props;

    return (
      <Fragment>
        <Confirm
          open={isConfirmVisible}
          content="Removing this client grant will prevent this client from accessing your resource. Are you sure you want to continue?"
          onCancel={this.handleConfirmCancel}
          onConfirm={this.handleConfirmConfirm}
        />
        <Mutation
          key={id}
          mutation={DELETE_CLIENT_GRANT}
          refetchQueries={[
            {
              query: GET_CLIENT_GRANTS_BY_AUDIENCE,
              variables: {
                audience,
                tenant,
              },
            },
          ]}
          onCompleted={() => toast.success('Successfully deleted client grant.')}
          onError={(error: Error) => toast.error(`Error encountered: ${getErrorMessages(error)}`)}
        >
          {(deleteClientGrant: Function) => (
            <Button
              color="red"
              onClick={() =>
                this.showConfirm({
                  confirmCallback: deleteClientGrant,
                  confirmCallbackArgs: {
                    variables: {
                      id,
                      resourceId,
                      tenant,
                      clientId,
                    },
                  },
                })
              }
            >
              <Icon link name="trash" />
              Delete
            </Button>
          )}
        </Mutation>
      </Fragment>
    );
  }
}

export default DeleteClientGrant;
