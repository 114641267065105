/* eslint @typescript-eslint/no-unused-vars: "warn" */
import React, { Component, SyntheticEvent } from 'react';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';
import {
  Button,
  Card,
  Checkbox,
  Divider,
  Icon,
  Input,
  List,
  Message,
  Segment,
  Tab,
} from 'semantic-ui-react';
import { Link } from 'react-router-dom';

import gql from 'graphql-tag';
import Error from '../Error';
import getErrorMessages from '../../util/errors';
import Gravatar from '../Gravatar';

const GET_AUTH_ROLE_USERS = gql`
  query ChooseResourcePermissions_GetAuthRoleUsers($tenant: String!, $roleId: ID!) {
    authRoleUsers(tenant: $tenant, role_id: $roleId) {
      email
      name
      user_id
    }
  }
`;

const GET_RESOURCE = gql`
  query ChooseResourcePermissions_GetResource($resourceId: ID!, $tenant: String!) {
    resource(resource_id: $resourceId, tenant: $tenant) {
      resourceId: resource_id
      name
      identifier
      envId: environment_id
      client {
        clientId: client_id
        name
      }
      scopes {
        value
        description
      }
      app {
        id
        name
      }
      allowUsers
    }
  }
`;

interface IProps {
  disabled: boolean;
  onPermissionChange: Function;
  onUserChange: Function;
  onRemove: Function;
  resourceId: string;
  roleId: string;
  selectedPermissions: any[];
  showRemove: boolean;
  tenant: string;
  users: any[];
}

interface IState {
  newUsers: any[];
  newEmails: string;
  removedUsers: any[];
  userError: boolean;
}

class ChooseResourcePermissions extends Component<IProps, IState> {
  state = {
    newUsers: [],
    newEmails: '',
    removedUsers: [],
    userError: false,
  };

  getSnapshotBeforeUpdate(prevProps: IProps) {
    // handle edit cancellation
    if (prevProps.disabled !== this.props.disabled) {
      this.setState({
        removedUsers: [],
        newEmails: '',
        newUsers: [],
        userError: false,
      });
    }
    return null;
  }

  componentDidUpdate(prevProps: IProps) {
    return null;
  }

  handleUserChange = (e: SyntheticEvent, { value }: { value: string }) => {
    this.setState({ newEmails: value });
  };

  handleAddUsers = (handleUserUpdate = (u: any[]) => {}) => {
    const { newUsers, newEmails, removedUsers } = this.state;
    const parsedUsers: any[] = newEmails
      .replace(/\s/g, '')
      .split(',')
      .reduce((acc, email) => {
        if (email) {
          acc.push({ email });
        }
        return acc;
      }, [] as any[]);
    const isValid = !parsedUsers.length || this.validateUserEmails(parsedUsers);

    if (!isValid) {
      this.setState({ userError: true });
      return;
    }

    this.setState({
      newUsers: [...parsedUsers, ...newUsers],
      newEmails: '',
      removedUsers: removedUsers.filter(e => !removedUsers.includes(e)),
      userError: false,
    });

    typeof handleUserUpdate === 'function' && handleUserUpdate([...parsedUsers, ...newUsers]);
  };

  handleRemoveUser = (
    email: string,
    currentUsers: any[],
    handleUserRemove = (u: string[]) => {}
  ) => {
    const { newUsers, removedUsers } = this.state;
    const isCurrentUser = !!currentUsers.find(({ email: e }) => e === email);
    const allRemovedUsers = [...removedUsers, email];

    if (isCurrentUser) {
      handleUserRemove(allRemovedUsers);
    } else {
      const updatedUsers = newUsers.filter(({ email: e }) => e !== email);
      handleUserRemove(updatedUsers);
    }
    this.setState({ removedUsers: allRemovedUsers });
  };

  handleRemove = () => {
    const { onRemove, resourceId } = this.props;
    onRemove(resourceId);
  };

  validateUserEmails(users: any[]) {
    return users.every(({ email }) => {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    });
  }

  render() {
    const {
      disabled,
      onPermissionChange,
      onUserChange,
      resourceId,
      roleId,
      selectedPermissions,
      showRemove,
      tenant,
      users,
    } = this.props;

    const { newEmails, userError } = this.state;

    if (!resourceId) return null;

    return (
      <Query<any, any> query={GET_RESOURCE} variables={{ resourceId, tenant }}>
        {({ loading, error, data = {} }) => {
          if (loading) return <p>Loading...</p>;
          if (error) return <Error errors={getErrorMessages(error)} />;

          const {
            resource: { name, resourceId, scopes: permissions = [], app },
          } = data;

          const handleCheckboxClick = (e: SyntheticEvent, d: any) => {
            onPermissionChange(e, { ...d, resourceId });
          };

          return (
            <>
              <Card.Header>
                <Link to={`/app/${app.id}`}>{app.name}</Link>
              </Card.Header>
              <Card.Meta>{name}</Card.Meta>
              {showRemove && <Icon name="cancel" onClick={this.handleRemove} />}
              <Divider hidden />
              <Tab
                panes={[
                  {
                    menuItem: 'Permissions',
                    render: () => (
                      <Tab.Pane>
                        <Card.Content>
                          <List id="choose-permissions" style={{ paddingLeft: 0 }}>
                            {permissions.length === 0 ? (
                              <i>None</i>
                            ) : (
                              permissions.map(
                                ({
                                  value,
                                  description,
                                }: {
                                  value: string;
                                  description: string;
                                }) => (
                                  <List.Item key={`scope-${value}`}>
                                    <Checkbox
                                      onClick={handleCheckboxClick}
                                      label={value}
                                      value={value}
                                      name={value}
                                      description={description}
                                      checked={selectedPermissions.some(s => s.value === value)}
                                      disabled={disabled}
                                    />
                                  </List.Item>
                                )
                              )
                            )}
                          </List>
                        </Card.Content>
                      </Tab.Pane>
                    ),
                  },
                  {
                    menuItem: 'Users',
                    render: () => (
                      <Query<any, any> query={GET_AUTH_ROLE_USERS} variables={{ roleId, tenant }}>
                        {({ loading, error, data = {} }) => {
                          if (error) return <Error error={error} />;

                          const { authRoleUsers = [] } = data;
                          const { newUsers, removedUsers } = this.state;
                          const allUsers = [...newUsers, ...authRoleUsers].filter(
                            ({ email }) => !(removedUsers as any[]).includes(email)
                          );

                          const handleUserUpdate = (newUsers: any[]) => {
                            onUserChange([...authRoleUsers, ...newUsers]);
                          };
                          const handleUserRemove = (removedUsers: any[]) => {
                            onUserChange(allUsers.filter(au => !removedUsers.includes(au.email)));
                          };

                          return (
                            <Tab.Pane loading={loading}>
                              <Card.Content>
                                {disabled ? (
                                  <Segment color="red" textAlign="center">
                                    Click Edit to Modify Users
                                  </Segment>
                                ) : (
                                  <div style={{ paddingBottom: 10 }}>
                                    <Input
                                      action={
                                        <Button
                                          onClick={() => this.handleAddUsers(handleUserUpdate)}
                                        >
                                          <Icon fitted name="add" />
                                        </Button>
                                      }
                                      fluid
                                      id="team-members"
                                      name="users"
                                      placeholder="User Names"
                                      value={newEmails}
                                      onChange={this.handleUserChange}
                                    />
                                  </div>
                                )}

                                {userError && (
                                  <Message negative>
                                    <Icon name="exclamation triangle" />
                                    Invalid email found
                                  </Message>
                                )}

                                <List
                                  divided
                                  verticalAlign="middle"
                                  style={{
                                    paddingLeft: 0,
                                    overflow: 'auto',
                                    maxHeight: 200,
                                  }}
                                >
                                  {permissions.length === 0 ? (
                                    <i>None</i>
                                  ) : (
                                    (users || allUsers).map(({ user_id, email }) => (
                                      <List.Item key={`scope-${email}`}>
                                        <List.Content floated="right" verticalAlign="middle">
                                          {!disabled && (
                                            <Icon
                                              name="delete"
                                              onClick={() => {
                                                this.handleRemoveUser(
                                                  email,
                                                  authRoleUsers,
                                                  handleUserRemove
                                                );
                                              }}
                                            />
                                          )}
                                        </List.Content>
                                        <Gravatar email={email} size="mini" /> {email}
                                      </List.Item>
                                    ))
                                  )}
                                </List>
                              </Card.Content>
                            </Tab.Pane>
                          );
                        }}
                      </Query>
                    ),
                  },
                ]}
              />
            </>
          );
        }}
      </Query>
    );
  }

  static propTypes = {
    disabled: PropTypes.bool,
    onPermissionChange: PropTypes.func.isRequired,
    onUserChange: PropTypes.func,
    onRemove: PropTypes.func,
    resourceId: PropTypes.string.isRequired,
    roleId: PropTypes.string,
    selectedPermissions: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string.isRequired,
      })
    ),
    showRemove: PropTypes.bool,
    tenant: PropTypes.string.isRequired,
    users: PropTypes.array,
  };

  static defaultProps = {
    disabled: false,
    onRemove: () => {},
    onUserChange: () => {},
    roleId: '',
    selectedPermissions: [],
    showRemove: true,
    users: null,
  };
}

export default ChooseResourcePermissions;
