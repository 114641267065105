import React, { Fragment, FC, ReactElement } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Button, Icon, Transition, Popup } from 'semantic-ui-react';
import { useMutation } from 'react-apollo';

import getErrorMessages from '../../util/errors';
import gql from 'graphql-tag';

import ModalState from '../../layouts/ModalState';
import ModalForm from '../../layouts/ModalForm';
import AppForm, { APP_FORM_ID } from './AppForm';
import SearchParam from '../../components/SearchParam';
import { toast } from 'react-toastify';

const APP_BASICS = gql`
  fragment AppBasics on App {
    id
    name
    description
    appType {
      id
      name
      description
    }
    team {
      id
      name
    }
    environments {
      id
      name
      url
      isProduction
    }
    members {
      id
    }
  }
`;

const CREATE_APP = gql`
  mutation CreateApp_CreateApp(
    $name: String!
    $description: String!
    $repoUrl: String!
    $teamId: ID!
    $members: [String!]!
    $appTypeId: ID!
  ) {
    addApp(
      app: {
        name: $name
        description: $description
        repoUrl: $repoUrl
        teamId: $teamId
        members: $members
        appTypeId: $appTypeId
      }
    ) {
      success
      error {
        message
        code
      }
      app {
        ...AppBasics
      }
    }
  }
  ${APP_BASICS}
`;

const GET_ALL_APPS = gql`
  query CreateApp_GetApps {
    apps {
      ...AppBasics
    }
  }
  ${APP_BASICS}
`;

const GET_USER_APPS = gql`
  query CreateApp_GetUser($id: ID!) {
    user(id: $id) {
      apps {
        id
        name
        appType {
          id
          name
        }
      }
    }
  }
`;

interface IProps extends RouteComponentProps {
  me?: {
    id: string;
  };
  defaultTeam?: ITeam;
}

export interface ITeam {
  id: string;
  name: string;
}

interface IMember {
  id: string;
}

interface IEnvironment {
  id: string;
  name: string;
  url: string;
  isProduction: boolean;
}

interface IApp {
  id: string;
  name: string;
  description: string;
  appType: {
    id: string;
    name: string;
    description: string;
  };
  environments: IEnvironment[];
  members: IMember[];
  team: ITeam;
}

interface IAddAppResponse {
  addApp: {
    app: IApp;
    error: string;
    success: boolean;
  };
}

const CreateApp: FC<IProps> = ({ me, defaultTeam, history }: IProps): ReactElement => {
  const [addApp, { error, loading, client }] = useMutation(CREATE_APP, {
    update: (_: any, { data: { addApp } }: any) => {
      const cachedApps = client?.readQuery({ query: GET_ALL_APPS });

      /*
      for subscription purposes, it's best to set up a brand new object
      here rather than adding to the array of an existing one
      */
      const newAppsArr = {
        apps: [addApp.app, ...cachedApps.apps],
      };

      client?.writeQuery({
        query: GET_ALL_APPS,
        data: newAppsArr,
      });
    },
    onCompleted: (data: IAddAppResponse) => {
      // ran after the mutation is complete and the cache is updated ^^
      history.push(`/app/${data.addApp.app.id}`); // redirect to new app
    },
    onError: (error: Error) => toast.error(`Error encountered: ${getErrorMessages(error)}`),
    refetchQueries: [
      {
        query: GET_USER_APPS,
        variables: {
          id: me?.id,
        },
      },
    ],
  });

  const errors = getErrorMessages(error);

  return (
    <SearchParam param="create" defaultValue="">
      {createParam => (
        <ModalState>
          {(modalOpen: boolean, toggleModal: () => void) => (
            <Fragment>
              {defaultTeam ? (
                <Transition
                  animation="jiggle"
                  duration="1500"
                  transitionOnMount={+createParam === 1}
                >
                  <Button className="register" id="registerAppBtn" primary onClick={toggleModal}>
                    <Icon name="add" />
                    {`Register an App`}
                  </Button>
                </Transition>
              ) : (
                <Popup
                  flowing
                  trigger={
                    <span>
                      <Button primary disabled>
                        <Icon name="add" />
                        {`Register an App`}
                      </Button>
                    </span>
                  }
                  content="You must register or join your team before registering an app."
                />
              )}

              {modalOpen && (
                <ModalForm
                  header={`Register App`}
                  actionAttr={{
                    positive: true,
                    type: 'submit',
                    form: APP_FORM_ID,
                  }}
                  loading={loading}
                  open={modalOpen}
                  toggleModal={toggleModal}
                  errors={errors}
                  actionText={`Register App`}
                >
                  <AppForm
                    onSubmit={addApp}
                    me={me}
                    loading={loading}
                    defaultTeam={defaultTeam}
                    errors={errors}
                  />
                </ModalForm>
              )}
            </Fragment>
          )}
        </ModalState>
      )}
    </SearchParam>
  );
};

export default withRouter(CreateApp);
