import React from 'react';
import { Placeholder, Header, Container } from 'semantic-ui-react';

const ContainerLoader = () => (
  <Container fluid>
    <Header as="h2">
      <Placeholder>
        <Placeholder.Header image>
          <Placeholder.Line />
          <Placeholder.Line />
        </Placeholder.Header>
      </Placeholder>
    </Header>
    <Placeholder>
      <Placeholder.Line length="very long" />
      <Placeholder.Line length="long" />
    </Placeholder>
  </Container>
);

export default ContainerLoader;
