import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';
import {
  Divider,
  Icon,
  Label,
  Message,
  Segment,
  Checkbox,
  Header,
  Confirm,
  Popup,
} from 'semantic-ui-react';
import { toast } from 'react-toastify';
import _ from 'lodash';

import gql from 'graphql-tag';
import ModalState from '../../layouts/ModalState';
import ModalForm from '../../layouts/ModalForm';
import { clipboardCopy } from '../../util';
import getErrorMessages from '../../util/errors';

const ROTATE_SECRET = gql`
  mutation RotateSecret_RotateClientSecret($tenant: String!, $clientId: ID!) {
    rotateClientSecret(tenant: $tenant, client_id: $clientId) {
      success
      error {
        message
        code
      }
      client {
        clientSecret: client_secret
      }
    }
  }
`;

class RotateSecret extends Component {
  constructor(props) {
    super(props);

    this.initialState = {
      clientSecret: 'ERROR',
      confirmed: false,
      actionDisabled: true,
      checked: false,
      closeOnEscape: true,
      isConfirmVisible: true,
    };

    this.state = { ...this.initialState };
  }

  handleChecked = () => {
    if (!this.state.checked) {
      this.setState({
        actionDisabled: false,
        checked: true,
        closeOnEscape: true,
      });
    } else
      this.setState({
        actionDisabled: true,
        checked: false,
        closeOnEscape: false,
      });
  };
  handleRotate = data => {
    const clientSecret = _.get(data, 'rotateClientSecret.client.clientSecret');
    this.setState({ clientSecret, confirmed: true });
  };

  resetStateAndClose = toggleModal => () => {
    this.setState({ ...this.initialState });
    toggleModal();
  };

  handleConfirmCancel = () => {
    this.setState({ isConfirmVisible: false });
  };

  render() {
    const { clientId, tenant } = this.props;
    const {
      clientSecret,
      confirmed,
      actionDisabled,
      closeOnEscape,
      checked,
      isConfirmVisible,
    } = this.state;

    return (
      <ModalState>
        {(modalOpen, toggleModal) => (
          <Fragment>
            <Popup
              content="Lost your client secret? Click here to get a new one!"
              inverted
              trigger={
                <Icon
                  name="refresh"
                  id="Rotate Secret"
                  style={{ cursor: 'pointer' }}
                  onClick={toggleModal}
                />
              }
            />

            {modalOpen && (
              <Mutation
                mutation={ROTATE_SECRET}
                variables={{ tenant, clientId }}
                onCompleted={d => {
                  this.handleRotate(d);
                  toast.success('Successfully rotated client secret!');
                }}
                onError={error =>
                  toast.error(`Error encountered: ${getErrorMessages(error)}`, {
                    autoClose: false,
                  })
                }
              >
                {(rotateClientSecret, { loading, error }) => {
                  const errors = getErrorMessages(error);

                  return confirmed ? (
                    <ModalForm
                      actionAttr={{
                        onClick: this.resetStateAndClose(toggleModal),
                        positive: true,
                      }}
                      actionDisabled={actionDisabled}
                      id="rotate-secret"
                      actionText="Agree"
                      cancel={false}
                      errors={errors}
                      header={
                        <Header as="h3">
                          <Icon name="exclamation circle" color="red" size="large" />
                          Wait, before you close this modal!
                        </Header>
                      }
                      loading={loading}
                      open={modalOpen}
                      toggleModal={toggleModal}
                      closeOnEscape={closeOnEscape}
                    >
                      <Fragment>
                        <Message warning>
                          <Message.Content>
                            <Header as="h3">
                              <Icon name="exclamation circle" color="red" size="large" />
                              Client Secrets will be lost forever if not saved now.
                            </Header>
                            <Divider />
                            <Header as="p">
                              Below is your client secret. Please copy it and keep it secure. This
                              is the only time you will see this information. Secrets (including but
                              not limited to: API keys, username/password credentials, auth tokens,
                              certificates, and private keys) are not permitted to be stored in code
                              repositories, persisted in plaintext, or transmitted programmatically
                              or manually over insecure channels.
                            </Header>
                          </Message.Content>
                          <Divider />
                          <Message.Content id="consentCheckBox">
                            <Checkbox
                              label="I have read and agree to the terms and conditions of the above statement."
                              onChange={this.handleChecked}
                              checked={checked}
                            />
                          </Message.Content>
                        </Message>

                        <Divider />
                        <Segment basic textAlign="center">
                          <Label horizontal size="big">
                            <Label.Detail>{clientSecret}</Label.Detail>
                            <Label.Detail id="copyClientId">
                              <Icon
                                link
                                name="clone outline"
                                onClick={() => clipboardCopy(clientSecret)}
                              />
                            </Label.Detail>
                          </Label>
                        </Segment>
                      </Fragment>
                    </ModalForm>
                  ) : isConfirmVisible ? (
                    <Confirm
                      open={!confirmed}
                      header="Rotate Secret"
                      cancelButton="Keep the current secret"
                      confirmButton="Get a new secret"
                      content="Are you sure you want to rotate the secret for this client? The current secret will no longer be valid."
                      onCancel={this.resetStateAndClose(toggleModal)}
                      onConfirm={rotateClientSecret}
                    />
                  ) : null;
                }}
              </Mutation>
            )}
          </Fragment>
        )}
      </ModalState>
    );
  }
}

RotateSecret.propTypes = {
  clientId: PropTypes.string.isRequired,
  tenant: PropTypes.string.isRequired,
};

RotateSecret.displayName = 'RotateSecret';

export default RotateSecret;
