import React, { useState, MouseEvent, FC } from 'react';
import { useQuery } from 'react-apollo';

import CreateResource from '../../scenes/auth-resources/CreateResource';
import gql from 'graphql-tag';
import PageLoader from '../PageLoader';
import Error from '../Error';
import { DropdownItemProps, Grid } from 'semantic-ui-react';
import EnvResourcePane from './EnvResourcePane';
import EmptyResults from '../EmptyResults';

const GET_ENV_RESOURCES = gql`
  query EnvResources_GetEnvResources($envs: [ID]!) {
    envResources(envs: $envs) {
      resourceId: resource_id
      allowUsers
      name
      identifier
      tenant
      envId: environment_id
      app {
        id
        name
      }
      scopes {
        value
        description
      }
    }
  }
`;

export interface IEnvResource {
  resourceId: string;
  allowUsers: boolean;
  name: string;
  identifier: string;
  tenant: string;
  envId: string;
  scopes: {
    value: string;
    description: string;
  }[];
  client: {
    clientId: string;
    name: string;
    app: {
      id: string;
      name: string;
    };
  }[];
}

interface IProps {
  appId: string;
  appName: string;
  environments: {
    id: string;
    name: string;
    tenant: string;
    isProduction: boolean;
    resources?: IEnvResource[];
  }[];
  meIsOwner: boolean;
}

const EnvResources: FC<IProps> = ({ appId, appName, environments, meIsOwner }: IProps) => {
  const [envId, setEnvId] = useState<string | undefined>();
  const [resource, setResource] = useState<IEnvResource | undefined | null>();
  const envIds = environments.map(e => e.id);

  // if envId is set, find env info
  const env = envId && environments.find(env => env.id === envId);

  // get resources for this env
  const { loading, error, data } = useQuery(GET_ENV_RESOURCES, {
    variables: { envs: envIds },
  });

  if (loading) return <PageLoader />;
  if (error) return <Error error={error} />;
  const envResources = data.envResources as IEnvResource[];

  // map resources for each env
  const envs = environments.map(e => {
    return {
      ...e,
      resources: envResources.filter((r: { envId: string }) => r.envId === e.id),
    };
  });

  const handleButtonClick = (_: MouseEvent<HTMLDivElement>, data: DropdownItemProps): void => {
    const resource = envResources.find((r: IEnvResource) => r.resourceId === data.value);
    setEnvId(data.env);
    setResource(resource as IEnvResource);
  };

  // use first env with resources as default
  const first = envs.find(e => e.resources.length > 0);
  return (
    <Grid>
      {meIsOwner && (
        <Grid.Row>
          <Grid.Column>
            <CreateResource
              appId={appId}
              appName={appName}
              environments={environments}
              setCurrentEnvId={setEnvId}
              onResourceCreate={setResource}
            />
          </Grid.Column>
        </Grid.Row>
      )}
      {env && resource ? (
        <EnvResourcePane
          env={env}
          envs={envs}
          resource={resource}
          meIsOwner={meIsOwner}
          onResourceUpdate={setResource}
          handleButtonClick={handleButtonClick}
        />
      ) : first ? (
        <EnvResourcePane
          env={first}
          envs={envs}
          resource={first.resources[0]}
          meIsOwner={meIsOwner}
          onResourceUpdate={setResource}
          handleButtonClick={handleButtonClick}
        />
      ) : (
        <EmptyResults />
      )}
    </Grid>
  );
};

export default EnvResources;
