import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Button, Dimmer, Icon, Loader, Message } from 'semantic-ui-react';
import { Mutation } from 'react-apollo';
import { toast } from 'react-toastify';
import gql from 'graphql-tag';
import ModalState from '../../layouts/ModalState';
import ModalForm from '../../layouts/ModalForm';
import getErrorMessages from '../../util/errors';

const DELETE_CLIENT = gql`
  mutation DeleteClient_DeleteClient($clientId: ID!, $tenant: String!) {
    deleteClient(client_id: $clientId, tenant: $tenant) {
      error {
        message
        code
      }
      success
    }
  }
`;

const GET_ENV_CLIENTS = gql`
  query DeleteClient_GetEnvClients($envs: [ID]!) {
    envClients(envs: $envs) {
      webOrigins: web_origins
      callbacks
      clientId: client_id
      description
      envId: environment_id
      logoutUrls: allowed_logout_urls
      name
      tenant
    }
  }
`;

class DeleteClient extends Component {
  handleDeleteClient = (deleteClient, clientId, tenant) => {
    deleteClient({
      variables: {
        clientId,
        tenant,
      },
    });
  };

  render() {
    const { environments, name, clientId, tenant } = this.props;
    const envs = environments.map(e => e.id);

    return (
      <ModalState>
        {(modalOpen, toggleModal) => (
          <Fragment>
            <Button className="delete-client" icon color="red" size="tiny" onClick={toggleModal}>
              <Icon name="trash" />
              Delete
            </Button>
            {modalOpen && (
              <Mutation
                mutation={DELETE_CLIENT}
                refetchQueries={[
                  {
                    query: GET_ENV_CLIENTS,
                    variables: { envs },
                  },
                ]}
                awaitRefetchQueries
                onCompleted={() => {
                  toggleModal();
                  toast.success('Successfully deleted client!');
                }}
                onError={error =>
                  toast.error(`Error encountered: ${getErrorMessages(error)}`, {
                    autoClose: false,
                  })
                }
              >
                {(deleteClient, { loading, error }) => {
                  const errors = getErrorMessages(error);

                  return (
                    <ModalForm
                      header={`Delete Client: ${name}`}
                      actionAttr={{
                        negative: true,
                        onClick: () => this.handleDeleteClient(deleteClient, clientId, tenant),
                      }}
                      actionText="Delete Client"
                      errors={errors}
                      loading={loading}
                      open={modalOpen}
                      toggleModal={toggleModal}
                    >
                      <Dimmer.Dimmable>
                        {loading && (
                          <Dimmer active inverted>
                            <Loader inverted />
                          </Dimmer>
                        )}
                        <Message negative>
                          <Message.Header>You cannot undo this step.</Message.Header>
                        </Message>
                        Are you sure you want to delete the <strong>{name}</strong> client?{' '}
                      </Dimmer.Dimmable>
                    </ModalForm>
                  );
                }}
              </Mutation>
            )}
          </Fragment>
        )}
      </ModalState>
    );
  }
}

DeleteClient.propTypes = {
  environments: PropTypes.arrayOf(PropTypes.object),
  name: PropTypes.string.isRequired,
  clientId: PropTypes.string.isRequired,
};

DeleteClient.defaultProps = {
  environments: [],
};

DeleteClient.displayName = 'DeleteClient';

export default DeleteClient;
