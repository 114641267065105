import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';

export const CREATE_ENV_FORM_ID = 'create-env-form';

class CreateEnvForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      description: '',
      url: '',
      isProduction: false,
    };
  }

  handleNameChange = (e, { value }) => this.setState({ name: value });
  handleUrlChange = (e, { value }) => this.setState({ url: value });

  handleIsProductionChange = () => {
    const { isProduction } = this.state;
    this.setState({ isProduction: !isProduction });
  };

  handleSubmit = e => {
    e.preventDefault();
    const { name, description, url, isProduction } = this.state;
    const { id, addEnvironment } = this.props;
    addEnvironment({
      variables: {
        appId: id,
        name,
        description,
        url,
        isProduction,
      },
    });
  };

  render() {
    const { loading, errors } = this.props;
    const { name, url, isProduction } = this.state;

    return (
      <Form
        onSubmit={this.handleSubmit}
        id={CREATE_ENV_FORM_ID}
        loading={loading}
        error={errors.length > 0}
      >
        <Form.Field>
          <Form.Input
            id="name"
            label="Environment Name"
            required
            autoFocus
            value={name}
            onChange={this.handleNameChange}
          />
          <Form.Input
            id="url"
            label="Environment URL"
            placeholder="https://"
            value={url}
            onChange={this.handleUrlChange}
          />
          <Form.Radio
            id="isProduction"
            toggle
            label="Production Environment"
            onClick={this.handleIsProductionChange}
            checked={isProduction === true}
          />
        </Form.Field>
      </Form>
    );
  }
}

CreateEnvForm.propTypes = {
  id: PropTypes.string.isRequired,
  addEnvironment: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  errors: PropTypes.array,
};
CreateEnvForm.defaultProps = {
  errors: [],
};

export default CreateEnvForm;
