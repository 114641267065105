import React, { Fragment, FC, MouseEvent } from 'react';
import { Dropdown, Icon, DropdownItemProps } from 'semantic-ui-react';

interface IEnvClient {
  clientId: string;
  allowUsers: boolean;
  name: string;
  identifier: string;
  tenant: string;
  envId: string;
  scopes: {
    value: string;
    description: string;
  }[];
  client: {
    clientId: string;
    name: string;
    app: {
      id: string;
      name: string;
    };
  }[];
}

interface IProps {
  envs: {
    id: string;
    name: string;
    isProduction: boolean;
    tenant: string;
    clients: IEnvClient[];
  }[];
  selectedClientName: string;
  handleClick: (event: MouseEvent<HTMLDivElement>, data: DropdownItemProps) => void;
}

const ChooseClientDropdown: FC<IProps> = ({ envs, selectedClientName, handleClick }: IProps) => {
  return (
    <Dropdown
      placeholder="Select Client"
      name="clientId"
      text={selectedClientName !== '' ? selectedClientName : 'Select Client'}
      scrolling
      fluid
      className="selection"
    >
      <Dropdown.Menu>
        {envs
          .filter(e => e && e.clients.length)
          .map(e => {
            const items = e.clients
              .filter(r => r.envId === e.id)
              .map(r => {
                return (
                  <Dropdown.Item
                    key={`item-${r.clientId}`}
                    value={r.clientId}
                    text={r.name}
                    env={e.id}
                    selected={r.name === selectedClientName ? true : false}
                    onClick={handleClick}
                  />
                );
              });
            return (
              <Fragment key={e.id}>
                <Dropdown.Header
                  content={
                    <Fragment>
                      <Icon name="world" color={e.isProduction ? 'green' : 'grey'} />
                      {e.name}
                    </Fragment>
                  }
                />
                {items}
              </Fragment>
            );
          })}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ChooseClientDropdown;
