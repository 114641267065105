import React, { Component, ChangeEvent } from 'react';
import PropTypes from 'prop-types';
import { Segment, Header, Input, Icon, Grid, Item } from 'semantic-ui-react';
import UserListItem from './UserListItem';

interface IProps {
  team: {
    members: any[];
  };
}

class UserList extends Component<IProps> {
  state = {
    filterValue: '',
    filteredData: this.props.team.members,
  };

  render() {
    const { filteredData, filterValue } = this.state;

    const handleFilterChange = (e: ChangeEvent, { value }: { value: string }) => {
      const { team } = this.props;
      const term = value.toLowerCase();

      this.setState({
        filteredData: team.members.filter(
          user =>
            user.firstName.toLowerCase().indexOf(term) > -1 ||
            user.lastName.toLowerCase().indexOf(term) > -1 ||
            user.email.toLowerCase().indexOf(term) > -1
        ),
        filterValue: term,
      });
    };

    const clearFilter = () => {
      this.setState({ filterValue: '', filteredData: this.props.team.members });
    };

    return (
      <div>
        <Segment secondary attached="top">
          <Grid>
            <Grid.Column width={10}>
              <Header>Members</Header>
            </Grid.Column>
            <Grid.Column width={6}>
              <Input
                size="small"
                fluid
                icon={
                  filterValue === '' ? (
                    <Icon name="search" color="green" />
                  ) : (
                    <Icon name="close" color="green" link onClick={clearFilter} />
                  )
                }
                placeholder="Filter"
                value={filterValue}
                onChange={handleFilterChange}
              />
            </Grid.Column>
          </Grid>
        </Segment>
        <Segment attached="bottom">
          <Item.Group>
            <UserListItem items={filteredData} />
          </Item.Group>
        </Segment>
      </div>
    );
  }

  static propTypes = {
    team: PropTypes.object,
  };

  static defaultProps = {
    team: {},
  };
}

export default UserList;
