import React, { Fragment, FC } from 'react';
import { Checkbox, Icon, Label, List, Popup, Grid, CommentText } from 'semantic-ui-react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useQuery } from 'react-apollo';

import { clipboardCopy } from '../../util';
import Error from '../Error';
import PageLoader from '../PageLoader';
import gql from 'graphql-tag';
import ClientGrantsGrid from './ClientGrantsGrid';
import AuthRoleGrid from './AuthRoleGrid';

import { IEnvResource } from './EnvResources';

const GET_AUTH_ROLES_BY_RESOURCE_ID = gql`
  query ResourceDetail_GetAuthRolesByResourceId($tenant: String!, $resourceId: ID!) {
    authRolesByResourceId(tenant: $tenant, resource_id: $resourceId) {
      role_id
      name
      description
      permissions {
        description
        name: permission_name
      }
      status
      users {
        user_id
        email
      }
      resource_id
    }
  }
`;

const GET_CLIENT_GRANTS_BY_AUDIENCE = gql`
  query ResourceDetail_GetClientGrants($tenant: String!, $audience: String!) {
    clientGrants(tenant: $tenant, audience: $audience) {
      id
      audience
      clientId: client_id
      scope
      tenant
      resource {
        resourceId: resource_id
      }
      status
    }
  }
`;

interface IProps {
  env: { id: string; name: string; tenant: string; isProduction: boolean };
  resourceId: string;
  resource: IEnvResource;
}

const ResourceDetail: FC<IProps> = ({ env, resourceId, resource }) => {
  const { allowUsers: allowUsersFlag } = useFlags();

  // get roles for this resource
  const { loading: rolesLoading, error: rolesError, data: rolesData } = useQuery(
    GET_AUTH_ROLES_BY_RESOURCE_ID,
    {
      variables: { tenant: resource.tenant, resourceId },
    }
  );

  // get client grants for this resource
  const { loading: clientGrantLoading, error: clientGrantError, data: clientGrantData } = useQuery(
    GET_CLIENT_GRANTS_BY_AUDIENCE,
    {
      variables: { tenant: resource.tenant, audience: resource.identifier },
    }
  );

  if (clientGrantLoading || rolesLoading) {
    return (
      <Grid.Row>
        <PageLoader inline />
      </Grid.Row>
    );
  }

  if (clientGrantError || rolesError) return <Error error={clientGrantError || rolesError} />;

  const { authRolesByResourceId } = rolesData;
  const { clientGrants } = clientGrantData;
  const { tenant, identifier, scopes } = resource;

  return (
    env && (
      <Fragment key={`pane-${resourceId}`}>
        <Grid celled>
          <Grid.Row style={{ height: '150px' }}>
            {allowUsersFlag && (
              <Grid.Column width={6}>
                <List relaxed="very">
                  <List.Item>
                    <List.Header style={{ marginBottom: '0.3em' }}>Users Allowed</List.Header>
                    <List.Content style={{ textAlign: 'center' }}>
                      <CommentText style={{ textAlign: 'left', marginBottom: '3em' }}>
                        <strong>Enable ability for users to access this resource directly</strong>{' '}
                        instead of only allowing machine-to-machine connections
                      </CommentText>
                      <Checkbox
                        toggle
                        disabled
                        id="allowUsersView"
                        name="allowUsersView"
                        placeholder="Allow Users"
                        checked={resource.allowUsers}
                      />
                    </List.Content>
                  </List.Item>
                </List>
              </Grid.Column>
            )}
            <Grid.Column width={allowUsersFlag ? 5 : 8}>
              <List relaxed="very">
                <List.Item>
                  <List.Header style={{ marginBottom: '0.3em' }}>
                    Tenant URL{' '}
                    <Popup
                      trigger={<Icon name="help" circular color="grey" size="small" />}
                      content={`The domain of the Auth0 tenant.`}
                    />
                  </List.Header>
                  <List.Content>
                    <Label>
                      <Label.Detail>{tenant}</Label.Detail>
                      <Label.Detail>
                        <Icon
                          link
                          name="clone outline"
                          onClick={(): void => {
                            clipboardCopy(tenant);
                          }}
                        />
                      </Label.Detail>
                    </Label>
                  </List.Content>
                </List.Item>
                <List.Item>
                  <List.Header style={{ marginBottom: '0.3em' }}>
                    API Identifier{' '}
                    <Popup
                      trigger={<Icon name="help" circular color="grey" size="small" />}
                      content="A unique identifier for your resource in Auth0; pass this as your audience when requesting an access token from a client."
                    />
                  </List.Header>
                  <List.Content relaxed="very">
                    <Label>
                      <Label.Detail>{identifier}</Label.Detail>
                      <Label.Detail>
                        <Icon
                          link
                          name="clone outline"
                          onClick={(): void => {
                            clipboardCopy(identifier);
                          }}
                        />
                      </Label.Detail>
                    </Label>
                  </List.Content>
                </List.Item>
              </List>
            </Grid.Column>
            <Grid.Column width={allowUsersFlag ? 5 : 8}>
              <List relaxed="very">
                <List.Item>
                  <List.Header style={{ marginBottom: '0.3em' }}>
                    Custom API Scopes{' '}
                    <Popup
                      trigger={<Icon name="help" circular color="grey" size="small" />}
                      content={
                        'Custom permissions for your resource; check for these when validating access tokens to restrict access.'
                      }
                    />
                  </List.Header>
                  <List.Content style={{ height: '100px', overflowY: 'auto' }}>
                    {scopes && !!scopes.length && (
                      <Fragment>
                        {scopes.map(({ value }) => (
                          <Label key={`scope-${value}`} style={{ marginBottom: '0.3em' }}>
                            {value}
                          </Label>
                        ))}
                      </Fragment>
                    )}
                  </List.Content>
                </List.Item>
              </List>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <List relaxed="very">
          <List.Item>
            <List.Header as={'h2'}>
              Machine-to-Machine Connections{' '}
              <Popup
                trigger={<Icon name="help" circular color="grey" size="small" />}
                content={`Non-interactive clients; these always use the Client Credentials grant flow.`}
              />
            </List.Header>
            <List.Content>
              <ClientGrantsGrid clientGrants={clientGrants} tenant={resource.tenant} />
            </List.Content>
          </List.Item>
          <List.Item>
            <List.Header as={'h2'}>
              Groups{' '}
              <Popup
                trigger={<Icon name="help" circular color="grey" size="small" />}
                content="Permissions assigned to specific users when requesting a token for your resource's identifier. The scopes claim on the access token will contain the configured scopes."
              />
            </List.Header>
            <List.Content>
              <AuthRoleGrid authRoles={authRolesByResourceId} tenant={resource.tenant} />
            </List.Content>
          </List.Item>
        </List>
      </Fragment>
    )
  );
};

export default ResourceDetail;
