import React, { FC, Fragment, ReactElement } from 'react';
import { Loader } from 'semantic-ui-react';
import { IClient } from '../../components/TokenUsage/ClientUsage';
import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import Error from '../../components/Error';
import ClientUsage from '../../components/TokenUsage/ClientUsage';

interface IApp {
  id: string;
  name: string;
  description: string;
  appType: {
    id: string;
    name: string;
    showClientGrants: boolean;
  };
  team?: {
    id: string;
    name: string;
  };
  environments?: {
    id: string;
    isProduction: boolean;
    clients: IClient[];
  }[];
}

const GET_TEAM_APPS_CLIENTS = gql`
  query Team_Usage_GetTeamAppsAndHighUsageClients($id: ID!) {
    team(id: $id) {
      id
      apps {
        id
        name
        appType {
          id
          showClientGrants
        }
        environments {
          id
          isProduction
          clients {
            clientId: client_id
            envId: environment_id
            name
            tenant
          }
        }
      }
      highUsageClients {
        clientId: client_id
      }
    }
  }
`;

interface IProps {
  team: { id: string };
}

const Usage: FC<IProps> = ({ team }: IProps): ReactElement => {
  // get this team's clients
  const { loading, error, data } = useQuery<{
    team: { id: string; apps: IApp[]; highUsageClients: IClient[] };
  }>(GET_TEAM_APPS_CLIENTS, {
    variables: { id: team.id },
  });

  if (loading) return <Loader />;

  if (error) {
    return <Error header="An error occurred while fetching your token usage" error={error} />;
  }

  if (!data) {
    return <Fragment />;
  }

  const { team: teamData } = data;
  const { apps, highUsageClients } = teamData;

  let allClients: IClient[] = [];
  apps
    // only show usage for m2m clients
    .filter(app => app.appType.showClientGrants)
    .forEach(app => {
      return app.environments?.forEach(e => {
        if (e.clients.length) {
          allClients = allClients.concat(
            e.clients.map(c => {
              let isHighUsage = false;
              highUsageClients.forEach(client => {
                if (c.clientId === client.clientId) {
                  isHighUsage = true;
                }
              });
              return {
                ...c,
                app,
                isHighUsage,
              };
            })
          );
        }
      });
    });

  return <ClientUsage clients={allClients} />;
};

export default Usage;
