import React, { Fragment, SyntheticEvent } from 'react';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';
import { Checkbox, List, Card, Loader } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

import gql from 'graphql-tag';
import Error from '../Error';

const GET_RESOURCE = gql`
  query ChooseResourceScopes_GetResource($resourceId: ID!, $tenant: String!) {
    resource(resource_id: $resourceId, tenant: $tenant) {
      resourceId: resource_id
      name
      identifier
      envId: environment_id
      client {
        clientId: client_id
        name
      }
      scopes {
        value
        description
      }
      app {
        id
        name
      }
      allowUsers
    }
  }
`;

interface IProps {
  disabled: boolean;
  onClick: Function;
  resourceId: string;
  selectedScopes: any[];
  tenant: string;
}

const ChooseResourceScopes = ({
  disabled,
  onClick,
  resourceId,
  selectedScopes,
  tenant,
}: IProps) => {
  if (!resourceId) return null;

  return (
    <Query<any, any> query={GET_RESOURCE} variables={{ resourceId, tenant }}>
      {({ loading, error, data = {} }) => {
        if (loading) return <Loader active size="mini" />;
        if (error) return <Error error={error} />;

        const {
          resource: { name, resourceId, scopes = [], app },
        } = data;

        const handleClick = (e: SyntheticEvent, d: any) => {
          onClick(e, { ...d, resourceId });
        };

        return (
          <Fragment>
            <Card.Header style={{ paddingTop: '1em' }}>
              <Link to={`/app/${app.id}`}>{app.name}</Link>
            </Card.Header>
            <Card.Meta>{name}</Card.Meta>
            <Card.Content>
              <List id="scope-list" style={{ paddingLeft: 0 }}>
                <List.Header>Scopes:</List.Header>
                {scopes.length === 0 ? (
                  <i>None</i>
                ) : (
                  scopes.map(({ value, description }: { value: string; description: string }) => (
                    <List.Item key={`scope-${value}`}>
                      <Checkbox
                        onChange={handleClick}
                        label={value}
                        value={value}
                        name={value}
                        description={description}
                        checked={selectedScopes.some(s => s.value === value)}
                        disabled={disabled}
                      />
                    </List.Item>
                  ))
                )}
              </List>
            </Card.Content>
          </Fragment>
        );
      }}
    </Query>
  );
};

ChooseResourceScopes.displayName = 'ChooseResourceScopes';

ChooseResourceScopes.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  resourceId: PropTypes.string.isRequired,
  selectedScopes: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
    })
  ),
  tenant: PropTypes.string.isRequired,
};

ChooseResourceScopes.defaultProps = {
  disabled: false,
  selectedScopes: [],
};

export default ChooseResourceScopes;
