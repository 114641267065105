import React from 'react';
import { Container, Icon, Header } from 'semantic-ui-react';

const EmptyResults = () => (
  <Container textAlign="center" style={{ paddingTop: '5em' }}>
    <Icon name="search" size="huge" color="grey" />
    <Header color="grey">No items found.</Header>
  </Container>
);

export default EmptyResults;
