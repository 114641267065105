import React, { FC, ReactElement } from 'react';
import { useQuery } from 'react-apollo';
import Error from '../Error';
import { Placeholder } from 'semantic-ui-react';
import DataGrid from '../DataGrid';
import DeleteClientGrant from './DeleteClientGrant';
import { Link } from 'react-router-dom';
import gql from 'graphql-tag';

const GET_CLIENTS_BY_IDS = gql`
  query ClientGrantsGrid_GetClientsByIDs($clientIds: [ID!], $tenant: String!) {
    clientsByIDs(client_ids: $clientIds, tenant: $tenant) {
      clientId: client_id
      envId: environment_id
      name
      description
      app {
        id
        name
        team {
          id
          name
        }
      }
    }
  }
`;

interface IClient {
  clientId: string;
  envId: string;
  name: string;
  description: string;
  app: {
    id: string;
    name: string;
    team: {
      id: string;
      name: string;
    };
  };
}

interface IClientGrantGridData {
  id: string;
  appName: ReactElement;
  clientName: string;
  teamName: string;
  scope: string[];
  clientId: string;
  deleteButton: ReactElement;
}

interface IClientGrant {
  id: string;
  audience: string;
  clientId: string;
  scope: string[];
  tenant: string;
  resource: {
    resourceId: string;
  };
  status: string;
}

interface IProps {
  tenant: string;
  clientGrants: IClientGrant[];
}

const ClientGrantsGrid: FC<IProps> = ({ tenant, clientGrants }: IProps): ReactElement => {
  const clientIds = clientGrants.map(cg => cg.clientId);
  const { loading, error, data } = useQuery<{
    clientsByIDs: IClient[];
  }>(GET_CLIENTS_BY_IDS, {
    variables: { clientIds, tenant },
  });

  if (error) return <Error error={error} />;

  let gridData: IClientGrantGridData[] = [];

  // normalize data, if available
  if (data) {
    const { clientsByIDs } = data;
    gridData = clientsByIDs.reduce((acc: IClientGrantGridData[], c: IClient) => {
      if (!c || c.app === null) return acc;
      const cg = clientGrants.find(cg => cg.clientId === c.clientId);
      if (cg && cg !== null) {
        const appNameLink = <Link to={`/app/${c.app?.id}`}>{c.app.name}</Link>;
        acc.push({
          id: cg.id,
          appName: appNameLink,
          clientName: c.name,
          teamName: c.app.team.name,
          scope: cg.scope,
          clientId: c.clientId,
          deleteButton: (
            <DeleteClientGrant
              id={cg.id}
              clientId={c.clientId}
              tenant={tenant}
              resourceId={cg.resource.resourceId}
              audience={cg.audience}
            />
          ),
        });
      }
      return acc;
    }, []);
  }

  const columns = [
    {
      id: 1,
      name: 'App Name',
      dataField: 'appName',
      dataType: 'component',
    },
    {
      id: 2,
      name: 'Client Name',
      dataField: 'clientName',
      dataType: 'string',
    },
    {
      id: 3,
      name: 'Team Name',
      dataField: 'teamName',
      dataType: 'string',
    },
    {
      id: 4,
      name: 'Scopes',
      dataField: 'scope',
      dataType: 'arrayOfValues',
    },
    {
      id: 5,
      name: 'Client ID',
      dataField: 'clientId',
      dataType: 'copystring',
    },
    {
      id: 6,
      name: '',
      dataField: 'deleteButton',
      dataType: 'component',
    },
  ];

  return (
    <DataGrid
      data={gridData}
      columns={columns}
      noItemsMessage={'No client grants found for your resource'}
      loading={loading}
      placeholder={
        <Placeholder>
          <Placeholder.Line />
          <Placeholder.Line />
          <Placeholder.Line />
          <Placeholder.Line />
          <Placeholder.Line />
        </Placeholder>
      }
    />
  );
};

export default ClientGrantsGrid;
