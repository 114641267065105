import React, { FC } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Container, Message } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import 'react-toastify/dist/ReactToastify.css';
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';

import { ToastContainer, toast } from 'react-toastify';

import Home from './scenes/home';
import Apps from './scenes/apps';
import Teams from './scenes/teams';
import AppScene from './scenes/app';
import User from './scenes/user';
import Team from './scenes/team';
import Requests from './scenes/requests';
import Docs from './scenes/docs';
import Search from './scenes/search';

import Splash from './components/Splash';
import NavBar from './components/NavBar';
import SearchBar from './components/SearchBar';
import SiteFooter from './components/SiteFooter';

import FeedbackPopup from './components/FeedbackPopup';
import PrivateRoute from './auth/PrivateRoute';
import { useAuth } from './auth/AuthProvider';
import { useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import HighUsage from './scenes/highUsage';

const ME = gql`
  query App_GetMe {
    me {
      id
      isAdmin
      firstName
      lastName
      email
      jobFamily
      hash
      teams {
        id
        name
        description
      }
    }
  }
`;

interface IData {
  me: {
    email: string;
    firstName: string;
    lastName: string;
    jobFamily: string;
    hash: string;
    isAdmin: boolean;
  };
}

const App: FC<{}> = () => {
  const { enableSearch, highUsageAdmin } = useFlags();

  // identify user for LaunchDarkly
  const ldClient = useLDClient();
  const { data, error, loading } = useQuery<IData>(ME);

  // we don't want to make anything wait on this query to finish to render
  // so we don't want to return
  if (ldClient && !loading && !error && data) {
    ldClient.identify(
      {
        key: data.me.email,
        firstName: data.me.firstName,
        lastName: data.me.lastName,
        custom: {
          jobFamily: data.me.jobFamily,
        },
      },
      data.me.hash
    );
  }

  const { logout } = useAuth();

  const handleLogout = () => {
    logout({ returnTo: window.location.origin });
  };

  return (
    <>
      <Route
        exact
        path="/"
        // eslint-disable-next-line react/no-children-prop
        children={({ match }) => (
          <Splash size={match && enableSearch ? 'large' : 'small'}>
            <NavBar onLogout={handleLogout} />
            {match && enableSearch && (
              <SearchBar
                header="Welcome to the RV Developer Network"
                fields={['name^4', 'description^2']}
                queryLimit={6}
              />
            )}
          </Splash>
        )}
      />

      <Container fluid style={{ flex: 1, position: 'relative' }}>
        <Message warning>
          <Message.Header>Cache &apos;em tokens</Message.Header>
          <p>
            M2M tokens in Marvin are good for up to 24 hours. Caching tokens can save RV and your
            business unit money. Get started with{' '}
            <a href="https://marvin.rvdocs.io/jwt-caching-guidance">
              caching guidance and code snippets
            </a>{' '}
            here.
          </p>
        </Message>
        <Switch>
          <PrivateRoute path="/docs" component={Docs} />
          <PrivateRoute exact path="/" component={Home} />
          <PrivateRoute path="/team/:id" component={Team} />
          <PrivateRoute path="/app/:id" component={AppScene} />
          <PrivateRoute path="/person/:id" component={User} />
          <PrivateRoute path="/requests" component={Requests} />
          <PrivateRoute path="/apps" component={Apps} />
          <PrivateRoute path="/teams" component={Teams} />
          {enableSearch && <PrivateRoute path="/search" component={Search} />}
          {highUsageAdmin && data?.me.isAdmin && (
            <PrivateRoute exact path="/usage" component={HighUsage} />
          )}
        </Switch>
        <FeedbackPopup />
      </Container>
      <ToastContainer position={toast.POSITION.BOTTOM_LEFT} />
      <SiteFooter />
    </>
  );
};

export default App;
