import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';
import InputDescription from '../../components/InputDescription';

export const CLIENT_FORM_ID = 'update-client-form';

class UpdateClientForm extends Component {
  constructor(props) {
    super(props);
    const { webOrigins, callbacks, description, logoutUrls, name } = this.props;

    this.state = {
      webOrigins,
      callbacks,
      description,
      logoutUrls,
      name,
    };
  }

  handleChange = (e, { name, value }) => {
    this.setState({ [name]: value });
  };

  handleSubmit = e => {
    e.preventDefault();
    const { webOrigins, callbacks, description, logoutUrls, name } = this.state;
    const { clientId, updateClient, tenant } = this.props;

    updateClient({
      variables: {
        webOrigins,
        callbacks,
        clientId,
        description,
        logoutUrls,
        name,
        tenant,
      },
    });
  };

  render() {
    const { webOrigins, callbacks, description, logoutUrls, name } = this.state;
    const { appType } = this.props;

    const getItemList = arr =>
      Array.isArray(arr)
        ? arr.map(item => ({
            key: item,
            text: item,
            value: item,
          }))
        : [];

    const callbacksList = getItemList(callbacks);
    const webOriginsList = getItemList(webOrigins);
    const logoutUrlsList = getItemList(logoutUrls);

    return (
      <Form onSubmit={this.handleSubmit} id="update-client-form">
        <Form.Input
          id="name"
          placeholder="Resource Name"
          name="name"
          label="Name"
          value={name}
          required
          autoFocus
          onChange={this.handleChange}
        />
        <InputDescription description="A friendly name for the API." />
        <Form.Input
          id="description"
          placeholder="Description"
          name="description"
          label="Description"
          value={description}
          onChange={this.handleChange}
        />
        <InputDescription description="A free text description of the application. Max character count is 140." />
        {appType.showCallbacksField && (
          <Fragment>
            <Form.Dropdown
              multiple
              selection
              allowAdditions
              search
              id="callbacks"
              name="callbacks"
              label="Callbacks"
              options={callbacksList}
              value={callbacks}
              onChange={this.handleChange}
            />
            <InputDescription
              description="After the user authenticates we will only call back to any of
              these URLs. You can specify multiple valid URLs (typically to handle different environments
              like QA or testing). Make sure to specify the protocol, http:// or
              https://, otherwise the callback may fail in some cases."
            />
          </Fragment>
        )}
        {appType.showAllowedOriginsField && (
          <Fragment>
            <Form.Dropdown
              multiple
              selection
              allowAdditions
              search
              id="webOrigins"
              placeholder="http://mysite.com, https://myothersite.com"
              name="webOrigins"
              label="Allowed Web Origins"
              options={webOriginsList}
              value={webOrigins}
              onChange={this.handleChange}
            />
            <InputDescription
              description="List of allowed origin URLs for use with Cross-Origin Authentication,
              Device Flow, and web message response mode."
            />
          </Fragment>
        )}
        {appType.showLogoutURLsField && (
          <Fragment>
            <Form.Dropdown
              multiple
              selection
              allowAdditions
              search
              id="logoutUrls"
              name="logoutUrls"
              label="Logout URLs"
              value={logoutUrls}
              options={logoutUrlsList}
              onChange={this.handleChange}
            />
            <InputDescription
              description="A set of valid URLs to redirect to after logout.
              When a user logs out, you can redirect them with the returnTo
              query parameter. The URL that you use in returnTo must be listed
              here."
            />
          </Fragment>
        )}
      </Form>
    );
  }
}

UpdateClientForm.propTypes = {
  webOrigins: PropTypes.arrayOf(PropTypes.string),
  callbacks: PropTypes.arrayOf(PropTypes.string),
  clientId: PropTypes.string.isRequired,
  description: PropTypes.string,
  logoutUrls: PropTypes.arrayOf(PropTypes.string),
  name: PropTypes.string.isRequired,
  tenant: PropTypes.string.isRequired,
  updateClient: PropTypes.func.isRequired,
};

UpdateClientForm.defaultProps = {
  webOrigins: [],
  callbacks: [],
  description: '',
  logoutUrls: [],
};

UpdateClientForm.displayName = 'UpdateClientForm';

export default UpdateClientForm;
