import React, { Fragment, FC, useState } from 'react';
import { Confirm, Button, Icon } from 'semantic-ui-react';
import { useMutation } from 'react-apollo';
import { toast } from 'react-toastify';
import gql from 'graphql-tag';
import getErrorMessages from '../../util/errors';
import { ExecutionResult } from 'graphql';

const DELETE_AUTH_ROLE = gql`
  mutation DeleteAuthRole_DeleteAuthRole($tenant: String!, $clientId: ID!, $roleId: ID!) {
    deleteAuthRole(tenant: $tenant, client_id: $clientId, role_id: $roleId) {
      success
      error {
        message
        code
      }
    }
  }
`;

const GET_AUTH_ROLES_BY_RESOURCE_ID = gql`
  query DeleteAuthRole_GetAuthRolesByResourceId($tenant: String!, $resourceId: ID!) {
    authRolesByResourceId(tenant: $tenant, resource_id: $resourceId) {
      role_id
      name
      description
      permissions {
        description
        name: permission_name
      }
      status
      users {
        user_id
        email
      }
      resource_id
    }
  }
`;

interface IProps {
  id: string;
  clientId: string;
  tenant: string;
  resourceId: string;
}

const DeleteAuthRole: FC<IProps> = ({ id, clientId, tenant, resourceId }: IProps) => {
  const [isConfirmVisible, setIsConfirmVisible] = useState<boolean>(false);
  const [deleteAuthRole, { loading }] = useMutation(DELETE_AUTH_ROLE, {
    onCompleted: () => toast.success('Successfully deleted auth role.'),
    onError: (error: Error) => toast.error(`Error encountered: ${getErrorMessages(error)}`),
    refetchQueries: [
      {
        query: GET_AUTH_ROLES_BY_RESOURCE_ID,
        variables: {
          resourceId,
          tenant,
        },
      },
    ],
  });

  return (
    <Fragment>
      <Confirm
        open={isConfirmVisible}
        content="Removing this client grant will prevent this client from accessing your resource. Are you sure you want to continue?"
        onCancel={(): void => setIsConfirmVisible(false)}
        onConfirm={(): Promise<ExecutionResult<any>> => {
          setIsConfirmVisible(false);
          return deleteAuthRole({
            variables: {
              tenant,
              clientId,
              roleId: id,
            },
          });
        }}
      />
      <Button color="red" loading={loading} onClick={(): void => setIsConfirmVisible(true)}>
        <Icon link name="trash" />
        Delete
      </Button>
    </Fragment>
  );
};

export default DeleteAuthRole;
