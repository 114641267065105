import React from 'react';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';
import { Container } from 'semantic-ui-react';
import GridLayout from '../../layouts/GridLayout';
import ContainerLoader from '../../components/ContainerLoader';
import TeamsDetail from './TeamsDetail';
import Error from '../../components/Error';
import gql from 'graphql-tag';

const GET_ALL_TEAMS = gql`
  query Teams_GetTeams {
    teams {
      id
      name
      description
    }
  }
`;

const Teams = ({ me }) => (
  <GridLayout title="Teams" icon="list" iconColor="grey">
    <Query query={GET_ALL_TEAMS}>
      {({ loading, error, data }) => {
        if (error) {
          return (
            <Error
              error={error}
              header='"I&#39;ve been talking to the main computer... it hates me."'
            />
          );
        }
        if (loading) return <ContainerLoader />;

        return (
          <Container fluid>
            <TeamsDetail me={me} teams={data.teams || []} />
          </Container>
        );
      }}
    </Query>
  </GridLayout>
);

Teams.displayName = 'Teams';
Teams.propTypes = {
  me: PropTypes.object.isRequired,
};

export default Teams;
