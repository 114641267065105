import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Tab, Card, Icon, Grid, Input, SemanticICONS } from 'semantic-ui-react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import DetailPane from '../components/DetailPane';

import SearchParam from './SearchParam';
import EmptyResults from './EmptyResults';
import { SemanticCOLORS } from 'semantic-ui-react/dist/commonjs/generic';

interface IDetailPaneContent {
  title?: string;
  descriptionTitle: string;
  description?: string;
  lists?: {
    listTitle?: string;
    listIcon?: SemanticICONS;
    listItems: {
      key: string;
      text: string;
      subtext?: string;
      linkTo?: string;
      iconColor?: SemanticCOLORS;
    }[];
  }[];
}
interface IProps extends RouteComponentProps {
  detailPaneContent?: IDetailPaneContent;
  children(a: any): any;
  filterFn(a: any): any;
  createButton?: Element;
}

class TabPane extends Component<IProps> {
  handleFilterChange = (e: Event, { value }: { value: string }) => {
    const { history } = this.props;
    history.push({
      search: `?q=${value}`,
    });
  };

  clearFilter = () => {
    const { history } = this.props;
    history.push({
      search: '',
    });
  };

  render() {
    const { createButton, filterFn, children, detailPaneContent } = this.props;

    return (
      <SearchParam param="q" defaultValue="">
        {(filterValue: any) => {
          const filteredData = filterFn(filterValue);

          return (
            <Tab.Pane as="div">
              <Grid stackable>
                {createButton && (
                  <Grid.Column floated="left" width={6}>
                    {createButton}
                  </Grid.Column>
                )}
                <Grid.Column floated="right" width={5}>
                  <Input
                    fluid
                    icon={
                      filterValue === '' ? (
                        <Icon name="search" />
                      ) : (
                        <Icon name="close" link onClick={this.clearFilter} />
                      )
                    }
                    placeholder="Filter"
                    value={filterValue}
                    onChange={this.handleFilterChange as any}
                  />
                </Grid.Column>
              </Grid>
              <Grid>
                {detailPaneContent && (
                  <Grid.Column width={3}>
                    <DetailPane content={detailPaneContent} />
                  </Grid.Column>
                )}
                <Grid.Column width={detailPaneContent ? 13 : 16}>
                  {filteredData.length > 0 && (
                    <Card.Group stackable itemsPerRow={4}>
                      {filteredData.map(children)}
                    </Card.Group>
                  )}
                  {filteredData.length === 0 && <EmptyResults />}
                </Grid.Column>
              </Grid>
              {createButton && filteredData.length > 0 && (
                <Grid stackable>
                  <Grid.Column floated="left" width={6}>
                    {createButton}
                  </Grid.Column>
                </Grid>
              )}
            </Tab.Pane>
          );
        }}
      </SearchParam>
    );
  }

  static propTypes = {
    children: PropTypes.func.isRequired,
    filterFn: PropTypes.func,
    createButton: PropTypes.node,
  };

  static defaultProps = {
    filterFn: (v: any) => v,
    createButton: null,
  };
}

export default withRouter<any, any>(TabPane);
