/* eslint-disable react/destructuring-assignment */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { Divider, Card } from 'semantic-ui-react';
import { Mutation } from 'react-apollo';

import AddClientGrants from './AddClientGrants';
import gql from 'graphql-tag';
import getErrorMessages from '../../util/errors';

import PendingClientGrants from './PendingClientGrants';
import ApprovedClientGrants from './ApprovedClientGrants';

const CREATE_RESOURCE_REQUEST = gql`
  mutation ClientGrants_CreateResourceRequest(
    $audience: String!
    $clientGrantId: ID
    $clientId: ID!
    $resourceId: ID!
    $scope: [String]
    $tenant: String!
  ) {
    createResourceRequest(
      audience: $audience
      client_id: $clientId
      clientGrantId: $clientGrantId
      resource_id: $resourceId
      scope: $scope
      tenant: $tenant
    ) {
      success
      error {
        message
        code
      }
    }
  }
`;

const GET_CLIENT_GRANTS_BY_CLIENT_ID = gql`
  query ClientGrants_GetClientGrants($tenant: String!, $clientId: ID!, $audience: String) {
    clientGrants(tenant: $tenant, client_id: $clientId, audience: $audience) {
      id
      audience
      clientId: client_id
      scope
      tenant
      resource {
        resourceId: resource_id
      }
      status
    }
  }
`;

const GET_PENDING_CLIENT_GRANTS = gql`
  query ClientGrants_GetPendingClientGrants($clientId: ID!) {
    pendingClientGrants(client_id: $clientId) {
      id
      audience
      clientId: client_id
      scope
      tenant
      resource {
        resourceId: resource_id
      }
      status
    }
  }
`;

const ME = gql`
  query ClientGrants_GetMe {
    me {
      id
      isAdmin
      firstName
      lastName
      email
      jobFamily
      hash
      teams {
        id
        name
        description
      }
    }
  }
`;

class ClientGrants extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isConfirmVisible: false,
    };
  }

  render() {
    const { clientId, tenant, envId, meIsOwner } = this.props;

    return (
      <Fragment>
        {meIsOwner && (
          <Card.Group id="client-credentials" itemsPerRow={3}>
            <Mutation
              mutation={CREATE_RESOURCE_REQUEST}
              refetchQueries={[
                {
                  query: GET_CLIENT_GRANTS_BY_CLIENT_ID,
                  variables: { clientId, tenant },
                },
                {
                  query: GET_PENDING_CLIENT_GRANTS,
                  variables: { clientId, tenant },
                },
                {
                  query: ME,
                },
              ]}
              onCompleted={() => {
                toast.success('Successfully requested client grant.');
              }}
              onError={error =>
                toast.error(`Error encountered: ${getErrorMessages(error)}`, {
                  autoClose: false,
                })
              }
            >
              {(createResourceRequest, { loading }) => {
                if (loading) return <p>Loading...</p>;

                return (
                  <AddClientGrants
                    clientId={clientId}
                    tenant={tenant}
                    envId={envId}
                    onSave={({ audience, scope, resourceId }) => {
                      createResourceRequest({
                        variables: {
                          audience,
                          clientId,
                          scope,
                          tenant,
                          resourceId,
                        },
                      });
                    }}
                  />
                );
              }}
            </Mutation>
            <Divider hidden />
            <PendingClientGrants clientId={clientId} tenant={tenant} meIsOwner={meIsOwner} />
            <ApprovedClientGrants clientId={clientId} tenant={tenant} meIsOwner={meIsOwner} />
          </Card.Group>
        )}
      </Fragment>
    );
  }
}

ClientGrants.displayName = 'ClientGrants';

ClientGrants.propTypes = {
  tenant: PropTypes.string.isRequired,
  clientId: PropTypes.string.isRequired,
  envId: PropTypes.string.isRequired,
};

ClientGrants.defaultProps = {};

export default ClientGrants;
