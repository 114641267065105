import React, { useEffect, useState, FC, ReactNode } from 'react';
import { withClientState } from 'apollo-link-state';
import { InMemoryCache, NormalizedCacheObject } from 'apollo-cache-inmemory';
import { setContext } from 'apollo-link-context';
import ApolloClient from 'apollo-client';
import { HttpLink } from 'apollo-link-http';
import { ApolloLink } from 'apollo-link';
import { useAuth } from './auth/AuthProvider';
import { ApolloProvider } from 'react-apollo';

interface IProps {
  children: ReactNode;
  /**
   * The uri of the GraphQL endpoint.
   */
  uri: string;
}

export const Apollo: FC<IProps> = (props: IProps) => {
  const [apolloClient, setApolloClient] = useState<ApolloClient<NormalizedCacheObject> | null>(
    null
  );
  const { getTokenSilently, user } = useAuth();

  // Setup Apollo. We only want this to run once so we pass an empty array as the second argument.
  useEffect(() => {
    const cache = new InMemoryCache();

    const authLink = setContext(async (_, { headers }) => {
      const token = await getTokenSilently();

      return {
        headers: {
          ...headers,
          authorization: token ? `Bearer ${token}` : '',
        },
      };
    });

    const stateLink = withClientState({
      cache,
      defaults: {
        searchTerm: '',
        userProfile: {},
        userName: '',
        userImage: '',
        isAuthenticated: false,
        jobFamily: '',
      },
      resolvers: {},
    });

    const client = new ApolloClient({
      cache,
      name: 'Marvin Client',
      link: ApolloLink.from([stateLink, authLink, new HttpLink({ uri: props.uri })]),
      resolvers: {},
    });

    client.writeData({
      data: {
        ...user,
        isAuthenticated: true,
      },
    });
    setApolloClient(client);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (!apolloClient) {
    return null;
  }

  return <ApolloProvider client={apolloClient}>{props.children}</ApolloProvider>;
};
