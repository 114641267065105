import { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter, RouteComponentProps } from 'react-router-dom';

interface IProps extends RouteComponentProps {
  location: any;
  children(a: string): any;
  param?: string;
  defaultValue: string | null;
}

class SearchParam extends Component<IProps> {
  getParam = () => {
    const { location, param = '', defaultValue } = this.props;
    const searchParams = new URLSearchParams(location && location.search);
    const value = searchParams.get(param);

    return value === null ? defaultValue : value;
  };

  render() {
    const { children } = this.props;
    const paramValue = this.getParam();

    return children(paramValue || '');
  }

  static propTypes = {
    location: PropTypes.shape({
      search: PropTypes.string,
    }).isRequired,
    children: PropTypes.func.isRequired,
    param: PropTypes.string.isRequired,
    defaultValue: PropTypes.any,
  };

  static defaultProps = {
    defaultValue: null,
  };
}

export default withRouter(SearchParam);
