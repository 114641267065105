import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import 'semantic-ui-css/semantic.min.css';
import 'react-toastify/dist/ReactToastify.css';
import ReactGA from 'react-ga';

import GAListener from './GoogleAnalytics.tsx';
import { LaunchDarklyProvider } from './LaunchDarkly';

import { ThemeProvider } from 'emotion-theming';
import { theme } from './styles/theme';

import './main.css';

import { Apollo } from './Apollo.tsx';
import App from './App.tsx';
import { AuthProvider } from './auth/AuthProvider.tsx';
import config from './config';

ReactGA.initialize(config.gaTrackingId);
ReactGA.pageview(window.location.pathname + window.location.search);

class Main extends React.Component {
  render() {
    return (
      <AuthProvider
        clientOptions={{
          domain: config.domain,
          client_id: config.clientId,
          audience: config.resource,
          redirect_uri: `${window.location.origin}/login`,
        }}
        returnTo={window.location.origin}
      >
        <Apollo uri="/graphql">
          <LaunchDarklyProvider>
            <BrowserRouter>
              <GAListener>
                <ThemeProvider theme={theme}>
                  <App handleLogout={this.handleLogout} />
                </ThemeProvider>
              </GAListener>
            </BrowserRouter>
          </LaunchDarklyProvider>
        </Apollo>
      </AuthProvider>
    );
  }
}

// add global function to trigger jira issue collector form
window.ATL_JQ_PAGE_PROPS = {
  triggerFunction: showFeedback => {
    window.showFeedback = showFeedback;
  },
};

ReactDOM.render(<Main />, document.getElementById('root'));
