import React, { FC } from 'react';
import { useQuery } from 'react-apollo';
import { Container } from 'semantic-ui-react';
import CardsLoader from '../../components/CardsLoader';
import GridLayout from '../../layouts/GridLayout';
import _ from 'lodash';
import gql from 'graphql-tag';

import { ITeam } from '../app/CreateApp';
import AppsDetail from './AppsDetail';
import Error from '../../components/Error';

const GET_ALL_APPS = gql`
  query Apps_GetApps {
    apps {
      id
      name
      description
      appType {
        id
        name
        description
      }
      team {
        id
        name
      }
    }
  }
`;

interface IProps {
  me: {
    teams: ITeam[];
  };
}

interface IData {
  apps: {
    id: string;
    name: string;
    description: string;
    appType: {
      id: string;
      name: string;
      description: string;
    };
    team: {
      id: string;
      name: string;
    };
  }[];
}

const Apps: FC<IProps> = ({ me }: IProps) => {
  const defaultTeam = _.get(me, 'teams[0]', null);
  const { loading, error, data } = useQuery<IData>(GET_ALL_APPS);

  if (loading) return <CardsLoader />;

  if (error || !data) {
    return (
      <Error error={error} header='"I&#39;ve been talking to the main computer... it hates me."' />
    );
  }

  return (
    <GridLayout title="Registry" icon="list" iconColor="grey">
      <Container fluid>
        <AppsDetail me={me} apps={data.apps} defaultTeam={defaultTeam} />
      </Container>
    </GridLayout>
  );
};

Apps.displayName = 'Apps';

export default Apps;
