import React, { Component } from 'react';
import { Message } from 'semantic-ui-react';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
    };
  }

  componentDidCatch(error) {
    // TODO add logging
    this.setState({
      hasError: true,
      error,
    });
  }

  render() {
    const { hasError, error } = this.state;
    const { children } = this.props;

    if (hasError) {
      return (
        <Message negative>
          <Message.Header>Error</Message.Header>
          <Message.Content>{error.toString()}</Message.Content>
        </Message>
      );
    }

    return children;
  }
}

ErrorBoundary.displayName = 'ErrorBoundary';

ErrorBoundary.propTypes = {};

ErrorBoundary.defaultProps = {};

export default ErrorBoundary;
