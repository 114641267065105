import React, { FC, ReactElement, Fragment } from 'react';
import { useQuery } from 'react-apollo';
import { Label } from 'semantic-ui-react';
import gql from 'graphql-tag';

interface IClient {
  clientId: string;
  envId: string;
  name: string;
  tenant: string;
  tokenCost: {
    isHighUsage: boolean;
  };
}

interface IMe {
  me: {
    id: string;
    incomingResourceRequestsByStatus: {
      id: string;
    }[];
  };
}

const ME_NOTIFICATIONS = gql`
  query RequestsBadge_GetMyIncomingRequestsByStatus {
    me {
      id
      incomingResourceRequestsByStatus(requestStatus: "pending") {
        id
      }
    }
  }
`;

const RequestsBadge: FC = (): ReactElement => {
  // TODO: move me query to index.js so that we only query this once
  const { loading, error, data } = useQuery<IMe>(ME_NOTIFICATIONS);

  if (loading) return <Fragment />;
  if (error || !data) return <Fragment />;

  const { me } = data;

  const notifCount = me.incomingResourceRequestsByStatus.length;

  if (notifCount === 0) {
    return <Fragment />;
  }

  return <Label size="tiny" floating circular color="yellow" content={notifCount} />;
};

export default RequestsBadge;
