import React, { FC } from 'react';
import { Container, Tab, List, Menu, Icon } from 'semantic-ui-react';
import AppPane from '../../components/AppPane';
import HighTokenUsageBadge from '../../components/TokenUsage/HighTokenUsageBadge';
import Usage from './Usage';
import { IClient } from '../../components/TokenUsage/ClientUsage';
import { useFlags } from 'launchdarkly-react-client-sdk';

interface IApp {
  id: string;
  name: string;
  description: string;
  appType: {
    id: string;
    name: string;
    showClientGrants: boolean;
  };
  team?: {
    id: string;
    name: string;
  };
  environments: {
    id: string;
    isProduction: boolean;
    clients?: IClient[];
  }[];
}

interface IProps {
  me: {
    id: string;
  };
  user: {
    id: string;
    apps?: IApp[] | undefined;
  };
}

const UserDetail: FC<IProps> = ({ me, user }) => {
  const { apps } = user;
  const { userTokenUsage } = useFlags();

  const panes = [
    {
      menuItem: (
        <Menu.Item key="apps">
          <Icon name="snowflake outline" /> Apps
        </Menu.Item>
      ),
      render: () => <AppPane apps={apps} showCreateApp={false} />,
    },
  ];

  // only show usage for self
  if (userTokenUsage && me.id === user.id) {
    panes.push({
      menuItem: (
        <Menu.Item key="tokenUsage">
          <Icon name="chart line" /> Token Usage{' '}
          <HighTokenUsageBadge circular={false} floating={false} />
        </Menu.Item>
      ),
      render: () => (
        <List size="large" relaxed divided>
          <Usage me={me} />
        </List>
      ),
    });
  }

  return (
    <Container fluid>
      <Tab menu={{ secondary: true, pointing: true }} panes={panes} />
    </Container>
  );
};

export default UserDetail;
