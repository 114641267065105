import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';
import { Button, Icon, Popup } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import gql from 'graphql-tag';
import CreateResourceForm, { RESOURCE_FORM_ID } from './CreateResourceForm';
import ModalState from '../../layouts/ModalState';
import ModalForm from '../../layouts/ModalForm';
import getErrorMessages from '../../util/errors';

const CREATE_RESOURCE = gql`
  mutation CreateResource_CreateResource(
    $name: String!
    $identifier: String!
    $envId: ID!
    $scopes: [ScopeInput]
    $appId: ID!
    $allowUsers: Boolean
  ) {
    createResource(
      name: $name
      identifier: $identifier
      envId: $envId
      scopes: $scopes
      appId: $appId
      allowUsers: $allowUsers
    ) {
      error {
        message
        code
      }
      success
      resource {
        resourceId: resource_id
        allowUsers
        name
        identifier
        tenant
        envId: environment_id
        scopes {
          value
          description
        }
      }
    }
  }
`;

const GET_ENV_RESOURCES = gql`
  query CreateResource_GetEnvResources($envs: [ID]!) {
    envResources(envs: $envs) {
      resourceId: resource_id
      allowUsers
      name
      identifier
      tenant
      envId: environment_id
      app {
        id
        name
      }
      scopes {
        value
        description
      }
    }
  }
`;

const CreateResource = ({ environments, appId, appName, onResourceCreate, setCurrentEnvId }) => {
  const envs = environments.map(e => e.id);

  return (
    <ModalState>
      {(modalOpen, toggleModal) => (
        <Fragment>
          {envs.length > 0 ? (
            <Button compact id="create-resource" primary onClick={toggleModal}>
              <Icon name="add" />
              Register a Resource
            </Button>
          ) : (
            <Popup
              flowing
              trigger={
                <span>
                  <Button primary disabled>
                    <Icon name="add" />
                    Register a Resource
                  </Button>
                </span>
              }
              content="You must register at least one environment before registering a resource."
            />
          )}

          {modalOpen && (
            <Mutation
              mutation={CREATE_RESOURCE}
              refetchQueries={[
                {
                  query: GET_ENV_RESOURCES,
                  variables: { envs },
                },
              ]}
              awaitRefetchQueries
              onCompleted={({ createResource }) => {
                // Set new resource as selected as long as we have one
                if (createResource.resource) {
                  onResourceCreate(createResource.resource);
                  setCurrentEnvId(createResource.resource.envId);
                }

                toggleModal();
                toast.success('Successfully created resource!');
              }}
              onError={error =>
                toast.error(`Error encountered: ${getErrorMessages(error)}`, {
                  autoClose: false,
                })
              }
            >
              {(addResource, { loading, error }) => {
                const errors = getErrorMessages(error);

                return (
                  <ModalForm
                    header={`Register a Resource for ${appName}`}
                    actionAttr={{
                      positive: true,
                      type: 'submit',
                      form: RESOURCE_FORM_ID,
                    }}
                    errors={errors}
                    actionText="Register Resource"
                    loading={loading}
                    open={modalOpen}
                    toggleModal={toggleModal}
                  >
                    <CreateResourceForm
                      appId={appId}
                      addResource={addResource}
                      appName={appName}
                      environments={environments}
                      errors={errors}
                    />
                  </ModalForm>
                );
              }}
            </Mutation>
          )}
        </Fragment>
      )}
    </ModalState>
  );
};

CreateResource.propTypes = {
  appId: PropTypes.string.isRequired,
  appName: PropTypes.string.isRequired,
  environments: PropTypes.arrayOf(PropTypes.object),
  onResourceCreate: PropTypes.func,
  setCurrentEnvId: PropTypes.func,
};

CreateResource.defaultProps = {
  environments: [],
};

CreateResource.displayName = 'CreateResource';

export default CreateResource;
