import React, { FC, ReactElement, Fragment } from 'react';
import { useQuery } from 'react-apollo';
import { Label } from 'semantic-ui-react';
import gql from 'graphql-tag';

interface IClient {
  clientId: string;
  tokenCost: {
    isHighUsage: boolean;
  };
}

interface IMe {
  team: {
    id: string;
    highUsageClients: IClient[];
  };
}

const TEAM_NOTIFICATIONS = gql`
  query TeamHighTokenUsageBadge_GetTeamHighUsageClients($id: ID!) {
    team(id: $id) {
      id
      highUsageClients {
        clientId: client_id
      }
    }
  }
`;

interface IProps {
  id: string;
  circular?: boolean | undefined;
  floating?: boolean | undefined;
  size?: 'big' | 'small' | 'tiny' | 'mini' | 'medium' | 'large' | 'huge' | 'massive' | undefined;
}

const TeamHighTokenUsageBadge: FC<IProps> = ({
  circular = true,
  floating = true,
  size = 'tiny',
  id,
}: IProps): ReactElement => {
  const { loading, error, data } = useQuery<IMe>(TEAM_NOTIFICATIONS, { variables: { id } });

  if (loading) return <Fragment />;
  if (error || !data) return <Fragment />;

  const { team } = data;

  const notifCount = team.highUsageClients.length;
  if (!notifCount) return <Fragment />;

  return (
    <Label size={size} floating={floating} circular={circular} color="red" content={notifCount} />
  );
};

export default TeamHighTokenUsageBadge;
