import React from 'react';
import { Container } from 'semantic-ui-react';

import styled from '../../styled';

const StyledSiteFooter = styled.div`
  background-color: ${props => props.theme.colors.primary};
  color: ${props => props.theme.colors.white};
  padding: 2rem 0;
  margin-top: 2rem;
`;

const SiteFooter = () => (
  <StyledSiteFooter>
    <Container text textAlign="center">
      Built with{' '}
      <span role="img" aria-label="Heart">
        ❤️️
      </span>{' '}
      by <strong>Developer Experience</strong>
    </Container>
  </StyledSiteFooter>
);

export default SiteFooter;
