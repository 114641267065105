import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';
import { Button, Icon, Message, Grid } from 'semantic-ui-react';
import { toast } from 'react-toastify';

import gql from 'graphql-tag';
import UpdateResourceForm, { RESOURCE_FORM_ID } from './UpdateResourceForm';
import ModalState from '../../layouts/ModalState';
import ModalForm from '../../layouts/ModalForm';
import getErrorMessages from '../../util/errors';

const GET_ENV_RESOURCES = gql`
  query UpdateResource_GetEnvResources($envs: [ID]!) {
    envResources(envs: $envs) {
      resourceId: resource_id
      allowUsers
      name
      identifier
      tenant
      envId: environment_id
      app {
        id
        name
      }
      scopes {
        value
        description
      }
    }
  }
`;

const UPDATE_RESOURCE = gql`
  mutation UpdateResource_UpdateResource(
    $tenant: String!
    $resourceId: ID!
    $name: String!
    $scopes: [ScopeInput]
    $allowUsers: Boolean
  ) {
    updateResource(
      tenant: $tenant
      resource_id: $resourceId
      name: $name
      scopes: $scopes
      allowUsers: $allowUsers
    ) {
      resourceId: resource_id
      name
      identifier
      envId: environment_id
      scopes {
        value
        description
      }
      allowUsers
    }
  }
`;

const UpdateResource = ({
  envId,
  envIds,
  name,
  allowUsers,
  resourceId,
  scopes,
  tenant,
  onResourceUpdate,
}) => (
  <ModalState>
    {(modalOpen, toggleModal) => (
      <Fragment>
        <Message>
          <Message.Content>
            <Grid columns={2}>
              <Grid.Column>
                <Message.Header>Edit Resource</Message.Header>
                <Message.Content>Edit name or configure scopes for this resource.</Message.Content>
              </Grid.Column>
              <Grid.Column>
                <Message.Content>
                  <Button onClick={toggleModal} floated="right">
                    <Icon name="pencil" />
                    Edit
                  </Button>
                </Message.Content>
              </Grid.Column>
            </Grid>
          </Message.Content>
        </Message>

        {modalOpen && (
          <Mutation
            mutation={UPDATE_RESOURCE}
            refetchQueries={[
              {
                query: GET_ENV_RESOURCES,
                variables: { envs: envIds },
              },
            ]}
            awaitRefetchQueries
            onCompleted={({ updateResource }) => {
              onResourceUpdate({
                resourceId: updateResource.resourceId,
                allowUsers: updateResource.allowUsers,
                name: updateResource.name,
                identifier: updateResource.identifier,
                tenant: tenant,
                envId: envId,
                scopes: updateResource.scopes,
              });

              toggleModal();
              toast.success('Successfully updated resource!');
            }}
            onError={error =>
              toast.error(`Error encountered: ${getErrorMessages(error)}`, {
                autoClose: false,
              })
            }
          >
            {(updateResource, { loading, error }) => {
              const errors = getErrorMessages(error);

              return (
                <ModalForm
                  header={`Update Resource: ${name}`}
                  actionAttr={{
                    positive: true,
                    type: 'submit',
                    form: RESOURCE_FORM_ID,
                  }}
                  actionText="Update Resource"
                  errors={errors}
                  loading={loading}
                  open={modalOpen}
                  toggleModal={toggleModal}
                >
                  <UpdateResourceForm
                    errors={errors}
                    name={name}
                    resourceId={resourceId}
                    updateResource={updateResource}
                    scopes={scopes}
                    tenant={tenant}
                    allowUsers={allowUsers}
                  />
                </ModalForm>
              );
            }}
          </Mutation>
        )}
      </Fragment>
    )}
  </ModalState>
);

UpdateResource.propTypes = {
  envIds: PropTypes.arrayOf(PropTypes.string),
  envId: PropTypes.string,
  environments: PropTypes.arrayOf(PropTypes.object),
  name: PropTypes.string.isRequired,
  allowUsers: PropTypes.bool.isRequired,
  resourceId: PropTypes.string.isRequired,
  scopes: PropTypes.arrayOf(PropTypes.object),
  tenant: PropTypes.string,
  onResourceUpdate: PropTypes.func,
};

UpdateResource.defaultProps = {
  environments: [],
};

UpdateResource.displayName = 'UpdateResource';

export default UpdateResource;
