import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Button, Icon, Form } from 'semantic-ui-react';
import { Mutation } from 'react-apollo';
import { toast } from 'react-toastify';
import getErrorMessages from '../../util/errors';
import gql from 'graphql-tag';

import ModalState from '../../layouts/ModalState';
import ModalForm from '../../layouts/ModalForm';
import DisabledButtonPopup from '../../components/DisabledButtonPopup';

const DELETE_APP = gql`
  mutation DeleteApp_DeleteApp($id: ID!) {
    deleteApp(id: $id) {
      success
      error {
        message
        code
      }
    }
  }
`;

const GET_ALL_APPS = gql`
  query DeleteApp_GetApps {
    apps {
      id
      name
      description
      appType {
        id
        name
        description
      }
      team {
        id
        name
      }
      environments {
        id
        name
        url
        isProduction
      }
      members {
        id
      }
    }
  }
`;

const ME = gql`
  query DeleteApp_GetMe {
    me {
      id
      isAdmin
      firstName
      lastName
      email
      jobFamily
      hash
      teams {
        id
        name
        description
      }
    }
  }
`;

const DELETE_FORM_ID = 'delete-app-form';

class DeleteApp extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      appName: '',
    };
  }

  handleChange = (e, { name, value }) => this.setState({ [name]: value });

  clearAppName = () => this.setState({ appName: '' });

  handleDeleteApp = deleteApp => () => {
    const {
      app: { id },
    } = this.props;

    deleteApp({
      variables: {
        id,
      },
    });
  };

  handleMutationComplete = () => {
    const { history } = this.props;

    history.push('/apps');
  };

  render() {
    const { app } = this.props;
    const { appName } = this.state;

    return (
      <ModalState toggleCallback={this.clearAppName}>
        {(modalOpen, toggleModal) => (
          <Fragment>
            {app.environments.length > 0 ? (
              <DisabledButtonPopup
                content={`To delete this ${app.appType.name}, you must remove all of it's Environments first.`}
              />
            ) : (
              <Button id="delete-app-btn" size="tiny" basic color="red" onClick={toggleModal}>
                <Icon name="remove" />
                Delete
              </Button>
            )}
            {modalOpen && (
              <Mutation
                mutation={DELETE_APP}
                refetchQueries={[
                  {
                    query: GET_ALL_APPS,
                  },
                  {
                    query: ME,
                  },
                ]}
                awaitRefetchQueries
                onCompleted={() => {
                  this.handleMutationComplete();
                  toast.success('Successfully deleted app!');
                }}
                onError={error =>
                  toast.error(`Error encountered: ${getErrorMessages(error)}`, {
                    autoClose: false,
                  })
                }
              >
                {(deleteApp, { loading, error }) => {
                  const errors = getErrorMessages(error);

                  return (
                    <ModalForm
                      header={`Delete ${app.appType.name}: ${app.name}`}
                      actionAttr={{
                        negative: true,
                        type: 'submit',
                        form: DELETE_FORM_ID,
                      }}
                      actionText={`Delete ${app.appType.name}`}
                      actionDisabled={appName.toLowerCase() !== app.name.toLowerCase()}
                      loading={loading}
                      open={modalOpen}
                      toggleModal={toggleModal}
                      errors={errors}
                    >
                      <Form
                        id={DELETE_FORM_ID}
                        onSubmit={this.handleDeleteApp(deleteApp)}
                        loading={loading}
                        error={errors.length > 0}
                      >
                        <p>{` Are you sure you want to delete this ${app.appType.name}?`}</p>
                        <Form.Input
                          id="delete-app"
                          label={`Please type the name of the ${app.appType.name} to confirm:`}
                          name="appName"
                          value={appName}
                          onChange={this.handleChange}
                          autoFocus
                        />
                      </Form>
                    </ModalForm>
                  );
                }}
              </Mutation>
            )}
          </Fragment>
        )}
      </ModalState>
    );
  }
}

DeleteApp.propTypes = {
  app: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default withRouter(DeleteApp);
