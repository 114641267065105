import React from 'react';
import { Divider } from 'semantic-ui-react';
import PropTypes from 'prop-types';

interface IProps {
  children: Element;
  description: string;
}

const InputDescription = ({ children, description }: IProps) => (
  <div style={{ color: 'gray' }}>
    {description}
    {children}
    <Divider hidden />
  </div>
);

InputDescription.propTypes = {
  children: PropTypes.element,
  description: PropTypes.string,
};

InputDescription.defaultProps = {
  description: '',
  children: null,
};

InputDescription.displayName = 'InputDescription';

export default InputDescription;
