import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { toast } from 'react-toastify';
import { Card, Button, Label, Icon, Divider } from 'semantic-ui-react';
import { Mutation } from 'react-apollo';
import _ from 'lodash';

import ButtonLoader from '../../components/ButtonLoader';

import gql from 'graphql-tag';

import { requestTypeIds, statusColors, statusIcons } from '../../constants';
import getErrorMessages from '../../util/errors';

const APPROVE_AUTH_ROLE_REQUEST = gql`
  mutation Request_ApproveAuthRoleRequest($id: ID!) {
    approveAuthRoleRequest(id: $id) {
      success
      error {
        message
        code
      }
    }
  }
`;

const APPROVE_RESOURCE_REQUEST = gql`
  mutation Request_ApproveResourceRequest($id: ID!) {
    approveResourceRequest(id: $id) {
      success
      error {
        message
        code
      }
    }
  }
`;

const ME_INCOMING_REQUESTS_BY_STATUS = gql`
  query Request_GetMyIncomingRequestsByStatus($requestStatus: String = "") {
    me {
      id
      incomingResourceRequestsByStatus(requestStatus: $requestStatus) {
        id
        status {
          name
        }
        scopes
        resource {
          resourceId: resource_id
          name
          identifier
          scopes {
            name: value
            description
          }
        }
        resourceApp {
          id
          name
        }
        resourceEnvironment {
          name
          isProduction
        }
        client {
          clientId: client_id
          name
          description
        }
        clientApp {
          id
          name
        }
        clientEnvironment {
          name
          isProduction
        }
        requestor {
          id
          firstName
          lastName
          email
          jobFamily
        }
        requestTypeId
      }
    }
  }
`;

const REJECT_REQUEST = gql`
  mutation Request_RejectRequest($id: ID!) {
    rejectRequest(id: $id) {
      success
      error {
        message
        code
      }
      resourceRequest {
        id
        status {
          name
        }
        approver {
          id
        }
      }
    }
  }
`;

class Request extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonsDisabled: false,
    };
  }

  handleApproveRequest = approveResourceRequest => () => {
    this.setState({ buttonsDisabled: true });
    const {
      request: { id },
    } = this.props;
    approveResourceRequest({
      variables: {
        id,
      },
    });
  };

  handleRejectRequest = rejectResourceRequest => () => {
    this.setState({ buttonsDisabled: true });
    const {
      request: { id },
    } = this.props;
    rejectResourceRequest({
      variables: {
        id,
      },
    });
  };

  render() {
    const { request, outgoing } = this.props;
    const { buttonsDisabled } = this.state;

    return (
      <Card fluid>
        <Card.Content>
          {(outgoing || _.get(request, 'status.name') !== '') && (
            <Card.Header>
              <Label
                color={statusColors[_.get(request, 'status.name')]}
                content={_.get(request, 'status.name')}
                icon={statusIcons[_.get(request, 'status.name')]}
                ribbon
              />
            </Card.Header>
          )}
          <Card fluid centered>
            <Card.Content>
              <Card.Header>
                <Link to={`/app/${request.clientApp.id}`}>{request.clientApp.name}</Link>
              </Card.Header>
              <Card.Meta>
                {request.clientEnvironment.isProduction ? (
                  <Icon name="world" color="green" />
                ) : (
                  <Icon name="world" color="grey" />
                )}{' '}
                {request.resourceEnvironment.name} environment
              </Card.Meta>
              <Card.Description>Client Name: {request.client.name}</Card.Description>
            </Card.Content>
          </Card>
          <Divider horizontal> Requests Access To</Divider>
          <Card fluid centered>
            <Card.Content>
              <Card.Header>
                <Link to={`/app/${request.resourceApp.id}`}>{request.resourceApp.name}</Link>
              </Card.Header>
              <Card.Meta>
                {request.resourceEnvironment.isProduction ? (
                  <Icon name="world" color="green" />
                ) : (
                  <Icon name="world" color="grey" />
                )}{' '}
                {request.resourceEnvironment.name} environment
              </Card.Meta>
              <Card.Description>Resource Name: {request.resource.name}</Card.Description>
            </Card.Content>
            <Card.Content>
              Included Scopes:{' '}
              {_.get(request, 'scopes', []).length > 0 ? (
                _.get(request, 'scopes', []).map(scope => <Label key={scope}>{scope}</Label>)
              ) : (
                <i>None</i>
              )}
            </Card.Content>
          </Card>
        </Card.Content>
        {!outgoing && _.get(request, 'status.name') === 'pending' && (
          <Card.Content extra>
            <form>
              <div className="ui two buttons">
                <Mutation
                  mutation={
                    request.requestTypeId === requestTypeIds.AUTH_ROLE
                      ? APPROVE_AUTH_ROLE_REQUEST
                      : APPROVE_RESOURCE_REQUEST
                  }
                  refetchQueries={[
                    {
                      query: ME_INCOMING_REQUESTS_BY_STATUS,
                      variables: { requestStatus: 'approved' },
                    },
                  ]}
                  onCompleted={() => {
                    toast.success('Successfully approved request!');
                  }}
                  onError={error =>
                    toast.error(`Error encountered: ${getErrorMessages(error)}`, {
                      autoClose: false,
                    })
                  }
                >
                  {(approveResourceRequest, { loading, error }) => {
                    if (loading) return <ButtonLoader color="green" />;

                    if (error)
                      return (
                        <Button
                          basic
                          color="green"
                          icon="warning sign"
                          disabled={buttonsDisabled}
                        />
                      );

                    return (
                      <Button
                        basic
                        color="green"
                        type="submit"
                        disabled={buttonsDisabled}
                        onClick={this.handleApproveRequest(approveResourceRequest)}
                      >
                        Approve
                      </Button>
                    );
                  }}
                </Mutation>
                <Mutation
                  mutation={REJECT_REQUEST}
                  refetchQueries={[
                    {
                      query: ME_INCOMING_REQUESTS_BY_STATUS,
                      variables: { requestStatus: 'rejected' },
                    },
                  ]}
                  onCompleted={() => {
                    toast.success('Successfully rejected request!');
                  }}
                  onError={error =>
                    toast.error(`Error encountered: ${getErrorMessages(error)}`, {
                      autoClose: false,
                    })
                  }
                >
                  {(rejectRequest, { loading, error }) => {
                    if (loading) return <ButtonLoader color="red" />;

                    if (error)
                      return (
                        <Button basic color="red" icon="warning sign" disabled={buttonsDisabled} />
                      );

                    return (
                      <Button
                        basic
                        color="red"
                        type="submit"
                        disabled={buttonsDisabled}
                        onClick={this.handleRejectRequest(rejectRequest)}
                      >
                        Reject
                      </Button>
                    );
                  }}
                </Mutation>
              </div>
            </form>
          </Card.Content>
        )}
      </Card>
    );
  }
}

Request.propTypes = {
  request: PropTypes.shape({
    id: PropTypes.string.isRequired,
    status: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
    client: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
    clientApp: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string,
    }).isRequired,
    resourceApp: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
    resource: PropTypes.shape({
      name: PropTypes.string.isRequired,
      scopes: PropTypes.array,
    }).isRequired,
    resourceEnvironment: PropTypes.shape({
      name: PropTypes.string.isRequired,
      isProduction: PropTypes.bool.isRequired,
    }).isRequired,
    clientEnvironment: PropTypes.shape({
      name: PropTypes.string.isRequired,
      isProduction: PropTypes.bool.isRequired,
    }).isRequired,
  }).isRequired,
  outgoing: PropTypes.bool,
};

Request.defaultProps = {
  outgoing: false,
};

export default Request;
