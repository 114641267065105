import React, { FC } from 'react';
import { Table, Label, Header, Grid, Segment, Icon, Popup } from 'semantic-ui-react';
import { clipboardCopy } from '../util';

interface IProps {
  columns: Array<object>;
  data: Array<object>;
  headerText?: string;
  footerText?: string;
  noItemsMessage: string;
  loading?: boolean;
  placeholder?: React.ReactNode | null;
}

const DataGrid: FC<IProps> = props => {
  const { footerText, data, columns, headerText, noItemsMessage, loading, placeholder } = props;
  const unknownDataTypeMsg = 'Unknown data type';
  const numCols = columns.length;

  const getFormattedData = (cellData: any, dataType: string): React.ReactElement | string => {
    switch (dataType) {
      case 'string':
        return cellData.toString();
      case 'array':
        return cellData.map((cell: any) => {
          return <Label key={cell.name}>{cell.name}</Label>;
        });
      case 'arrayOfValues':
        return cellData.map((cell: any, i: number) => {
          return <Label key={`label-${i}`}>{cell}</Label>;
        });
      case 'copystring':
        return (
          <Label>
            <Label.Detail>{cellData}</Label.Detail>
            <Label.Detail>
              <Icon link name="clone outline" onClick={() => clipboardCopy(cellData)} />
            </Label.Detail>
          </Label>
        );
      case 'component':
        if (React.isValidElement(cellData)) {
          return cellData;
        }
        return unknownDataTypeMsg;
      default:
        return unknownDataTypeMsg;
    }
  };

  return (
    <Grid stackable doubling stretched>
      <Grid.Row>
        <Grid.Column>
          <Header as="h2">{headerText}</Header>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column>
          <Table colSpan={numCols}>
            <Table.Header>
              <Table.Row>
                {columns.map((column: any) => {
                  return (
                    <Table.HeaderCell key={column.name}>
                      {column.name}{' '}
                      {column.icon && (
                        <Popup
                          trigger={<Icon name={column.icon} circular color="grey" size="small" />}
                          content={column.iconText}
                        />
                      )}
                    </Table.HeaderCell>
                  );
                })}
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {!loading &&
                data.length > 0 &&
                data.map((row: any) => (
                  <Table.Row key={'row' + row.id}>
                    {columns.map((col: any) => {
                      return (
                        <Table.Cell key={'cell' + col.id}>
                          {getFormattedData(row[col.dataField], col.dataType)}
                        </Table.Cell>
                      );
                    })}
                  </Table.Row>
                ))}
              {!loading && !data.length && (
                <Table.Row>
                  <Table.Cell colSpan={numCols}>
                    <Segment>{noItemsMessage}</Segment>
                  </Table.Cell>
                </Table.Row>
              )}
              {loading && placeholder && (
                <Table.Row>
                  <Table.Cell colSpan={numCols}>
                    <Segment>{placeholder}</Segment>
                  </Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
            <Table.Footer>
              <Table.Row>
                <Table.HeaderCell style={{ textAlign: 'center' }} colSpan={numCols}>
                  {footerText}
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          </Table>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

DataGrid.defaultProps = {
  columns: [{}],
  data: [],
  headerText: '',
  footerText: '',
  noItemsMessage: 'No items found',
  loading: false,
  placeholder: null,
};

export default DataGrid;
