import _ from 'lodash';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useQuery } from 'react-apollo';
import { List, Header } from 'semantic-ui-react';
import Error from '../../components/Error';
import gql from 'graphql-tag';
import { registryIcons } from '../../constants';
import { Link } from 'react-router-dom';
import ContainerLoader from '../../components/ContainerLoader';
import styles from './index.module.css';
import { ROUTE_MAP } from '../../constants';
import { NormalizedResult, RawResult } from '../../types/search';

gql`
  input SearchOptions {
    indexName: String
    limit: Int
    fields: [String]
  }
`;

const SEARCH_TERM = gql`
  query SearchList_SearchAllIndexes($query: String!, $options: SearchOptions) {
    searchAllIndexes(query: $query, options: $options) {
      hits {
        index
        type
        id
        score
        title
        description
        key
      }
    }
  }
`;

type PathParamsType = {};

type PropsType = RouteComponentProps<PathParamsType> & {
  searchValue: string;
  filters: string[];
  queryLimit?: number;
  fields?: string[];
};

const getRouteName = (itemIndex: string, itemId: string) => {
  const routeType = (ROUTE_MAP as any)[itemIndex];
  return itemIndex ? `/${routeType}/${itemId}` : '/search';
};

const normalizeData = (data: any, filters: string[]) => {
  const callback = (filteredResults: NormalizedResult[], currentResult: RawResult) => {
    const resultIndex = currentResult.index;
    if (filters.includes(resultIndex.toLowerCase())) {
      // get correct icon
      const iconName = registryIcons[resultIndex].toString();

      const shortDescription = _.truncate(currentResult.description, {
        length: 70,
        separator: ' ,;.',
      });
      return [
        ...filteredResults,
        {
          ...currentResult,
          description: shortDescription,
          icon: iconName,
        },
      ];
    }
    return filteredResults;
  };
  return data.reduce(callback, []);
};

const renderResultsList = (searchItems: NormalizedResult[]) => {
  const searchList: any = [];
  searchItems.forEach(({ key, id, index, title, description }: NormalizedResult) => {
    searchList.push(
      <List.Item key={key}>
        <List.Icon name={registryIcons[index]} size="small" verticalAlign="middle" />
        <Link to={getRouteName(index, id)} key={`link-${id}`}>
          <List.Content>
            <List.Header className={styles.resultHeader} as="h5">
              {title}
            </List.Header>
            <List.Description>{description}</List.Description>
          </List.Content>
        </Link>
      </List.Item>
    );
  });
  return searchList;
};

const SearchList = ({ searchValue, fields, filters, queryLimit = 60 }: PropsType) => {
  const queryArgs = {
    variables: {
      query: searchValue,
      options: {
        limit: queryLimit,
        ...(fields && { fields: fields }),
      },
    },
    skip: !searchValue,
  };

  const { loading, error, data } = useQuery(SEARCH_TERM, queryArgs);
  if (loading) return <ContainerLoader />;
  if (error) {
    return (
      <Error error={error} header='"I&#39;ve been talking to the main computer... it hates me."' />
    );
  }

  // update our results set
  if (_.get(data, 'searchAllIndexes.hits', []).length > 0) {
    const rawResults: RawResult[] = data.searchAllIndexes.hits;

    // normalize the data
    const normalizedData: NormalizedResult[] = normalizeData(rawResults, filters);

    // add result count header
    return (
      <List relaxed="very">
        <Header as="h5">{`Found ${normalizedData.length} results${
          normalizedData.length > 4 ? '!' : '.'
        }`}</Header>
        {renderResultsList(normalizedData)}
      </List>
    );
  }
  return <ContainerLoader />;
};

export default SearchList;
