import React, { Fragment } from 'react';
import { Button, Popup, Icon } from 'semantic-ui-react';

interface IProps {
  trigger?: string;
}

declare global {
  /* eslint-disable-next-line */
  interface Window {
    showFeedback: Function;
  }
}

const FeedbackPopup = ({ trigger = '' }: IProps) => (
  <Popup
    style={{ maxWidth: 'none' }}
    trigger={
      trigger || (
        <div
          style={{
            display: 'flex',
            position: 'fixed',
            right: '.65em',
            width: '7rem',
            height: '3rem',
            bottom: '.45em',
            borderRadius: '30px',
            padding: ' 1.25em',
            backgroundColor: 'white',
            boxShadow: '0.1em 0.2em 1em 0px darkgrey',
            zIndex: 3,
          }}
        >
          <Button
            inverted
            color="green"
            size="tiny"
            id="feedbackBtn"
            style={{
              display: 'flex',
              position: 'fixed',
              right: '.5em',
              height: '3.5em',
              bottom: '.5em',
              borderRadius: '30px',
              padding: ' 1.25em',
            }}
          >
            <Icon name="bug" />
            Feedback
          </Button>
        </div>
      )
    }
    content={
      <Fragment>
        <p>Contact the Platform Tools Team:</p>
        <Button.Group>
          <Button as="a" href="mailto:platform-tools@redventures.com">
            <Icon name="mail" />
            Email
          </Button>
          <Button.Or />
          <Button href="https://redventures.slack.com/archives/C038CF1JAFK">
            <Icon name="slack" />
            Slack
          </Button>
        </Button.Group>
      </Fragment>
    }
    on="click"
  />
);

export default FeedbackPopup;
