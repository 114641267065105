import React, { FC, ReactElement } from 'react';
import { Icon, Grid } from 'semantic-ui-react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ReferenceLine,
} from 'recharts';
import '../../styles/rechart.css';

export interface IChartData {
  date: string;
  thisWeekTokens: string | number;
  lastWeekTokens: string | number;
  thisWeekDate: string;
  lastWeekDate: string;
  thisWeekCost: string | number;
  lastWeekCost: string | number;
  thisWeekUsage: boolean;
  lastWeekUsage: boolean;
  isHighUsage: boolean;
}

interface IProps {
  chartData: IChartData[];
  isHighUsage: boolean;
}

/**
 * Creates a custom tool tip for use in recharts chart
 *
 * @param {Object}
 *
 * @returns {JSX} HTML tooltip container
 */
export const customTooltip = ({
  payload,
  active,
}: {
  payload: {
    payload: {
      thisWeekDate: string;
      thisWeekUsage: boolean;
      thisWeekTokens: number;
      thisWeekCost: number;
      lastWeekDate: string;
      lastWeekUsage: boolean;
      lastWeekTokens: number;
      lastWeekCost: number;
    };
  }[];
  active: boolean;
}) => {
  if (payload === null) {
    return null;
  }
  if (active) {
    return (
      <div className="recharts-custom-tooltip">
        <p className="label" style={{ color: '#1778C2' }}>
          <b>{payload[0].payload.thisWeekDate}:</b>&nbsp;
          {payload[0].payload.thisWeekUsage && (
            <Icon name="exclamation triangle" color="red" />
          )}{' '}
          {payload[0].payload.thisWeekTokens} tokens ($
          {payload[0].payload.thisWeekCost})
        </p>
        <p className="label" style={{ color: '#6D2E46' }}>
          <b>{payload[0].payload.lastWeekDate}:</b>&nbsp;
          {payload[0].payload.lastWeekUsage && (
            <Icon name="exclamation triangle" color="red" />
          )}{' '}
          {payload[0].payload.lastWeekTokens} tokens ($
          {payload[0].payload.lastWeekCost})
        </p>
      </div>
    );
  }

  return null;
};

const UsageChart: FC<IProps> = ({ chartData, isHighUsage }: IProps): ReactElement => {
  return (
    <Grid padded columns={1}>
      <Grid.Row centered>
        <div style={{ width: '100%', height: 300 }}>
          <ResponsiveContainer>
            <LineChart
              data={chartData}
              margin={{
                top: 10,
                right: 30,
                left: 20,
                bottom: 10,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip content={customTooltip} />
              <Legend />
              <ReferenceLine y={18} label="Max" stroke="red" strokeDasharray="5 5" />
              <Line name="Last Week" type="monotone" dataKey="lastWeekTokens" stroke="#6D2E46" />
              <Line
                name="This Week"
                type="monotone"
                dataKey="thisWeekTokens"
                stroke="#1778C2"
                activeDot={{ r: 8 }}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </Grid.Row>
      <Grid.Row centered>
        {isHighUsage && (
          <p>
            <Icon name="exclamation triangle" color="red" />
            This client has experienced high token usage over the last 2 weeks. Read this{' '}
            <a href="https://arvy.io/news/effectively-caching-jwt-access-tokens-saves-us-heres-how">
              blog post
            </a>{' '}
            to learn how to fix this costly problem.
          </p>
        )}
      </Grid.Row>
    </Grid>
  );
};

export default UsageChart;
