import React from 'react';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';

import GridLayout from '../../layouts/GridLayout';
import AppDetail from './AppDetail';
import Error from '../../components/Error';
import ContainerLoader from '../../components/ContainerLoader';
import { Grid, Item, Icon } from 'semantic-ui-react';
import UpdateApp from './UpdateApp';
import DeleteApp from './DeleteApp';
import isMember from './selectors';
import DetailPane from '../../components/DetailPane';

const GET_APP = gql`
  query App_GetApp($id: ID!) {
    app(id: $id) {
      id
      name
      description
      repoUrl
      appType {
        id
        name
        description
        showRoles
        showEnvironmentsTab
        showClientTab
        authClientType
        showResourceTab
        showClientGrants
        showCallbacksField
        showLogoutURLsField
        showAllowedOriginsField
      }
      team {
        id
        name
        description
      }
      members {
        id
        firstName
        lastName
        email
        jobFamily
      }
      environments {
        id
        name
        url
        isProduction
        tenant
      }
    }
  }
`;

const App = ({
  match: {
    params: { id },
  },
  me,
}) => {
  return (
    <GridLayout>
      <Query query={GET_APP} variables={{ id }}>
        {({ loading, error, data }) => {
          if (loading) return <ContainerLoader />;

          if (error) {
            return <Error header="This isn't right." error={error} />;
          }

          const meIsMember = isMember(data.app, me.id);
          const meIsAdmin = me.isAdmin;
          const environments = data.app.environments.map(({ name, isProduction }) => ({
            text: name,
            subtext: isProduction ? 'Production' : 'Non-Production',
            iconColor: isProduction ? 'green' : 'grey',
          }));

          const team = {
            text: data.app.team.name,
            subtext: data.app.team.description,
            linkTo: `/team/${data.app.team.id}`,
          };

          data.app.subtext = (
            <a href={`${data.app.repoUrl}`} target="_blank" rel="noopener noreferrer">
              {data.app.repoUrl}
            </a>
          );
          data.app.text = 'Repo';

          const detailPaneContent = {
            extra: data.app.appType.name,
            description: data.app.description,
            descriptionTitle: 'Description',
            lists: [
              {
                listTitle: 'Environments',
                listItems: environments,
                listIcon: 'world',
              },
              {
                listTitle: 'Team',
                listItems: [team],
              },
              {
                listTitle: 'Extras',
                listItems: [data.app],
              },
            ],
          };

          return (
            <Grid>
              <Grid.Row>
                <Grid.Column>
                  <Item.Header
                    style={{
                      fontWeight: 'bold',
                      fontSize: '2em',
                      marginBottom: '.5em',
                    }}
                  >
                    <Icon name="snowflake" size="large" color="grey" />
                    <p>{data.app.name}</p>
                  </Item.Header>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row style={{ wordWrap: 'word-break', hyphens: 'auto' }}>
                <Grid.Column width={3}>
                  <DetailPane content={detailPaneContent} />
                  {(meIsMember || meIsAdmin) && (
                    <div style={{ marginBottom: 20 }}>
                      <UpdateApp app={data.app} />
                      <DeleteApp app={data.app} />
                    </div>
                  )}
                </Grid.Column>
                <Grid.Column width={13}>
                  <AppDetail app={data.app} me={me} />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          );
        }}
      </Query>
    </GridLayout>
  );
};

App.displayName = 'App';
App.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  me: PropTypes.object.isRequired,
};

export default App;
