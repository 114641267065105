/* eslint-disable react/destructuring-assignment */
import React, { Component, Fragment } from 'react';
import { toast } from 'react-toastify';
import { Card, Icon, Header, Button, Confirm } from 'semantic-ui-react';
import { Query, Mutation } from 'react-apollo';
import _ from 'lodash';

import getErrorMessages from '../../util/errors';
import gql from 'graphql-tag';

import { statusColors, statusIcons } from '../../constants';
import ChooseResourceScopes from './ChooseResourceScopes';
import Error from '../Error';

const CREATE_RESOURCE_REQUEST = gql`
  mutation ApprovedClientGrants_CreateResourceRequest(
    $audience: String!
    $clientGrantId: ID
    $clientId: ID!
    $resourceId: ID!
    $scope: [String]
    $tenant: String!
  ) {
    createResourceRequest(
      audience: $audience
      client_id: $clientId
      clientGrantId: $clientGrantId
      resource_id: $resourceId
      scope: $scope
      tenant: $tenant
    ) {
      success
      error {
        message
        code
      }
    }
  }
`;

const DELETE_CLIENT_GRANT = gql`
  mutation ApprovedClientGrants_DeleteClientGrants(
    $tenant: String!
    $id: ID!
    $resourceId: ID!
    $clientId: ID!
  ) {
    deleteClientGrant(tenant: $tenant, id: $id, resource_id: $resourceId, client_id: $clientId) {
      success
      error {
        message
        code
      }
    }
  }
`;

const GET_CLIENT_GRANTS_BY_CLIENT_ID = gql`
  query ApprovedClientGrants_GetClientGrants($tenant: String!, $clientId: ID!, $audience: String) {
    clientGrants(tenant: $tenant, client_id: $clientId, audience: $audience) {
      id
      audience
      clientId: client_id
      scope
      tenant
      resource {
        resourceId: resource_id
      }
      status
    }
  }
`;

const GET_PENDING_CLIENT_GRANTS = gql`
  query ApprovedClientGrants_GetPendingClientGrants($clientId: ID!) {
    pendingClientGrants(client_id: $clientId) {
      id
      audience
      clientId: client_id
      scope
      tenant
      resource {
        resourceId: resource_id
      }
      status
    }
  }
`;

const UPDATE_CLIENT_GRANT = gql`
  mutation ApprovedClientGrants_UpdateClientGrants(
    $tenant: String!
    $id: ID!
    $scope: [String]
    $clientId: ID!
  ) {
    updateClientGrant(tenant: $tenant, id: $id, scope: $scope, clientId: $clientId) {
      success
      error {
        message
        code
      }
    }
  }
`;

class ApprovedClientGrants extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isConfirmVisible: false,
    };
  }

  handleScopeEdit = ({ data, resourceId, scope }) => {
    const idx = scope.indexOf(data.value);
    const updatedScopes = [...scope];

    if (idx === -1) {
      updatedScopes.push(data.value);
    } else {
      updatedScopes.splice(idx, 1);
    }

    this.setState(() => ({
      [resourceId]: {
        editing: true,
        scopes: updatedScopes,
      },
    }));
  };

  handleEditResource = resourceId => {
    this.setState(() => ({
      [resourceId]: {
        editing: true,
      },
    }));
  };

  handleConfirmCancel = () => {
    this.setState({ isConfirmVisible: false });
  };

  handleConfirmConfirm = () => {
    const { confirmCallback, confirmCallbackArgs } = this.state;

    this.setState({ isConfirmVisible: false }, () => {
      confirmCallback(confirmCallbackArgs);
    });
  };

  handleCancel = resourceId => {
    this.setState(() => ({
      [resourceId]: {
        editing: false,
      },
    }));
  };

  handleSave = clientGrantData => {
    const {
      audience,
      clientGrantId,
      clientId,
      resourceId,
      scope,
      tenant,
      requestHandler,
      clientGrantHandler,
    } = clientGrantData;

    const editedScope = _.get(this.state[resourceId], 'scopes', scope);

    const canUpdateClientGrant = editedScope.every(s => scope.includes(s));

    // if the user only subtracted scopes then an approval request isn't necessary
    if (canUpdateClientGrant) {
      clientGrantHandler({
        variables: {
          tenant,
          id: clientGrantId,
          scope: editedScope,
          clientId,
        },
      });
    } else {
      requestHandler({
        variables: {
          audience,
          clientGrantId,
          clientId,
          resourceId,
          scope: editedScope,
          tenant,
        },
      });
    }

    // clear the editing state
    this.setState(() => ({
      [resourceId]: { editing: false },
    }));
  };
  render() {
    const { clientId, tenant, meIsOwner } = this.props;

    const { isConfirmVisible } = this.state;

    return (
      <Query query={GET_CLIENT_GRANTS_BY_CLIENT_ID} variables={{ clientId, tenant }}>
        {({ loading, error, data }) => {
          if (loading) return <p>Loading...</p>;
          if (error) return <Error error={error} />;

          return data.clientGrants.map(
            ({ audience, clientId, id: clientGrantId, resource, scope, status, tenant }) => {
              const { resourceId } = resource;
              const isEditing = _.get(this.state[resourceId], 'editing', false);
              const editedScopes = _.get(this.state[resourceId], 'scopes', scope);
              return (
                <Card key={clientGrantId}>
                  <Card.Content extra>
                    {isEditing ? (
                      <Header sub>
                        <Icon color="blue" name="edit" />
                        Editing
                      </Header>
                    ) : (
                      <Header sub>
                        <Icon color={statusColors[status]} name={statusIcons[status]} />
                        {status}
                      </Header>
                    )}
                  </Card.Content>
                  <Confirm
                    open={isConfirmVisible}
                    content="Removing this clientGrant will prevent your client from accessing this resource. Are you sure you want to continue?"
                    onCancel={this.handleConfirmCancel}
                    onConfirm={this.handleConfirmConfirm}
                  />
                  <Mutation
                    mutation={UPDATE_CLIENT_GRANT}
                    refetchQueries={[
                      {
                        query: GET_CLIENT_GRANTS_BY_CLIENT_ID,
                        variables: { clientId, tenant },
                      },
                    ]}
                    onCompleted={() => {
                      toast.success('Successfully updated client grant.');
                    }}
                    onError={err => toast.error(`Error encountered: ${getErrorMessages(err)}`)}
                  >
                    {updateClientGrant => (
                      <Mutation
                        mutation={CREATE_RESOURCE_REQUEST}
                        refetchQueries={[
                          {
                            query: GET_CLIENT_GRANTS_BY_CLIENT_ID,
                            variables: { clientId, tenant },
                          },
                          {
                            query: GET_PENDING_CLIENT_GRANTS,
                            variables: { clientId, tenant },
                          },
                        ]}
                        onCompleted={() => {
                          toast.success('Successfully created resource request.');
                        }}
                        onError={err => toast.error(`Error encountered: ${getErrorMessages(err)}`)}
                      >
                        {createResourceRequest => (
                          <Fragment>
                            <Card.Content>
                              <ChooseResourceScopes
                                disabled={!isEditing || !meIsOwner}
                                tenant={tenant}
                                onClick={(e, data) =>
                                  this.handleScopeEdit({
                                    resourceId,
                                    scope: editedScopes,
                                    data,
                                  })
                                }
                                resourceId={resource.resourceId}
                                selectedScopes={editedScopes.map(s => ({
                                  value: s,
                                }))}
                              />
                            </Card.Content>
                            {meIsOwner && (
                              <Card.Content extra>
                                {isEditing ? (
                                  <Button.Group widths="2">
                                    <Button
                                      basic
                                      color="red"
                                      onClick={() => this.handleCancel(resource.resourceId)}
                                    >
                                      <Icon name="cancel" />
                                      Cancel
                                    </Button>
                                    <Button
                                      basic
                                      color="blue"
                                      onClick={() =>
                                        this.handleSave({
                                          audience,
                                          clientGrantId,
                                          clientId,
                                          resourceId,
                                          tenant,
                                          requestHandler: createResourceRequest,
                                          clientGrantHandler: updateClientGrant,
                                          scope,
                                        })
                                      }
                                    >
                                      <Icon name="save" />
                                      Save
                                    </Button>
                                  </Button.Group>
                                ) : (
                                  <Button.Group basic widths="2">
                                    <Mutation
                                      key={clientGrantId}
                                      mutation={DELETE_CLIENT_GRANT}
                                      refetchQueries={[
                                        {
                                          query: GET_CLIENT_GRANTS_BY_CLIENT_ID,
                                          variables: { clientId, tenant },
                                        },
                                      ]}
                                      onCompleted={() => {
                                        toast.success('Successfully deleted client grant.');
                                      }}
                                      onError={err =>
                                        toast.error(`Error encountered: ${getErrorMessages(err)}`)
                                      }
                                    >
                                      {deleteClientGrant => (
                                        <Button
                                          basic
                                          onClick={() =>
                                            this.setState({
                                              isConfirmVisible: true,
                                              confirmCallback: deleteClientGrant,
                                              confirmCallbackArgs: {
                                                variables: {
                                                  id: clientGrantId,
                                                  resourceId: resource.resourceId,
                                                  tenant,
                                                  clientId,
                                                },
                                              },
                                            })
                                          }
                                        >
                                          <Icon name="trash" />
                                          Remove
                                        </Button>
                                      )}
                                    </Mutation>
                                    <Button
                                      basic
                                      color="green"
                                      onClick={() => this.handleEditResource(resource.resourceId)}
                                    >
                                      <Icon name="edit" />
                                      Edit
                                    </Button>
                                  </Button.Group>
                                )}
                              </Card.Content>
                            )}
                          </Fragment>
                        )}
                      </Mutation>
                    )}
                  </Mutation>
                </Card>
              );
            }
          );
        }}
      </Query>
    );
  }
}

export default ApprovedClientGrants;
