import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Gravatar from './Gravatar';
import { Item, Icon, Button, ButtonProps } from 'semantic-ui-react';

interface IProps {
  items: {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    jobFamily: string;
    isAdmin: boolean;
  }[];
}

class UserListItem extends Component<IProps> {
  handleIconClick = (e: any, { email }: ButtonProps) => {
    if (email) {
      window.location.href = 'mailto:' + email;
    }
  };

  render() {
    const { items } = this.props;

    return items.map(({ id, firstName, lastName, email, isAdmin }) => (
      <Item
        id={id}
        key={`member-${id}`}
        style={{
          border: '1px solid #d4d4d5',
          margin: '.5em .5em ',
          padding: '.5em',
          borderRadius: '.28571429rem',
        }}
      >
        <Item.Image size={'tiny'}>
          <Gravatar email={email} rounded bordered />
        </Item.Image>
        <Item.Content style={{ display: 'inline-block' }}>
          <Item.Description as={Link} to={`/person/${id}`}>
            {`${firstName} ${lastName}`}
          </Item.Description>
          <Item.Meta>{`${isAdmin}` ? 'Admin' : 'User'}</Item.Meta>
          <Item.Extra>
            <Button size="mini" onClick={this.handleIconClick} email={email} icon>
              <Icon name={'mail'} />
            </Button>
          </Item.Extra>
        </Item.Content>
      </Item>
    ));
  }

  static propTypes = {
    items: PropTypes.arrayOf(PropTypes.object),
  };

  static defaultProps = {
    items: [],
  };
}

export default UserListItem;
