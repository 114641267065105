import React, { Component, SyntheticEvent } from 'react';
import PropTypes from 'prop-types';
import { Button, Card, Icon, Header } from 'semantic-ui-react';
import { Query } from 'react-apollo';
import _ from 'lodash';

import ChooseResourceScopes from './ChooseResourceScopes';
import ChooseResource from './ChooseResource';
import gql from 'graphql-tag';
import Error from '../Error';

const GET_CLIENT_GRANTS_BY_CLIENT_ID = gql`
  query AddClientGrants_GetClientGrants($tenant: String!, $clientId: ID!, $audience: String) {
    clientGrants(tenant: $tenant, client_id: $clientId, audience: $audience) {
      id
      audience
      clientId: client_id
      scope
      tenant
      resource {
        resourceId: resource_id
      }
      status
    }
  }
`;

interface IState {
  resourceId: string | null;
  audience: string;
  scope: string[];
}

interface IProps {
  onSave: Function;
  clientId: string;
  envId: string;
  tenant: string;
}

class AddClientGrants extends Component<IProps, IState> {
  state = {
    audience: '',
    resourceId: '',
    scope: [],
  };

  handleResourceChange = (e: SyntheticEvent, { value }: { value: string }) => {
    try {
      const { resourceId, identifier } = JSON.parse(value);

      this.setState({ resourceId, audience: identifier, scope: [] });
    } catch (e) {
      // console.error('problem parsing ', value);
    }
  };

  handleScopeEdit = (e: Error, { value }: { value: any }) => {
    this.setState(({ scope }) => {
      const updatedScopes = [...scope];

      if (scope.includes(value)) {
        updatedScopes.splice(scope.indexOf(value), 1);
      } else {
        updatedScopes.push(value);
      }

      return { scope: updatedScopes };
    });
  };

  handleSave = () => {
    const { onSave } = this.props;
    const { audience, scope, resourceId } = this.state;

    onSave({ audience, scope, resourceId });
    this.reset();
  };

  reset = () => {
    this.setState({ resourceId: null, scope: [] });
  };

  render() {
    const { clientId, envId, tenant } = this.props;
    const { resourceId, scope } = this.state;

    return (
      <Query<any, any> query={GET_CLIENT_GRANTS_BY_CLIENT_ID} variables={{ clientId, tenant }}>
        {({ loading, error, data }) => {
          if (loading) return <p>Loading...</p>;
          if (error) return <Error error={error} />;

          const existingResources = _.get(data, 'clientGrants', []).reduce(
            (acc: any[], r: { status: string; resource: any }) => {
              ['approved', 'pending'].includes(r.status) &&
                acc.push(_.get(r.resource, 'resourceId'));
              return acc;
            },
            []
          );

          return (
            <Card>
              <Card.Content extra>
                <Header sub>
                  <Icon color={'blue'} name={'star'} />
                  New Request
                </Header>
              </Card.Content>
              <Card.Content>
                <ChooseResource
                  envId={envId}
                  excludedResources={existingResources}
                  onChange={this.handleResourceChange}
                />
                {resourceId && (
                  <ChooseResourceScopes
                    onClick={this.handleScopeEdit}
                    resourceId={resourceId}
                    selectedScopes={scope && scope.map(s => ({ value: s }))}
                    tenant={tenant}
                  />
                )}
              </Card.Content>
              <Card.Content extra>
                <Button
                  id="request-resource-button"
                  basic
                  color="blue"
                  fluid
                  onClick={this.handleSave}
                >
                  <Icon name="save" />
                  Request
                </Button>
              </Card.Content>
            </Card>
          );
        }}
      </Query>
    );
  }

  static propTypes = {
    clientId: PropTypes.string.isRequired,
    envId: PropTypes.string.isRequired,
    onSave: PropTypes.func,
    tenant: PropTypes.string.isRequired,
  };

  static defaultProps = {
    onSave: () => {},
  };
}

export default AddClientGrants;
