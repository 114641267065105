import React, { FC, ReactElement, Fragment } from 'react';
import { useQuery } from 'react-apollo';
import { Label } from 'semantic-ui-react';
import gql from 'graphql-tag';

interface IClient {
  clientId: string;
  tokenCost: {
    isHighUsage: boolean;
  };
}

interface IMe {
  me: {
    id: string;
    highUsageClients: IClient[];
  };
}

const ME_NOTIFICATIONS = gql`
  query HighTokenUsageBadge_GetMyHighUsageClients {
    me {
      id
      highUsageClients {
        clientId: client_id
      }
    }
  }
`;

interface IProps {
  circular?: boolean | undefined;
  floating?: 'right' | 'left' | false | undefined;
  size?: 'big' | 'small' | 'tiny' | 'mini' | 'medium' | 'large' | 'huge' | 'massive' | undefined;
}

const HighTokenUsageBadge: FC<IProps> = ({
  circular = true,
  floating = 'right',
  size = 'tiny',
}: IProps): ReactElement => {
  const { loading, error, data } = useQuery<IMe>(ME_NOTIFICATIONS);

  if (loading) return <Fragment />;
  if (error || !data) return <Fragment />;

  const { me } = data;

  const notifCount = me.highUsageClients.length;
  if (!notifCount) return <Fragment />;

  if (!floating) {
    return <Label size={size} circular={circular} color="red" content={notifCount} />;
  }

  if (floating === 'right') {
    return <Label size={size} floating circular={circular} color="red" content={notifCount} />;
  }

  // float left
  return (
    <Label
      size={size}
      style={{
        position: 'absolute',
        zIndex: 100,
        top: '-1em',
        right: '50%',
        margin: '0 0 0 -1.5em!important',
      }}
      circular={circular}
      color="red"
      content={notifCount}
    />
  );
};

export default HighTokenUsageBadge;
