import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Icon, Input, Card as SemanticCard } from 'semantic-ui-react';

import CreateApp from '../app/CreateApp';
import DetailPaneContent from '../../components/DetailPane';
import AppCard from '../../components/Card/AppCard';

import { useQuery } from 'react-apollo';
import PageLoader from '../../components/PageLoader';
import Error from '../../components/Error';
import gql from 'graphql-tag';

const GET_APP_TYPES = gql`
  query AppsDetail_GetAppTypes {
    appTypes {
      id
      name
      description
    }
  }
`;

const AppsDetail = ({ apps, me, defaultTeam, showCreateApp }) => {
  const initialState = {
    filterValue: '',
    filteredData: apps,
  };
  const [state, setState] = useState(initialState);

  useEffect(() => {
    /*
      this function runs initially on mount but
      doesn't run again until the `apps` array from props is updated

      helpful for keeping any apps from props in sync with state
    */
    setState({ ...state, filteredData: apps });
  }, [apps]);

  const handleFilterChange = (e, { value }) => {
    const term = value.toLowerCase();

    setState(prevState => ({
      ...prevState,
      filteredData: apps.filter(
        app =>
          app.name.toLowerCase().indexOf(term) > -1 ||
          app.description.toLowerCase().indexOf(term) > -1 ||
          app.appType.name.toLowerCase().indexOf(term) > -1
      ),
      filterValue: term,
    }));
  };

  const clearFilter = () => {
    setState(initialState);
  };

  const { loading, error, data } = useQuery(GET_APP_TYPES);

  const getAppTypes = () => {
    if (loading) {
      return <PageLoader />;
    }
    if (error) {
      return <Error error={error} />;
    }

    const detailPaneContent = {
      description: 'An application is a user-facing client or a backend API.',
      title: 'Applications',
      descriptionTitle: 'definition',
      lists: [
        {
          listTitle: 'Types of Applications',
          listItems: data.appTypes.map(appType => {
            return {
              key: appType.id,
              text: appType.name,
              subtext: appType.description,
            };
          }),
        },
      ],
    };

    return <DetailPaneContent content={detailPaneContent} />;
  };

  const { filterValue, filteredData } = state;

  return (
    <Grid stackable>
      <Grid.Column width={11} floated="left">
        {showCreateApp && <CreateApp me={me} defaultTeam={defaultTeam} />}
      </Grid.Column>
      <Grid.Column floated="right" width={5}>
        <Input
          fluid
          icon={
            filterValue === '' ? (
              <Icon name="search" />
            ) : (
              <Icon name="close" link onClick={clearFilter} />
            )
          }
          placeholder="Filter"
          value={filterValue}
          onChange={handleFilterChange}
        />
      </Grid.Column>
      <Grid.Column width={3} floated="left">
        {getAppTypes()}
      </Grid.Column>
      <Grid.Column width={13}>
        <SemanticCard.Group itemsPerRow={4} stackable>
          {filteredData.map(app => (
            <AppCard app={app} key={`card-${app.id}`} />
          ))}
        </SemanticCard.Group>
      </Grid.Column>
    </Grid>
  );
};

AppsDetail.propTypes = {
  me: PropTypes.object.isRequired,
  apps: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      team: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      }).isRequired,
    })
  ).isRequired,
  defaultTeam: PropTypes.object,
  showCreateApp: PropTypes.bool,
};
AppsDetail.defaultProps = {
  defaultTeam: null,
  showCreateApp: true,
};

export default AppsDetail;
