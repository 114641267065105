import React from 'react';
import Card from '.';

interface IApp {
  id: string;
  name: string;
  description: string;
  appType: {
    id: string;
    name: string;
  };
  team?: {
    id: string;
    name: string;
  };
}

const AppCard = ({ app }: { app: IApp }) => (
  <Card
    key={app.id}
    title={app.name}
    details={[app.team && app.team.name, app.appType.name]}
    linkto={`/app/${app.id}`}
  />
);

export default AppCard;
