import React, { FC, ReactElement } from 'react';
import { Icon, StatisticGroup, Statistic, SemanticCOLORS, Popup } from 'semantic-ui-react';

export enum AllowedRatings {
  LOW,
  MEDIUM,
  HIGH,
  ALARM,
}

interface IProps {
  isHighUsage: boolean;
  estimatedYearlyCost: number;
  rating: AllowedRatings;
  totalTokens: number;
  highUsageDays: number;
}

const UsageStats: FC<IProps> = ({
  isHighUsage,
  estimatedYearlyCost,
  rating,
  totalTokens,
  highUsageDays,
}: IProps): ReactElement => {
  const ratingConfig = () => {
    const green: SemanticCOLORS = 'green';
    const orange: SemanticCOLORS = 'orange';
    const red: SemanticCOLORS = 'red';
    const yellow: SemanticCOLORS = 'yellow';

    if (rating === AllowedRatings.MEDIUM) {
      return {
        color: yellow,
        value: 'Medium',
      };
    }

    if (rating === AllowedRatings.HIGH) {
      return {
        color: orange,
        value: 'High',
      };
    }

    if (rating === AllowedRatings.ALARM) {
      return {
        color: red,
        value: 'Alarm',
      };
    }

    return {
      color: green,
      value: 'Low',
    };
  };
  return (
    <StatisticGroup size="mini" widths={4}>
      <Statistic color={ratingConfig().color}>
        <Statistic.Value>{ratingConfig().value}</Statistic.Value>
        <Statistic.Label>Average Usage Rating</Statistic.Label>
      </Statistic>
      <Statistic>
        <Statistic.Value>
          ${estimatedYearlyCost}{' '}
          <Popup size="large" trigger={<Icon corner="top right" name="info circle" />}>
            <Popup.Header>How much should it cost?</Popup.Header>
            <Popup.Content>
              Access tokens are valid for 24 hours by default, so clients should need only{' '}
              <b>1 token per day</b> for each resource they connect to. At a cost of around{' '}
              <b>$.0034 per token</b>, most clients need less than{' '}
              <b>$5 worth of tokens each year</b>!
            </Popup.Content>
          </Popup>
        </Statistic.Value>
        <Statistic.Label>Estimated Yearly Cost</Statistic.Label>
      </Statistic>
      <Statistic>
        <Statistic.Value>{totalTokens}</Statistic.Value>
        <Statistic.Label>Total Bi-Weekly Tokens</Statistic.Label>
      </Statistic>
      <Statistic>
        <Statistic.Value>
          {isHighUsage && <Icon name="exclamation triangle" color="red" />} {highUsageDays}
        </Statistic.Value>
        <Statistic.Label>High Usage Days</Statistic.Label>
      </Statistic>
    </StatisticGroup>
  );
};

export default UsageStats;
