import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Icon, Dimmer, Loader } from 'semantic-ui-react';
import { Mutation } from 'react-apollo';
import { toast } from 'react-toastify';
import gql from 'graphql-tag';
import getErrorMessages from '../../util/errors';

import ModalState from '../../layouts/ModalState';
import ModalForm from '../../layouts/ModalForm';

const DELETE_ENV = gql`
  mutation DeleteEnv_DeleteEnv($id: ID!) {
    deleteEnvironment(id: $id) {
      success
      error {
        message
        code
      }
    }
  }
`;

const GET_APP = gql`
  query DeleteEnv_GetApp($id: ID!) {
    app(id: $id) {
      id
      name
      description
      repoUrl
      appType {
        id
        name
        description
        showRoles
        showEnvironmentsTab
        showClientTab
        authClientType
        showResourceTab
        showClientGrants
        showCallbacksField
        showLogoutURLsField
        showAllowedOriginsField
      }
      team {
        id
        name
        description
      }
      members {
        id
        firstName
        lastName
        email
        jobFamily
      }
      environments {
        id
        name
        url
        isProduction
        tenant
      }
    }
  }
`;

class DeleteEnv extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleDeleteEnv = deleteEnv => () => {
    const { envId: id } = this.props;

    deleteEnv({
      variables: {
        id,
      },
    });
  };

  render() {
    const { appId, name } = this.props;

    return (
      <ModalState>
        {(modalOpen, toggleModal) => (
          <Fragment>
            <Icon id="deleteEnvBtn" link name="remove" onClick={toggleModal} />
            {modalOpen && (
              <Mutation
                mutation={DELETE_ENV}
                refetchQueries={[
                  {
                    query: GET_APP,
                    variables: { id: appId },
                  },
                ]}
                awaitRefetchQueries
                onCompleted={() => {
                  toggleModal();
                  toast.success('Successfully deleted environment!');
                }}
                onError={error =>
                  toast.error(`Error encountered: ${getErrorMessages(error)}`, {
                    autoClose: false,
                  })
                }
              >
                {(deleteEnv, { loading, error }) => {
                  const errors = getErrorMessages(error);

                  return (
                    <ModalForm
                      header={`Delete Environment: ${name}`}
                      actionAttr={{
                        negative: true,
                        onClick: this.handleDeleteEnv(deleteEnv),
                      }}
                      actionText="Delete Environment"
                      loading={loading}
                      open={modalOpen}
                      toggleModal={toggleModal}
                      errors={errors}
                    >
                      <Dimmer.Dimmable>
                        {loading && (
                          <Dimmer active inverted>
                            <Loader inverted />
                          </Dimmer>
                        )}
                        <p>Are you sure you want to delete this environment?</p>
                      </Dimmer.Dimmable>
                    </ModalForm>
                  );
                }}
              </Mutation>
            )}
          </Fragment>
        )}
      </ModalState>
    );
  }
}

DeleteEnv.propTypes = {
  appId: PropTypes.string.isRequired,
  envId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
};

export default DeleteEnv;
