import React, { Fragment, FC } from 'react';
import { Grid, Divider, Container } from 'semantic-ui-react';
import { useQuery } from 'react-apollo';
import gql from 'graphql-tag';

import Error from '../Error';
import PageLoader from '../PageLoader';
import { IClient } from './ClientUsage';
import NoUsageMessage from './NoUsageMessage';
import UsageStats from './UsageStats';
import UsageChart from './UsageChart';

const GET_CLIENT_USAGE = gql`
  query ClientUsageDetail_GetClientUsage($clientId: ID!, $tenant: String!) {
    client(client_id: $clientId, tenant: $tenant) {
      clientId: client_id
      envId: environment_id
      name
      tenant
      tokenCost {
        chartData {
          date
          thisWeekTokens
          lastWeekTokens
          thisWeekDate
          lastWeekDate
          thisWeekCost
          lastWeekCost
          thisWeekUsage
          lastWeekUsage
          isHighUsage
        }
        estimatedYearlyCost
        isHighUsage
        rating
        highUsageDays
        totalTokens
      }
    }
  }
`;

interface IProps {
  clientId: string;
  client: IClient;
}

const ClientUsageDetail: FC<IProps> = ({ clientId, client }) => {
  const { loading, error, data } = useQuery<{ client: IClient }>(GET_CLIENT_USAGE, {
    variables: { clientId, tenant: client.tenant },
  });

  if (loading) {
    return (
      <Grid.Row>
        <PageLoader inline />
      </Grid.Row>
    );
  }

  if (error) {
    return <Error header="An error occurred while fetching your token usage" error={error} />;
  }

  if (!data) {
    return <Fragment />;
  }

  const { tokenCost } = data?.client;
  const { tenant } = client;

  // if we don't get any data, don't show any data.
  if (!tokenCost) {
    return <NoUsageMessage clientId={clientId} tenant={tenant} />;
  }

  const {
    chartData,
    isHighUsage,
    estimatedYearlyCost,
    rating,
    totalTokens,
    highUsageDays,
  } = tokenCost;

  return (
    <Container fluid key={`pane-${clientId}`}>
      <Divider hidden />
      <UsageStats
        estimatedYearlyCost={estimatedYearlyCost}
        rating={rating}
        totalTokens={totalTokens}
        highUsageDays={highUsageDays}
        isHighUsage={isHighUsage}
      />
      <Divider hidden />
      <UsageChart chartData={chartData} isHighUsage={isHighUsage} />
    </Container>
  );
};

export default ClientUsageDetail;
