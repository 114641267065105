import React from 'react';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';

import QueryDropdown from './QueryDropdown';
import { getGravatarUrl } from './Gravatar';

const GET_ALL_USERS = gql`
  query UsersDropdown_GetUsers {
    users {
      id
      isAdmin
      firstName
      lastName
      email
      jobFamily
    }
  }
`;

interface IProps {
  id: string;
  placeholder: string;
  label: string;
  name: string;
  value: any[];
  onChange(): any;
}

const optionsFn = (data: any) => {
  let options = [];

  if (data.users) {
    options = data.users.map((user: any) => ({
      text: `${user.firstName} ${user.lastName}`,
      id: user.id,
      value: user.id,
      image: {
        avatar: true,
        src: getGravatarUrl(user.email),
      },
      description: `${user.email}`,
    }));
  }

  return options;
};

const UsersDropdown = (props: IProps) => (
  <QueryDropdown
    query={GET_ALL_USERS}
    optionsFn={optionsFn}
    noResultsMessage="No users found."
    multiple
    required
    {...props}
  />
);

UsersDropdown.propTypes = {
  id: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default UsersDropdown;
