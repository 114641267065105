import React, { Component, Fragment } from 'react';
import { List, Container, Icon, Tab, Divider, Label, Menu } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import isMember from './selectors';
import CreateEnv from './CreateEnv';
import UpdateEnv from './UpdateEnv';
import DeleteEnv from './DeleteEnv';
import UsageTab from './tabs/Usage';
import ResourceTab from '../../components/AuthResources/tabs/AuthResourceTab';
import AppAccordion from '../../components/AppAccordion';
import ClientTab from './tabs/Client';
import AdminTab from './tabs/Admin';
import EmptyResults from '../../components/EmptyResults';

class AppDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { app, me } = this.props;
    const meIsMember = isMember(app, me.id);
    const meIsAdmin = me.isAdmin;
    const accordionItems = [
      {
        accordionDetails: '',
        accordionTitle: 'What is an Environment?',
        accordionItemText: [
          {
            key: 'resource-env',
            title: 'Environments',
            details:
              'are used to identify the location in which the resource being accessed is located',
          },
          {
            key: 'client-env',
            title: 'Environments',
            details: 'are also used to identify the location in which a client is located.',
          },
        ],
      },
      {
        accordionDetails: '',
        accordionTitle: 'When should I mark an Environment as Production?',
        accordionItemText: [
          {
            key: 'prod-env',
            title: '',
            details: 'When the environment is using production data',
          },
          {
            key: 'source-prod-env',
            title: '',
            details: 'When communicating with sources that handle production data',
          },
        ],
      },
    ];

    const panes = [
      {
        menuItem: { key: 'admins', icon: 'key', content: 'Admins' },
        render: () => <AdminTab members={app.members} />,
      },
    ];

    app.appType.showEnvironmentsTab &&
      panes.push({
        menuItem: {
          id: 'environments-tab',
          key: 'environments',
          icon: 'world',
          content: 'Environments',
        },
        render: () => (
          <Fragment>
            <AppAccordion id="apps" accordionItems={accordionItems} />
            <Divider hidden />
            {(meIsMember || meIsAdmin) && <CreateEnv {...app} />}
            <List id="app-environments" relaxed divided>
              {app.environments &&
                app.environments.map(({ id, name, url, isProduction }) => (
                  <List.Item id={id} key={`env-${id}`}>
                    {(meIsMember || meIsAdmin) && (
                      <List.Content floated="right">
                        <DeleteEnv envId={id} appId={app.id} name={name} />
                        <UpdateEnv
                          envId={id}
                          appId={app.id}
                          name={name}
                          url={url}
                          isProduction={isProduction}
                        />
                      </List.Content>
                    )}
                    <Icon
                      name="world"
                      color={isProduction ? 'green' : 'grey'}
                      size="large"
                      style={{ verticalAlign: 'middle' }}
                    />
                    <List.Content>
                      <List.Header>{name}</List.Header>
                      <List.Description>
                        {isProduction ? 'Production' : 'Non-Production'}
                      </List.Description>
                      <List.Item>
                        <a href={`${url}`} target="_blank" rel="noopener noreferrer">
                          {url}
                        </a>
                      </List.Item>
                    </List.Content>
                  </List.Item>
                ))}
            </List>
            {!app.environments.length > 0 && <EmptyResults />}
          </Fragment>
        ),
      });

    app.appType.showClientTab &&
      panes.push({
        menuItem: {
          id: 'auth-clients-tab',
          key: 'authClients',
          icon: 'sitemap',
          content: 'Auth Clients',
        },
        render: () => (
          <ClientTab
            appId={app.id}
            appName={app.name}
            appDesc={app.description}
            appType={app.appType}
            environments={app.environments}
            meIsOwner={meIsMember || me.isAdmin}
          />
        ),
      });

    app.appType.showResourceTab &&
      panes.push({
        menuItem: {
          id: 'auth-resources-tab',
          key: 'authResources',
          icon: 'lock',
          content: 'Auth Resources',
        },
        render: () => (
          <ResourceTab
            appId={app.id}
            appName={app.name}
            environments={app.environments}
            meIsOwner={meIsMember || me.isAdmin}
          />
        ),
      });

    app.appType.showClientTab &&
      app.appType.authClientType === 'non_interactive' &&
      panes.push({
        menuItem: (
          <Menu.Item key="tokenusage">
            <Icon name="chart line" />
            Token Usage
            <Label color="blue">New!</Label>
          </Menu.Item>
        ),
        render: () => (
          <UsageTab appId={app.id} appName={app.name} environments={app.environments} />
        ),
      });

    return (
      <Container fluid id="app-detail">
        <Tab menu={{ secondary: true, pointing: true }} panes={panes} />
      </Container>
    );
  }
}

AppDetail.propTypes = {
  app: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    repoUrl: PropTypes.string.isRequired,
    appType: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      authClientType: PropTypes.string.isRequired,
      showEnvironmentsTab: PropTypes.bool.isRequired,
      showClientTab: PropTypes.bool.isRequired,
      showResourceTab: PropTypes.bool.isRequired,
    }).isRequired,
    team: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
    members: PropTypes.array.isRequired,
    environments: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        url: PropTypes.string,
        isProduction: PropTypes.bool.isRequired,
      }).isRequired
    ).isRequired,
  }).isRequired,
  me: PropTypes.object.isRequired,
};

export default AppDetail;
