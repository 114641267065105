import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Button, Icon } from 'semantic-ui-react';
import { Mutation } from 'react-apollo';
import { toast } from 'react-toastify';
import gql from 'graphql-tag';
import getErrorMessages from '../../util/errors';

import ModalForm from '../../layouts/ModalForm';
import ModalState from '../../layouts/ModalState';
import CreateEnvForm, { CREATE_ENV_FORM_ID } from './CreateEnvForm';

const CREATE_ENV = gql`
  mutation CreateEnv_CreateEnv(
    $appId: ID!
    $name: String!
    $description: String!
    $url: String!
    $isProduction: Boolean!
  ) {
    addEnvironment(
      appId: $appId
      environment: {
        name: $name
        description: $description
        url: $url
        isProduction: $isProduction
      }
    ) {
      success
      error {
        message
        code
      }
      environment {
        id
      }
    }
  }
`;

const GET_APP = gql`
  query CreateEnv_GetApp($id: ID!) {
    app(id: $id) {
      id
      name
      description
      repoUrl
      appType {
        id
        name
        description
        showRoles
        showEnvironmentsTab
        showClientTab
        authClientType
        showResourceTab
        showClientGrants
        showCallbacksField
        showLogoutURLsField
        showAllowedOriginsField
      }
      team {
        id
        name
        description
      }
      members {
        id
        firstName
        lastName
        email
        jobFamily
      }
      environments {
        id
        name
        url
        isProduction
        tenant
      }
    }
  }
`;

class CreateEnv extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { id } = this.props;

    return (
      <ModalState>
        {(modalOpen, toggleModal) => (
          <Fragment>
            <Button id="add-app-environment" primary onClick={toggleModal}>
              <Icon name="add" />
              Register an Environment
            </Button>
            {modalOpen && (
              <Mutation
                mutation={CREATE_ENV}
                refetchQueries={[
                  {
                    query: GET_APP,
                    variables: { id },
                  },
                ]}
                awaitRefetchQueries
                onCompleted={() => {
                  toggleModal();
                  toast.success('Successfully added environment!');
                }}
                onError={error =>
                  toast.error(`Error encountered: ${getErrorMessages(error)}`, {
                    autoClose: false,
                  })
                }
              >
                {(addEnvironment, { loading, error }) => {
                  const errors = getErrorMessages(error);

                  return (
                    <ModalForm
                      header="Register an Environment"
                      actionAttr={{
                        positive: true,
                        type: 'submit',
                        form: CREATE_ENV_FORM_ID,
                      }}
                      loading={loading}
                      open={modalOpen}
                      toggleModal={toggleModal}
                      errors={errors}
                      actionText="Register Environment"
                    >
                      <CreateEnvForm
                        addEnvironment={addEnvironment}
                        id={id}
                        loading={loading}
                        errors={errors}
                      />
                    </ModalForm>
                  );
                }}
              </Mutation>
            )}
          </Fragment>
        )}
      </ModalState>
    );
  }
}

CreateEnv.propTypes = {
  id: PropTypes.string.isRequired,
};

export default CreateEnv;
