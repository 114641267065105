import React from 'react';
import PropTypes from 'prop-types';
import { Loader } from 'semantic-ui-react';
import styles from './PageLoader.module.css';
import { SemanticSIZES } from 'semantic-ui-react/dist/commonjs/generic';

interface IProps {
  size: SemanticSIZES;
  content: string;
  inline: boolean;
}

const PageLoader = ({ size, content, inline }: IProps) => (
  <div className={styles.loaderPage}>
    <Loader active inline={inline} size={size} content={content} />
  </div>
);

PageLoader.propTypes = {
  size: PropTypes.oneOf(['mini', 'tiny', 'small', 'medium', 'large', 'big', 'huge', 'massive']),
  content: PropTypes.string,
  inline: PropTypes.oneOf(['centered', true, false]),
};
PageLoader.defaultProps = {
  size: 'huge',
  content: null,
  inline: false,
};

export default PageLoader;
