/* eslint-disable react/destructuring-assignment */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { Button, Card, Confirm, Divider, Icon, Message, Header } from 'semantic-ui-react';
import { Query, Mutation } from 'react-apollo';
import _ from 'lodash';

import ChooseResourcePermissions from './ChooseResourcePermissions';
import AddUserAuth from './AddUserAuth';
import gql from 'graphql-tag';
import getErrorMessages from '../../util/errors';
import { statusColors, statusIcons } from '../../constants';
import Error from '../Error';

const CANCEL_REQUEST = gql`
  mutation ManageUserAuth_CancelRequest($id: ID!) {
    cancelRequest(id: $id) {
      success
      error {
        message
        code
      }
    }
  }
`;

const CREATE_AUTH_ROLE_REQUEST = gql`
  mutation ManageUserAuth_CreateAuthRoleRequest(
    $clientId: ID!
    $resourceId: ID!
    $description: String!
    $permissions: [PermissionInput]
    $roleId: ID
    $tenant: String!
    $users: [AuthUserInput]
  ) {
    createAuthRoleRequest(
      client_id: $clientId
      resource_id: $resourceId
      description: $description
      permissions: $permissions
      role_id: $roleId
      tenant: $tenant
      users: $users
    ) {
      success
      error {
        message
        code
      }
    }
  }
`;

const GET_AUTH_ROLE_USERS = gql`
  query ManageUserAuth_GetAuthRoleUsers($tenant: String!, $roleId: ID!) {
    authRoleUsers(tenant: $tenant, role_id: $roleId) {
      email
      name
      user_id
    }
  }
`;

const GET_AUTH_ROLES = gql`
  query ManageUserAuth_GetAuthRoles($tenant: String!, $clientId: ID!) {
    authRoles(tenant: $tenant, client_id: $clientId) {
      role_id
      name
      description
      permissions {
        description
        name: permission_name
      }
      status
    }
  }
`;

const DELETE_AUTH_ROLE = gql`
  mutation ManageUserAuth_DeleteAuthRole($tenant: String!, $clientId: ID!, $roleId: ID!) {
    deleteAuthRole(tenant: $tenant, client_id: $clientId, role_id: $roleId) {
      success
      error {
        message
        code
      }
    }
  }
`;

const GET_PENDING_AUTH_ROLES = gql`
  query ManageUserAuth_GetPendingAuthRoles($clientId: ID!) {
    pendingAuthRoles(client_id: $clientId) {
      id
      role_id
      name
      description
      permissions {
        description
        name: permission_name
      }
      status
      tenant
      users {
        email
      }
    }
  }
`;

const ME = gql`
  query ManageUserAuth_GetMe {
    me {
      id
      isAdmin
      firstName
      lastName
      email
      jobFamily
      hash
      teams {
        id
        name
        description
      }
    }
  }
`;

const UPDATE_AUTH_ROLE = gql`
  mutation ManageUserAuth_UpdateAuthRole(
    $roleId: ID!
    $permissions: [PermissionInput]
    $tenant: String!
    $clientId: ID!
    $resourceId: ID!
    $users: [AuthUserInput]
  ) {
    updateAuthRole(
      role_id: $roleId
      tenant: $tenant
      permissions: $permissions
      client_id: $clientId
      resource_id: $resourceId
      users: $users
    ) {
      role_id
      permissions {
        description
        name: permission_name
      }
    }
  }
`;

class ManageUserAuth extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isConfirmVisible: false,
      permissionsEdited: false,
      usersEdited: false,
    };
  }

  handleEditAuthRole = (resourceId, uid) => {
    this.setState(() => ({
      [`${resourceId}::${uid}`]: {
        editing: true,
      },
    }));
  };

  handlePermissionEdit = ({ data, resourceId, uid, permissions }) => {
    const idx = permissions.findIndex(({ name }) => name === data.value);
    const updatedPermissions = [...permissions];

    if (idx === -1) {
      updatedPermissions.push({
        name: data.value,
        resourceId: data.resourceId,
      });
    } else {
      updatedPermissions.splice(idx, 1);
    }

    this.setState(() => ({
      [`${resourceId}::${uid}`]: {
        editing: true,
        permissions: updatedPermissions,
      },
      permissionsEdited: true,
    }));
  };

  handleUserEdit = users => {
    this.setState({ users, usersEdited: true });
  };

  handleCancel = (resourceId, uid) => {
    this.setState(() => ({
      [`${resourceId}::${uid}`]: {
        editing: false,
      },
    }));
  };

  handleSave = authRoleData => {
    const {
      roleId,
      clientId,
      resourceId,
      uid,
      permissions,
      tenant,
      requestHandler,
      authRoleHandler,
      users = [],
    } = authRoleData;

    const { permissionsEdited, usersEdited } = this.state;
    const editedPermissions = _.get(this.state[`${resourceId}::${uid}`], 'permissions', []).map(
      ({ name, description }) => ({
        resource_id: resourceId,
        permission_name: name,
        description,
      })
    );

    const canUpdateAuthRole =
      editedPermissions.every(({ permission_name }) =>
        permissions.find(({ name }) => permission_name === name)
      ) && !!roleId;

    const formattedUsers = users.map(({ user_id, email }) => ({
      user_id,
      email,
    }));

    // if the user only subtracted permissions then an approval request isn't necessary
    if (canUpdateAuthRole) {
      authRoleHandler({
        variables: {
          roleId,
          tenant,
          permissions: permissionsEdited ? editedPermissions : null,
          clientId,
          resourceId,
          uid,
          users: usersEdited ? formattedUsers : null,
        },
      });
    } else {
      requestHandler({
        variables: {
          clientId,
          resourceId,
          uid,
          description: 'created with love by marvin',
          permissions: permissionsEdited ? editedPermissions : null,
          roleId,
          tenant,
          users: usersEdited ? formattedUsers : null,
        },
      });
    }

    // clear the editing state
    this.setState(() => ({
      [`${resourceId}::${uid}`]: { editing: false },
    }));
  };

  handleConfirmCancel = () => {
    this.setState({ isConfirmVisible: false });
  };

  handleConfirmConfirm = () => {
    const { confirmCallback, confirmCallbackArgs } = this.state;

    this.setState({ isConfirmVisible: false }, () => {
      confirmCallback(confirmCallbackArgs);
    });
  };

  showConfirm = ({ confirmCallback, confirmCallbackArgs }) => {
    this.setState({
      isConfirmVisible: true,
      confirmCallback,
      confirmCallbackArgs,
    });
  };

  render() {
    const { clientId, tenant, envId, meIsOwner } = this.props;
    const { isConfirmVisible, users } = this.state;

    return (
      <Fragment>
        <Confirm
          open={isConfirmVisible}
          content="Removing this role will prevent your SPA from accessing this resource. Are you sure you want to continue?"
          onCancel={this.handleConfirmCancel}
          onConfirm={this.handleConfirmConfirm}
        />
        {meIsOwner && (
          <Mutation
            mutation={CREATE_AUTH_ROLE_REQUEST}
            refetchQueries={[
              {
                query: GET_AUTH_ROLES,
                variables: { clientId, tenant },
              },
              {
                query: GET_PENDING_AUTH_ROLES,
                variables: { clientId },
              },
              {
                query: ME,
              },
            ]}
            onCompleted={() => {
              toast.success('Successfully requested access to resource.');
            }}
            onError={error =>
              toast.error(`Error encountered: ${getErrorMessages(error)}`, {
                autoClose: false,
              })
            }
          >
            {(createAuthRoleRequest, { loading }) => {
              if (loading) return <p>Loading...</p>;

              return (
                <AddUserAuth
                  clientId={clientId}
                  tenant={tenant}
                  envId={envId}
                  onSave={({ permissions, resourceId, users }) => {
                    const formattedPermissions = permissions.map(({ value }) => ({
                      permission_name: value,
                    }));
                    createAuthRoleRequest({
                      variables: {
                        clientId,
                        description: 'created with love by marvin',
                        resourceId,
                        tenant,
                        permissions: formattedPermissions,
                        users,
                      },
                    });
                  }}
                />
              );
            }}
          </Mutation>
        )}
        <Divider hidden />
        <Card.Group>
          <Query query={GET_PENDING_AUTH_ROLES} variables={{ clientId }}>
            {({ loading, error, data }) => {
              if (loading) return <p>Loading...</p>;
              if (error) return <Error error={error} />;

              return data.pendingAuthRoles.map(
                ({ id, name = '', permissions, status, tenant, users }) => (
                  <Card key={`column-${id}`}>
                    <Card.Content extra>
                      <Header sub>
                        <Icon color={statusColors[status]} name={statusIcons[status]} />
                        Status is {status}
                      </Header>
                    </Card.Content>
                    <Card.Content>
                      <Mutation
                        mutation={CREATE_AUTH_ROLE_REQUEST}
                        refetchQueries={[
                          {
                            query: GET_AUTH_ROLES,
                            variables: { clientId, tenant },
                          },
                        ]}
                        onCompleted={() => {
                          toast.success('Successfully created resource request.');
                        }}
                        onError={err => toast.error(`Error encountered: ${getErrorMessages(err)}`)}
                      >
                        {(createAuthRoleRequest, { loading, error }) => {
                          if (loading) return <p>Loading...</p>;
                          if (error) return <Error error={error} />;
                          const [, resourceId, uid] = name.split('::');

                          return (
                            <ChooseResourcePermissions
                              disabled={status === 'pending'}
                              tenant={tenant}
                              onPermissionChange={(e, data) =>
                                this.handlePermissionEdit({
                                  data,
                                  permissions,
                                  resourceId,
                                  uid,
                                })
                              }
                              onUserChange={this.handleUserEdit}
                              resourceId={resourceId}
                              selectedPermissions={permissions.map(({ name }) => ({
                                value: name,
                              }))}
                              showRemove={false}
                              users={users}
                            />
                          );
                        }}
                      </Mutation>
                    </Card.Content>
                    <Card.Content extra>
                      <Mutation
                        mutation={CANCEL_REQUEST}
                        refetchQueries={[
                          {
                            query: GET_PENDING_AUTH_ROLES,
                            variables: { clientId, tenant },
                          },
                        ]}
                        onCompleted={() => {
                          toast.success('Successfully cancelled resource request.');
                        }}
                        onError={err => toast.error(`Error encountered: ${getErrorMessages(err)}`)}
                      >
                        {cancelResourceRequest => (
                          <Button
                            basic
                            color={statusColors.cancel}
                            fluid
                            onClick={() =>
                              this.showConfirm({
                                confirmCallback: cancelResourceRequest,
                                confirmCallbackArgs: {
                                  variables: { id },
                                },
                              })
                            }
                          >
                            <Icon name="trash" />
                            Cancel
                          </Button>
                        )}
                      </Mutation>
                    </Card.Content>
                    <Message icon warning attached="bottom">
                      <Icon name="warning sign" />
                      Please contact the admin(s) of this resource directly to request approval.
                    </Message>
                  </Card>
                )
              );
            }}
          </Query>
          <Query query={GET_AUTH_ROLES} variables={{ clientId, tenant }}>
            {({ loading, error, data }) => {
              if (loading) return <p>Loading...</p>;
              if (error) return <Error error={error} />;

              return data.authRoles.map(
                ({ description, name, permissions, role_id: roleId, status }) => {
                  const [clientId, resourceId, uid] = name.split('::');
                  const isEditing = _.get(this.state[`${resourceId}::${uid}`], 'editing', false);
                  const editedPermissions = _.get(
                    this.state[`${resourceId}::${uid}`],
                    'permissions',
                    permissions
                  );
                  return (
                    <Card key={roleId}>
                      <Card.Content extra>
                        {isEditing ? (
                          <Header sub>
                            <Icon color="blue" name="edit" />
                            Editing
                          </Header>
                        ) : (
                          <Header sub>
                            <Icon color={statusColors[status]} name={statusIcons[status]} />
                            Status is {status}
                          </Header>
                        )}
                      </Card.Content>
                      <Mutation
                        mutation={UPDATE_AUTH_ROLE}
                        refetchQueries={[
                          {
                            query: GET_AUTH_ROLES,
                            variables: { clientId, tenant },
                          },
                          {
                            query: GET_AUTH_ROLE_USERS,
                            variables: { roleId, tenant },
                          },
                        ]}
                        onCompleted={() => {
                          toast.success('Successfully updated auth role.');
                        }}
                        onError={err => toast.error(`Error encountered: ${getErrorMessages(err)}`)}
                      >
                        {updateAuthRole => (
                          <Mutation
                            mutation={CREATE_AUTH_ROLE_REQUEST}
                            refetchQueries={[
                              {
                                query: GET_AUTH_ROLES,
                                variables: { clientId, tenant },
                              },
                              {
                                query: GET_PENDING_AUTH_ROLES,
                                variables: { clientId, tenant },
                              },
                              {
                                query: GET_AUTH_ROLE_USERS,
                                variables: { roleId, tenant },
                              },
                            ]}
                            onCompleted={() => {
                              toast.success('Successfully created auth role request.');
                            }}
                            onError={err =>
                              toast.error(`Error encountered: ${getErrorMessages(err)}`)
                            }
                          >
                            {createAuthRoleRequest => (
                              <Fragment>
                                <Card.Content>
                                  <ChooseResourcePermissions
                                    disabled={!isEditing}
                                    tenant={tenant}
                                    onPermissionChange={(e, data) =>
                                      this.handlePermissionEdit({
                                        resourceId,
                                        uid,
                                        permissions: editedPermissions,
                                        data,
                                      })
                                    }
                                    onUserChange={this.handleUserEdit}
                                    resourceId={resourceId}
                                    roleId={roleId}
                                    selectedPermissions={editedPermissions.map(({ name }) => ({
                                      value: name,
                                    }))}
                                    showRemove={false}
                                  />
                                </Card.Content>
                                {isEditing ? (
                                  <Card.Content extra>
                                    <Button.Group widths="2">
                                      <Button
                                        basic
                                        color="red"
                                        onClick={() => this.handleCancel(resourceId, uid)}
                                      >
                                        <Icon name="cancel" />
                                        Cancel
                                      </Button>
                                      <Button
                                        basic
                                        color="blue"
                                        onClick={() =>
                                          this.handleSave({
                                            clientId,
                                            resourceId,
                                            uid,
                                            description,
                                            tenant,
                                            requestHandler: createAuthRoleRequest,
                                            authRoleHandler: updateAuthRole,
                                            permissions,
                                            roleId,
                                            users,
                                          })
                                        }
                                      >
                                        <Icon name="save" />
                                        Save
                                      </Button>
                                    </Button.Group>
                                  </Card.Content>
                                ) : (
                                  <Card.Content extra>
                                    <Button.Group basic widths="2">
                                      <Mutation
                                        key={name}
                                        mutation={DELETE_AUTH_ROLE}
                                        refetchQueries={[
                                          {
                                            query: GET_AUTH_ROLES,
                                            variables: { clientId, tenant },
                                          },
                                        ]}
                                        onCompleted={() => {
                                          toast.success('Successfully deleted auth role.');
                                        }}
                                        onError={err =>
                                          toast.error(`Error encountered: ${getErrorMessages(err)}`)
                                        }
                                      >
                                        {deleteAuthRole => (
                                          <Button
                                            basic
                                            onClick={() =>
                                              this.showConfirm({
                                                confirmCallback: deleteAuthRole,
                                                confirmCallbackArgs: {
                                                  variables: {
                                                    tenant,
                                                    clientId,
                                                    roleId,
                                                  },
                                                },
                                              })
                                            }
                                          >
                                            <Icon name="trash" />
                                            Remove
                                          </Button>
                                        )}
                                      </Mutation>
                                      <Button
                                        basic
                                        color="green"
                                        onClick={() => this.handleEditAuthRole(resourceId, uid)}
                                      >
                                        <Icon name="edit" />
                                        Edit
                                      </Button>
                                    </Button.Group>
                                  </Card.Content>
                                )}
                              </Fragment>
                            )}
                          </Mutation>
                        )}
                      </Mutation>
                    </Card>
                  );
                }
              );
            }}
          </Query>
        </Card.Group>
      </Fragment>
    );
  }
}

ManageUserAuth.displayName = 'ManageUserAuth';

ManageUserAuth.propTypes = {
  tenant: PropTypes.string.isRequired,
  clientId: PropTypes.string.isRequired,
  envId: PropTypes.string.isRequired,
};

ManageUserAuth.defaultProps = {};

export default ManageUserAuth;
