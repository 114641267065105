import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Button, Dimmer, Icon, Loader, Message, Grid } from 'semantic-ui-react';
import { Mutation } from 'react-apollo';
import { toast } from 'react-toastify';
import getErrorMessages from '../../util/errors';
import gql from 'graphql-tag';
import ModalState from '../../layouts/ModalState';
import ModalForm from '../../layouts/ModalForm';

const DELETE_RESOURCE = gql`
  mutation DeleteResource_DeleteResource($resourceId: ID!, $tenant: String!, $identifier: String!) {
    deleteResource(resource_id: $resourceId, tenant: $tenant, identifier: $identifier) {
      error {
        message
        code
      }
      success
    }
  }
`;

const GET_ENV_RESOURCES = gql`
  query DeleteResource_GetEnvResources($envs: [ID]!) {
    envResources(envs: $envs) {
      resourceId: resource_id
      allowUsers
      name
      identifier
      tenant
      envId: environment_id
      app {
        id
        name
      }
      scopes {
        value
        description
      }
    }
  }
`;

class DeleteResource extends Component {
  handleDeleteResource = (deleteResource, resourceId, tenant, identifier) => {
    deleteResource({
      variables: {
        identifier,
        resourceId,
        tenant,
      },
    });
  };

  render() {
    const { envIds, clients, identifier, name, resourceId, tenant, onResourceUpdate } = this.props;

    return (
      <ModalState>
        {(modalOpen, toggleModal) => (
          <Fragment>
            <Message>
              <Message.Content>
                <Grid columns={2}>
                  <Grid.Column>
                    <Message.Header>Delete Resource</Message.Header>
                    <Message.Content>Permanently delete this resource.</Message.Content>
                  </Grid.Column>
                  <Grid.Column>
                    <Message.Content>
                      <Button color="red" onClick={toggleModal} floated="right">
                        <Icon name="trash" />
                        Delete
                      </Button>
                    </Message.Content>
                  </Grid.Column>
                </Grid>
              </Message.Content>
            </Message>
            {modalOpen && (
              <Mutation
                mutation={DELETE_RESOURCE}
                refetchQueries={[
                  {
                    query: GET_ENV_RESOURCES,
                    variables: { envs: envIds },
                  },
                ]}
                awaitRefetchQueries
                onCompleted={() => {
                  toggleModal();
                  onResourceUpdate(null);
                  toast.success('Successfully deleted resource!');
                }}
                onError={error =>
                  toast.error(`Error encountered: ${getErrorMessages(error)}`, {
                    autoClose: false,
                  })
                }
              >
                {(deleteResource, { loading, error }) => {
                  const errors = getErrorMessages(error);

                  return (
                    <ModalForm
                      header={`Delete Resource: ${name}`}
                      actionAttr={{
                        negative: true,
                        onClick: () =>
                          this.handleDeleteResource(deleteResource, resourceId, tenant, identifier),
                      }}
                      actionText="Delete Resource"
                      errors={errors}
                      loading={loading}
                      open={modalOpen}
                      toggleModal={toggleModal}
                    >
                      <Dimmer.Dimmable>
                        {loading && (
                          <Dimmer active inverted>
                            <Loader inverted />
                          </Dimmer>
                        )}
                        <Message negative>
                          <Message.Header>You cannot undo this step.</Message.Header>
                        </Message>
                        {!!clients.length && (
                          <p>
                            Clients {clients.join(', ')} will no longer be able to access this
                            resource.
                          </p>
                        )}
                        Are you sure you want to delete the <strong>{name}</strong> resource?{' '}
                      </Dimmer.Dimmable>
                    </ModalForm>
                  );
                }}
              </Mutation>
            )}
          </Fragment>
        )}
      </ModalState>
    );
  }
}

DeleteResource.propTypes = {
  envIds: PropTypes.arrayOf(PropTypes.string),
  envId: PropTypes.string,
  tenant: PropTypes.string,
  identifier: PropTypes.string,
  environments: PropTypes.arrayOf(PropTypes.object),
  name: PropTypes.string.isRequired,
  resourceId: PropTypes.string.isRequired,
  clients: PropTypes.arrayOf(PropTypes.string),
  onResourceUpdate: PropTypes.func,
};

DeleteResource.defaultProps = {
  environments: [],
  clients: [],
};

DeleteResource.displayName = 'DeleteResource';

export default DeleteResource;
