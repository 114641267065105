import React, { ReactNode } from 'react';
import PropTypes from 'prop-types';
import { Form, FormDropdownProps } from 'semantic-ui-react';

import QueryField from './QueryField';
import { DocumentNode } from 'graphql';

interface IPropsDropdown {
  id: string;
  label: string;
  options: any;
  placeholder?: string;
  noResultsMessage?: string | ReactNode;
  loading: boolean;
}

interface IPropsQueryDropdown extends FormDropdownProps {
  clearable?: boolean;
  query: DocumentNode;
  variables?: any;
  multiple?: any;
  optionsFn: Function;
  id: string;
  label: string;
  required?: boolean;
}

const Dropdown = ({ id, label, options, placeholder, ...props }: IPropsDropdown) => (
  <Form.Dropdown
    placeholder={placeholder}
    label={{ children: label, htmlFor: id }}
    fluid
    search
    selection
    options={options}
    closeOnChange
    selectOnBlur={false}
    searchInput={{ id }}
    {...props}
  />
);

const QueryDropdown = ({
  query,
  variables,
  optionsFn,
  id,
  label,
  ...props
}: IPropsQueryDropdown) => (
  <QueryField query={query} label={label} variables={variables}>
    {(loading: any, data: any) => {
      if (loading) return <Form.Dropdown loading placeholder="Loading - Please wait..." />;

      const options = optionsFn(data);
      return <Dropdown options={options} loading={loading} id={id} label={label} {...props} />;
    }}
  </QueryField>
);

QueryDropdown.propTypes = {
  query: PropTypes.object.isRequired,
  optionsFn: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.string]).isRequired,
  noResultsMessage: PropTypes.string,
};

QueryDropdown.defaultProps = {
  noResultsMessage: 'No results found.',
};

export default QueryDropdown;
