import React, { Component, Fragment, SyntheticEvent } from 'react';
import PropTypes from 'prop-types';
import { Button, Card, Icon } from 'semantic-ui-react';
import { Query } from 'react-apollo';
import _ from 'lodash';

import ChooseResource from './ChooseResource';
import gql from 'graphql-tag';
import Error from '../Error';
import ChooseResourcePermissions from './ChooseResourcePermissions';

const GET_AUTH_ROLES = gql`
  query AddUserAuth_GetAuthRoles($tenant: String!, $clientId: ID!) {
    authRoles(tenant: $tenant, client_id: $clientId) {
      role_id
      name
      description
      permissions {
        description
        name: permission_name
      }
      status
    }
  }
`;

interface IProps {
  clientId: string;
  envId: string;
  onSave: Function;
  tenant: string;
}

interface IState {
  audience?: string;
  description?: string;
  permissions?: any[];
  resourceId?: string | null;
  users: string[];
}

class AddUserAuth extends Component<IProps, IState> {
  state = {
    description: '',
    users: [],
    permissions: [],
    resourceId: null,
  };

  handleResourceChange = (e: SyntheticEvent, { value }: { value: string }) => {
    try {
      const { resourceId, identifier } = JSON.parse(value);

      this.setState({ resourceId, audience: identifier, permissions: [] });
    } catch (e) {
      // console.error('problem parsing ', value);
    }
  };

  handlePermissionEdit = (e: Error, { value }: { value: string }) => {
    this.setState(({ permissions = [] }) => {
      const updatedPermissions = [...permissions];
      const permissionIdx = permissions.findIndex(({ value: v }) => v === value);

      if (permissionIdx !== -1) {
        updatedPermissions.splice(permissions.indexOf(permissionIdx), 1);
      } else {
        updatedPermissions.push({ value });
      }

      return { permissions: updatedPermissions };
    });
  };

  handleUserEdit = (users: string[]) => {
    this.setState({ users });
  };

  handleSave = () => {
    const { onSave } = this.props;
    const { description, permissions, resourceId, users } = this.state;
    onSave({ description, permissions, resourceId, users });
    this.reset();
  };

  reset = () => {
    this.setState({ resourceId: null, permissions: [], users: [] });
  };

  render() {
    const { clientId, envId, tenant } = this.props;
    const { resourceId = '', permissions } = this.state;

    return (
      <Query<any, any> query={GET_AUTH_ROLES} variables={{ clientId, tenant }}>
        {({ loading, error, data }) => {
          if (loading) return <p>Loading...</p>;
          if (error) return <Error error={error} />;

          const existingResources = _.get(data, 'authRoles', []).reduce(
            (acc: any[], r: { status: string; resource: any }) => {
              ['approved', 'pending'].includes(r.status) &&
                acc.push(_.get(r.resource, 'resourceId'));
              return acc;
            },
            []
          );

          return (
            <Fragment>
              <ChooseResource
                envId={envId}
                excludedResources={existingResources}
                onChange={this.handleResourceChange}
              />
              {resourceId && (
                <Card>
                  <Card.Content>
                    <Card.Header>
                      <Icon name="arrow alternate circle up outline" />
                      Request Resource
                    </Card.Header>
                  </Card.Content>
                  <Card.Content>
                    <ChooseResourcePermissions
                      onPermissionChange={this.handlePermissionEdit}
                      onUserChange={this.handleUserEdit}
                      onRemove={this.reset}
                      resourceId={resourceId}
                      selectedPermissions={permissions}
                      showRemove={false}
                      tenant={tenant}
                    />
                  </Card.Content>
                  <Card.Content>
                    <Button.Group basic widths="2">
                      <Button basic fluid onClick={this.reset}>
                        <Icon name="remove" />
                        Cancel
                      </Button>
                      <Button
                        id="request-resource-button"
                        basic
                        color="green"
                        fluid
                        onClick={this.handleSave}
                      >
                        <Icon name="save" />
                        Request
                      </Button>
                    </Button.Group>
                  </Card.Content>
                </Card>
              )}
            </Fragment>
          );
        }}
      </Query>
    );
  }

  static propTypes = {
    clientId: PropTypes.string.isRequired,
    envId: PropTypes.string.isRequired,
    onSave: PropTypes.func,
    tenant: PropTypes.string.isRequired,
  };

  static defaultProps = {
    onSave: () => {},
  };
}

export default AddUserAuth;
