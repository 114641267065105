import React from 'react';
import { Container, Grid } from 'semantic-ui-react';
import PropTypes from 'prop-types';

const GridLayout = ({ children, isPadded }) => (
  <Container fluid>
    <Grid stackable>
      {isPadded ? (
        <Grid.Row style={{ padding: '0 2rem 5rem', marginTop: '4rem' }}>
          <Grid.Column>{children}</Grid.Column>
        </Grid.Row>
      ) : (
        <Grid.Row>
          <Grid.Column
            style={{
              marginTop: '3em',
            }}
          >
            {children}
          </Grid.Column>
        </Grid.Row>
      )}
    </Grid>
  </Container>
);

GridLayout.propTypes = {
  children: PropTypes.node.isRequired,
  isPadded: PropTypes.bool,
};

GridLayout.defaultProps = {
  title: '',
  subtitle: '',
  icon: '',
  iconColor: '',
  isPadded: true,
};

export default GridLayout;
