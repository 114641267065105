import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Button, Icon, Transition } from 'semantic-ui-react';
import { Mutation } from 'react-apollo';
import { toast } from 'react-toastify';
import gql from 'graphql-tag';
import getErrorMessages from '../../util/errors';

import ModalState from '../../layouts/ModalState';
import ModalForm from '../../layouts/ModalForm';
import TeamForm, { TEAM_FORM_ID } from './TeamForm';
import SearchParam from '../../components/SearchParam';

const CREATE_TEAM = gql`
  mutation CreateTeam_CreateTeam($name: String!, $description: String!, $members: [String!]!) {
    addTeam(team: { name: $name, description: $description, members: $members }) {
      success
      error {
        message
        code
      }
      team {
        id
      }
    }
  }
`;

const GET_ALL_TEAMS_DETAILED = gql`
  query CreateTeam_GetTeams {
    teams {
      id
      name
      description
      apps {
        id
      }
      members {
        id
      }
    }
  }
`;

const ME = gql`
  query CreateTeam_GetMe {
    me {
      id
      isAdmin
      firstName
      lastName
      email
      jobFamily
      hash
      teams {
        id
        name
        description
      }
    }
  }
`;

class CreateTeam extends React.Component {
  handleMutationComplete = data => {
    const { history } = this.props;
    history.push(`/team/${data.addTeam.team.id}`);
  };

  render() {
    const { me } = this.props;

    return (
      <SearchParam param="create" defaultValue={0}>
        {createParam => (
          <ModalState>
            {(modalOpen, toggleModal) => (
              <Fragment>
                <Transition
                  animation="jiggle"
                  duration="1500"
                  transitionOnMount={+createParam === 1}
                >
                  <Button id="registerTeamBtn" primary onClick={toggleModal}>
                    <Icon name="add" />
                    Register a Team
                  </Button>
                </Transition>
                {modalOpen && (
                  <Mutation
                    mutation={CREATE_TEAM}
                    refetchQueries={[
                      {
                        query: GET_ALL_TEAMS_DETAILED,
                      },
                      {
                        query: ME,
                      },
                    ]}
                    onCompleted={d => {
                      this.handleMutationComplete(d);
                      toast.success('Successfully created team!');
                    }}
                    onError={error =>
                      toast.error(`Error encountered: ${getErrorMessages(error)}`, {
                        autoClose: false,
                      })
                    }
                  >
                    {(addTeam, { loading, error }) => {
                      const errors = getErrorMessages(error);

                      return (
                        <ModalForm
                          header="Register a Team"
                          actionAttr={{
                            positive: true,
                            type: 'submit',
                            form: TEAM_FORM_ID,
                          }}
                          loading={loading}
                          open={modalOpen}
                          toggleModal={toggleModal}
                          errors={errors}
                          actionText="Register Team"
                        >
                          <TeamForm onSubmit={addTeam} loading={loading} me={me} errors={errors} />
                        </ModalForm>
                      );
                    }}
                  </Mutation>
                )}
              </Fragment>
            )}
          </ModalState>
        )}
      </SearchParam>
    );
  }
}

CreateTeam.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  me: PropTypes.object.isRequired,
};

export default withRouter(CreateTeam);
