import React, { Fragment } from 'react';
import { Tab, Message, Icon, Card } from 'semantic-ui-react';
import { Query } from 'react-apollo';
import PropTypes from 'prop-types';

import RequestFilter from './RequestFilter';
import Request from './Request';
import EmptyInbox from './EmptyInbox';

import gql from 'graphql-tag';
import Error from '../../components/Error';
import { groupObjArrayByProp } from '../../util';

import PageLoader from '../../components/PageLoader';

const ME_INCOMING_REQUESTS_BY_STATUS = gql`
  query RequestsPane_GetMyIncomingRequestsByStatus($requestStatus: String = "") {
    me {
      id
      incomingResourceRequestsByStatus(requestStatus: $requestStatus) {
        id
        status {
          name
        }
        scopes
        resource {
          resourceId: resource_id
          name
          identifier
          scopes {
            name: value
            description
          }
        }
        resourceApp {
          id
          name
        }
        resourceEnvironment {
          name
          isProduction
        }
        client {
          clientId: client_id
          name
          description
        }
        clientApp {
          id
          name
        }
        clientEnvironment {
          name
          isProduction
        }
        requestor {
          id
          firstName
          lastName
          email
          jobFamily
        }
        requestTypeId
      }
    }
  }
`;

const ME_OUTGOING_REQUESTS_BY_STATUS = gql`
  query RequestsPane_GetMyOutgoingRequestsByStatus($requestStatus: String = "") {
    me {
      id
      outgoingResourceRequestsByStatus(requestStatus: $requestStatus) {
        id
        status {
          name
        }
        scopes
        resource {
          resourceId: resource_id
          name
          identifier
          scopes {
            name: value
            description
          }
        }
        resourceApp {
          id
          name
        }
        resourceEnvironment {
          name
          isProduction
        }
        client {
          clientId: client_id
          name
          description
        }
        clientApp {
          id
          name
        }
        clientEnvironment {
          name
          isProduction
        }
        requestor {
          id
          firstName
          lastName
          email
          jobFamily
        }
      }
    }
  }
`;

class RequestsPane extends React.Component {
  constructor(props) {
    super(props);
    this.state = { viewStatus: 'pending' };
  }

  handleFilterChange = (e, { value }) => {
    this.setState({ viewStatus: value });
  };

  render() {
    const { viewStatus } = this.state;
    const { outgoing } = this.props;
    const query = outgoing ? ME_OUTGOING_REQUESTS_BY_STATUS : ME_INCOMING_REQUESTS_BY_STATUS;
    return (
      <Tab.Pane as="div">
        <RequestFilter status={viewStatus} handleFilterChange={this.handleFilterChange} />
        <Query query={query} variables={{ requestStatus: viewStatus }}>
          {({ loading, error, data }) => {
            if (loading)
              return (
                <PageLoader
                  inline="centered"
                  content={outgoing ? 'loading transmission log' : 'checking for distress signals'}
                />
              );
            if (error) return <Error error={error} />;
            const { me } = data;
            const resourceRequests = outgoing
              ? me.outgoingResourceRequestsByStatus
              : me.incomingResourceRequestsByStatus;
            const groupedResourceRequests = outgoing
              ? groupObjArrayByProp(resourceRequests, 'clientApp')
              : groupObjArrayByProp(resourceRequests, 'resourceApp');
            let requestsFound = false;
            return (
              <Fragment>
                {outgoing && viewStatus === 'pending' && (
                  <Message warning>
                    <Icon name="warning sign" />
                    Please contact the admin(s) of the resource directly to request approval.
                  </Message>
                )}
                <Card.Group itemsPerRow={3}>
                  {Object.keys(groupedResourceRequests).map(key => {
                    const group = groupedResourceRequests[key];
                    if (group.find(r => r.status.name === viewStatus)) requestsFound = true;
                    return (
                      <Fragment key={key}>
                        {group
                          .filter(r => r.status.name === viewStatus)
                          .map(r =>
                            outgoing ? (
                              <Request key={r.id} request={r} outgoing />
                            ) : (
                              <Request key={r.id} request={r} />
                            )
                          )}
                      </Fragment>
                    );
                  })}
                  {!requestsFound && <EmptyInbox />}
                </Card.Group>
              </Fragment>
            );
          }}
        </Query>
      </Tab.Pane>
    );
  }
}

RequestsPane.propTypes = {
  outgoing: PropTypes.bool,
};

RequestsPane.defaultProps = {
  outgoing: false,
};

export default RequestsPane;
