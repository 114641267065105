import React from 'react';
import { Container, Icon, Header } from 'semantic-ui-react';

const EmptyInbox = () => (
  <Container textAlign="center" style={{ paddingTop: '5em' }}>
    <Icon name="inbox" size="massive" color="grey" />
    <Header color="grey">No requests found.</Header>
  </Container>
);

export default EmptyInbox;
