import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';
import {
  Button,
  Divider,
  Icon,
  Label,
  Message,
  Segment,
  Popup,
  Checkbox,
  Header,
} from 'semantic-ui-react';
import { toast } from 'react-toastify';
import _ from 'lodash';

import gql from 'graphql-tag';
import ModalState from '../../layouts/ModalState';
import ModalForm from '../../layouts/ModalForm';
import CreateClientForm, { CLIENT_FORM_ID } from './CreateClientForm';
import { clipboardCopy } from '../../util';
import getErrorMessages from '../../util/errors';

const CREATE_CLIENT = gql`
  mutation CreateClient_CreateClient(
    $webOrigins: [String]
    $callbacks: [String]
    $authClientType: String!
    $description: String
    $envId: ID!
    $logoutUrls: [String]
    $name: String!
    $resource: [ResourceInput]
    $appId: ID!
  ) {
    createClient(
      web_origins: $webOrigins
      allowed_logout_urls: $logoutUrls
      app_type: $authClientType
      callbacks: $callbacks
      description: $description
      envId: $envId
      name: $name
      resource: $resource
      appId: $appId
    ) {
      success
      error {
        message
        code
      }
      client {
        clientId: client_id
        clientSecret: client_secret
      }
    }
  }
`;

const GET_ENV_CLIENTS = gql`
  query CreateClient_GetEnvClients($envs: [ID]!) {
    envClients(envs: $envs) {
      webOrigins: web_origins
      callbacks
      clientId: client_id
      description
      envId: environment_id
      logoutUrls: allowed_logout_urls
      name
      tenant
    }
  }
`;

class CreateClient extends Component {
  constructor(props) {
    super(props);

    this.initialState = {
      clientSecret: 'ERROR',
      created: false,
      actionDisabled: true,
      checked: false,
      closeOnEscape: true,
    };

    this.state = this.initialState;
  }

  handleChecked = () => {
    if (!this.state.checked) {
      this.setState({
        actionDisabled: false,
        checked: true,
        closeOnEscape: true,
      });
    } else
      this.setState({
        actionDisabled: true,
        checked: false,
        closeOnEscape: false,
      });
  };
  handleCreate = data => {
    const clientSecret = _.get(data, 'createClient.client.clientSecret');
    this.setState({ clientSecret, created: true });
  };

  resetStateAndClose = toggleModal => () => {
    this.setState(this.initialState);
    toggleModal();
  };

  render() {
    const { appId, appName, appDesc, environments, appType } = this.props;
    const { clientSecret, created, actionDisabled, closeOnEscape, checked } = this.state;
    const envs = environments.map(e => e.id);

    return (
      <ModalState>
        {(modalOpen, toggleModal) => (
          <Fragment>
            {envs.length > 0 ? (
              <Button id="create-client" primary onClick={toggleModal}>
                <Icon name="add" />
                Register a Client
              </Button>
            ) : (
              <Popup
                flowing
                trigger={
                  <span>
                    <Button primary disabled>
                      <Icon name="add" />
                      Register a Client
                    </Button>
                  </span>
                }
                content="You must register at least one environment before registering a client."
              />
            )}

            {modalOpen && (
              <Mutation
                mutation={CREATE_CLIENT}
                refetchQueries={[
                  {
                    query: GET_ENV_CLIENTS,
                    variables: { envs },
                  },
                ]}
                onCompleted={d => {
                  this.handleCreate(d);
                  toast.success('Successfully created client!');
                }}
                onError={error =>
                  toast.error(`Error encountered: ${getErrorMessages(error)}`, {
                    autoClose: false,
                  })
                }
                awaitRefetchQueries
              >
                {(addClient, { loading, error }) => {
                  const errors = getErrorMessages(error);

                  return created ? (
                    <ModalForm
                      actionAttr={{
                        onClick: this.resetStateAndClose(toggleModal),
                        positive: true,
                      }}
                      actionDisabled={actionDisabled}
                      id="client-secret"
                      actionText="Agree"
                      cancel={false}
                      errors={errors}
                      header={
                        <Header as="h3">
                          <Icon name="exclamation circle" color="red" size="large" />
                          Wait, before you close this modal!
                        </Header>
                      }
                      loading={loading}
                      open={modalOpen}
                      toggleModal={toggleModal}
                      closeOnEscape={closeOnEscape}
                    >
                      <Fragment>
                        <Message warning>
                          <Message.Content>
                            <Header as="h3">
                              <Icon name="exclamation circle" color="red" size="large" />
                              Client Secrets will be lost forever if not saved now.
                            </Header>
                            <Divider />
                            <Header as="p">
                              Below is your client secret. Please copy it and keep it secure. This
                              is the only time you will see this information. Secrets (including but
                              not limited to: API keys, username/password credentials, auth tokens,
                              certificates, and private keys) are not permitted to be stored in code
                              repositories, persisted in plaintext, or transmitted programmatically
                              or manually over insecure channels.
                            </Header>
                          </Message.Content>
                          <Divider />
                          <Message.Content id="consentCheckBox">
                            <Checkbox
                              label="I have read and agree to the terms and conditions of the above statement."
                              onChange={this.handleChecked}
                              checked={checked}
                            />
                          </Message.Content>
                        </Message>

                        <Divider />
                        <Segment basic textAlign="center">
                          <Label horizontal size="big">
                            <Label.Detail>{clientSecret}</Label.Detail>
                            <Label.Detail id="copyClientId">
                              <Icon
                                link
                                name="clone outline"
                                onClick={() => clipboardCopy(clientSecret)}
                              />
                            </Label.Detail>
                          </Label>
                        </Segment>
                      </Fragment>
                    </ModalForm>
                  ) : (
                    <ModalForm
                      header={`Register a Client for: ${appName}`}
                      actionAttr={{
                        positive: true,
                        type: 'submit',
                        form: CLIENT_FORM_ID,
                      }}
                      errors={errors}
                      actionText="Register Client"
                      loading={loading}
                      open={modalOpen}
                      toggleModal={toggleModal}
                    >
                      <CreateClientForm
                        appId={appId}
                        appName={appName}
                        appDesc={appDesc}
                        addClient={addClient}
                        appType={appType}
                        environments={environments}
                        errors={errors}
                      />
                    </ModalForm>
                  );
                }}
              </Mutation>
            )}
          </Fragment>
        )}
      </ModalState>
    );
  }
}

CreateClient.propTypes = {
  appName: PropTypes.string.isRequired,
  appId: PropTypes.string.isRequired,
  environments: PropTypes.arrayOf(PropTypes.object),
  appType: PropTypes.object,
};

CreateClient.defaultProps = {
  environments: [],
  appType: {},
};

CreateClient.displayName = 'CreateClient';

export default CreateClient;
