import React, { Fragment, useState } from 'react';
import { Divider, Header, Label, List, Icon, Grid, Card } from 'semantic-ui-react';
import { Query } from 'react-apollo';
import PropTypes from 'prop-types';
import gql from 'graphql-tag';
import CreateClient from '../../auth-clients/CreateClient';
import UpdateClient from '../../auth-clients/UpdateClient';
import DeleteClient from '../../auth-clients/DeleteClient';
import ClientGrants from '../../../components/auth/ClientGrants';
import Error from '../../../components/Error';
import { clipboardCopy } from '../../../util';
import ManageUserAuth from '../../../components/auth/ManageUserAuth';
import AppAccordion from '../../../components/AppAccordion';
import EmptyResults from '../../../components/EmptyResults';
import RotateSecret from '../../auth-clients/RotateSecret';
import ChooseClientDropdown from '../../../components/AuthResources/ChooseClientDropdown';

const GET_ENV_CLIENTS = gql`
  query ClientTab_GetEnvClients($envs: [ID]!) {
    envClients(envs: $envs) {
      webOrigins: web_origins
      callbacks
      clientId: client_id
      description
      envId: environment_id
      logoutUrls: allowed_logout_urls
      name
      tenant
    }
  }
`;

export function ClientTab(props) {
  const [client, setClient] = useState(undefined);

  const getClientPane = (environment = {}, client = {}) => {
    const { appType, meIsOwner, environments } = props;
    const {
      webOrigins,
      callbacks,
      clientId,
      name,
      description,
      envId,
      logoutUrls,
      tenant,
    } = client;
    return (
      <List divided relaxed key={`env-${environment.id}`}>
        <Divider hidden />
        <Header as="h2" floated="left">
          <Icon name="world" color={environment.isProduction ? 'teal' : 'grey'} />
          <Header.Content>
            {name}
            <Header.Subheader>
              {environment.isProduction ? 'Production' : 'Non-Production'}
            </Header.Subheader>
          </Header.Content>
        </Header>
        <Header as="h2" floated="right">
          {meIsOwner && (
            <List.Content>
              <UpdateClient
                appType={appType}
                callbacks={callbacks}
                webOrigins={webOrigins}
                clientId={clientId}
                description={description}
                environments={environments}
                logoutUrls={logoutUrls}
                name={name}
                tenant={tenant}
              />
              <DeleteClient
                clientId={clientId}
                environments={environments}
                name={name}
                tenant={tenant}
              />
            </List.Content>
          )}
        </Header>

        <List>
          <Card key={`client-${clientId}`} style={{ padding: '2rem' }} fluid>
            <List.Item id={clientId}>
              <List.Content>
                <Grid celled="internally">
                  <Grid.Row columns={2}>
                    <Grid.Column verticalAlign="middle">
                      <Label horizontal size="large">
                        Environment:
                        <Label.Detail>{environment.name}</Label.Detail>
                      </Label>
                      <Label horizontal size="large" style={{ marginTop: '20px' }}>
                        Tenant URL:
                        <Label.Detail>https://{environment.tenant}</Label.Detail>
                      </Label>
                    </Grid.Column>
                    <Grid.Column verticalAlign="middle">
                      <Label horizontal size="large">
                        <Icon link name="clone outline" onClick={() => clipboardCopy(clientId)} />
                        ClientId:
                        <Label.Detail>{clientId}</Label.Detail>
                      </Label>
                      {appType.name !== 'Single Page App' && (
                        <Label horizontal size="large" style={{ marginTop: '20px' }}>
                          {meIsOwner && (
                            <RotateSecret clientId={clientId} tenant={environment.tenant} />
                          )}
                          Client Secret:
                          <Label.Detail>{'*****************************************'}</Label.Detail>
                        </Label>
                      )}
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column columns={1}>
                      {appType.showRoles && (
                        <ManageUserAuth
                          clientId={clientId}
                          envId={envId}
                          meIsOwner={meIsOwner}
                          tenant={tenant}
                        />
                      )}
                      {appType.showClientGrants && (
                        <ClientGrants
                          clientId={clientId}
                          envId={envId}
                          meIsOwner={meIsOwner}
                          tenant={tenant}
                        />
                      )}
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </List.Content>
            </List.Item>
          </Card>
        </List>
      </List>
    );
  };

  const listByEnv = (environment = {}, envClients = []) => {
    const clients = envClients.filter(c => c.envId === environment.id);
    if (!clients.length) {
      return null;
    }
    environment.clients = clients;
    return environment;
  };

  const { appId, appName, appDesc, appType, meIsOwner, environments } = props;
  const envs = environments.map(e => e.id);

  const accordionItems = [
    {
      accordionDetails:
        'A client is any application that needs to request data or access the account of a user. Examples include:',
      accordionTitle: 'What is a client?',
      accordionItemText: [
        {
          key: 'spas',
          title: 'Single-Page Apps (SPAs):',
          details:
            'JavaScript applications that perform most of their user interface logic in a web browser, communicating with a web server primarily using APIs (e.g. AngularJS, React).',
        },
        {
          key: 'reg-web-app',
          title: 'Regular Web Apps:',
          details:
            'Traditional web applications that perform most of their application logic on the server (e.g. Express.js, ASP.NET).',
        },
        {
          key: 'm2m',
          title: 'Machine-to-Machine (M2M) Apps:',
          details:
            'Non-interactive applications, such as command-line tools, daemons, IoT devices, or services running on the back-end.',
        },
      ],
    },
  ];

  let envClients = undefined;

  const handleButtonClick = (_, data) => {
    const clickedClient = envClients.find(c => c.clientId === data.value);
    setClient(clickedClient);
  };

  if (!envs) {
    return <Error errors={['Please register an environment before setting up authentication.']} />;
  }

  return (
    <Query query={GET_ENV_CLIENTS} variables={{ envs }}>
      {({ loading, error, data }) => {
        if (loading) return <p>Loading...</p>;
        if (error) return <Error error={error} />;
        let envWithClients,
          first = undefined;
        if (data.envClients) {
          envWithClients = environments.map(env => listByEnv(env, data.envClients));
          first = envWithClients.find(e => e && e.clients.length > 0);
        }
        // use first env with clients as default
        let thisEnv = undefined;
        let i,
          j = 0;
        if (client) {
          for (i = 0; i < envWithClients.length; i++) {
            if (envWithClients[i]) {
              for (j = 0; j < envWithClients[i].clients.length; j++) {
                if (envWithClients[i].clients[j].clientId === client.clientId)
                  thisEnv = envWithClients[i];
              }
            }
          }
        }
        envClients = data.envClients;
        return (
          <Fragment>
            <AppAccordion accordionItems={accordionItems} />
            <Divider hidden />
            <Header floated="left">
              {meIsOwner && (
                <CreateClient
                  appId={appId}
                  appName={appName}
                  appDesc={appDesc}
                  environments={environments}
                  appType={appType}
                />
              )}
            </Header>
            <Header floated="right">
              <ChooseClientDropdown
                floated="right"
                envs={envWithClients}
                handleClick={handleButtonClick}
                selectedClientName={
                  first
                    ? 'Select a client: ' + (client ? client.name : first.clients[0].name)
                    : 'No clients'
                }
              />
            </Header>
            <Divider hidden style={{ marginTop: '70px' }} />
            {client ? (
              getClientPane(thisEnv, client)
            ) : first ? (
              getClientPane(first, first.clients[0])
            ) : (
              <EmptyResults />
            )}
          </Fragment>
        );
      }}
    </Query>
  );
}

ClientTab.displayName = 'ClientTab';

ClientTab.propTypes = {
  appName: PropTypes.string,
  appId: PropTypes.string.isRequired,
  environments: PropTypes.arrayOf(PropTypes.object),
  appType: PropTypes.object,
  meIsOwner: PropTypes.bool,
};

ClientTab.defaultProps = {
  appName: '',
  environments: [],
  appType: {},
  meIsOwner: false,
};

export default ClientTab;
