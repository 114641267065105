import React from 'react';
import PropTypes from 'prop-types';
import { Message } from 'semantic-ui-react';
import getErrorMessages from '../util/errors';

interface IProps {
  error?: object | null;
  errors?: string[];
  wrapper?: any;
  header?: string;
}

const Error: React.SFC<IProps> = ({ error, errors, wrapper, ...props }) => {
  let errs = errors || [];

  if (error) {
    errs = errs.concat(getErrorMessages(error));
  }

  if (!errs || errs.length === 0) {
    return null;
  }

  const content =
    errs.length === 1 ? (
      errs[0]
    ) : (
      <ul>
        {errs.map((err: string) => (
          <li key={err}>{err}</li>
        ))}
      </ul>
    );

  if (!wrapper) {
    return <Message negative content={content} {...props} />;
  }

  return React.cloneElement(wrapper, props, content);
};

Error.propTypes = {
  error: PropTypes.object,
  errors: PropTypes.array,
  wrapper: PropTypes.element,
};

Error.defaultProps = {
  error: null,
  errors: [],
  wrapper: null,
};

export default Error;
