import React, { FC, ReactElement, Fragment } from 'react';
import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import { Grid, Header, List, Container } from 'semantic-ui-react';
import { PieChart, Pie, Cell, PieLabelRenderProps, ResponsiveContainer, Tooltip } from 'recharts';
import { theme } from '../../styles/theme';
import { Link } from 'react-router-dom';

const GET_HIGH_USAGE_CLIENTS = gql`
  query HighUsage_GetHighUsageClients {
    highUsageClients {
      clientId: client_id
      name
      app {
        id
        name
        team {
          id
          name
        }
      }
      tenant
      yesterdaysTokens
    }
  }
`;

const COLORS = [
  theme.colors.green,
  theme.colors.purple,
  theme.colors.notificationYellow,
  theme.colors.primary,
];

const RADIAN = Math.PI / 180;

const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
}: PieLabelRenderProps) => {
  if (
    typeof innerRadius !== 'number' ||
    typeof outerRadius !== 'number' ||
    typeof midAngle !== 'number' ||
    typeof percent !== 'number' ||
    typeof cx !== 'number' ||
    typeof cy !== 'number'
  ) {
    return;
  }
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const CustomTooltip = ({
  payload,
  active,
}: {
  payload: { name: string; value: number }[];
  active?: boolean;
}) => {
  if (!payload) return <div></div>;
  if (active) {
    return (
      <div>
        <div className="ant-popover-arrow" />
        <div className="recharts-custom-tooltip">
          <b>{payload[0].name}</b>
          <span>
            <p className="desc">
              <small>Tokens: {payload[0].value}</small>
            </p>
          </span>
        </div>
      </div>
    );
  }
  return <div />;
};

export interface IHighUsageClient {
  clientId: string;
  name: string;
  app: { id: string; name: string; team: { id: string; name: string } };
  tenant: string;
  yesterdaysTokens: number;
}

const HighUsage: FC = (): ReactElement => {
  const { loading, error, data } = useQuery<{
    highUsageClients?: IHighUsageClient[];
  }>(GET_HIGH_USAGE_CLIENTS);

  if (loading) return <Fragment />;
  if (error || !data) return <Fragment />;
  const { highUsageClients } = data;

  if (!highUsageClients) return <div>No high usage found.</div>;

  const chartData = highUsageClients.map(huc => {
    return {
      name: huc.name,
      value: huc.yesterdaysTokens,
    };
  });

  const sortedClients = highUsageClients.sort((a, b) => {
    if ((a.yesterdaysTokens || 0) < (b.yesterdaysTokens || 0)) {
      return 1;
    }
    if ((a.yesterdaysTokens || 0) > (b.yesterdaysTokens || 0)) {
      return -1;
    }
    return 0;
  });

  const teamUsage = highUsageClients.reduce(
    (acc: { [key: string]: { name: string; tokens: number } }, cur) => {
      if (!acc[cur.app.team.id]) {
        acc[cur.app.team.id] = { name: cur.app.team.name, tokens: cur.yesterdaysTokens };
        return acc;
      }
      acc[cur.app.team.id].tokens = acc[cur.app.team.id].tokens + cur.yesterdaysTokens;
      return acc;
    },
    {}
  );

  const teamData = Object.entries(teamUsage).map(([, { name, tokens }]) => {
    return {
      name,
      value: tokens,
    };
  });

  return (
    <Grid relaxed padded style={{ minHeight: '80vh' }} stackable>
      <Grid.Row style={{ margin: '2em' }}>
        <Container>
          <Header as="h1" textAlign="center">
            Yesterday&apos;s High Usage Clients
          </Header>
        </Container>
      </Grid.Row>
      <Grid padded>
        <Grid.Row>
          <Header as="h2" textAlign="center">
            Top Clients
          </Header>
        </Grid.Row>
        <Grid.Row columns={2}>
          <Grid.Column>
            <List ordered size="massive" relaxed>
              {sortedClients.map(huc => {
                return (
                  <List.Item key={huc.clientId}>
                    <List.Header>
                      {huc.name} ({huc.yesterdaysTokens})
                    </List.Header>
                    <List.Description>
                      <Link to={`/team/${huc.app.team.id}`}>{huc.app.team.name}</Link>
                    </List.Description>
                    <Link to={`/app/${huc.app.id}`}>{huc.app.name}</Link>
                  </List.Item>
                );
              })}
            </List>
          </Grid.Column>
          <Grid.Column>
            <ResponsiveContainer>
              <PieChart>
                <Pie
                  data={chartData}
                  dataKey="value"
                  labelLine={false}
                  label={renderCustomizedLabel}
                  fill="#8884d8"
                >
                  {chartData.map((entry, index) => {
                    return <Cell key={entry.name} fill={COLORS[index % COLORS.length]} />;
                  })}
                </Pie>
                <Tooltip content={<CustomTooltip payload={chartData} />} />
              </PieChart>
            </ResponsiveContainer>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Grid padded>
        <Grid.Row>
          <Header as="h2">Top Teams</Header>
        </Grid.Row>
        <Grid.Row columns={2}>
          <Grid.Column>
            <List ordered size="massive" relaxed>
              {Object.entries(teamUsage).map(([key, { name, tokens }]) => {
                return (
                  <List.Item key={key}>
                    <List.Header>
                      <Link to={`/team/${key}`}>{name}</Link>
                    </List.Header>
                    <List.Description>{tokens} Tokens</List.Description>
                  </List.Item>
                );
              })}
            </List>
          </Grid.Column>
          <Grid.Column>
            <ResponsiveContainer>
              <PieChart>
                <Pie
                  data={teamData}
                  dataKey="value"
                  labelLine={false}
                  label={renderCustomizedLabel}
                  fill="#8884d8"
                >
                  {teamData.map((entry, index) => {
                    return <Cell key={entry.name} fill={COLORS[index % COLORS.length]} />;
                  })}
                </Pie>
                <Tooltip content={<CustomTooltip payload={teamData} />} />
              </PieChart>
            </ResponsiveContainer>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Grid>
  );
};

export default HighUsage;
