import styled from '@emotion/styled';
import { Header as SemanticHeader, Card, Segment, Statistic } from 'semantic-ui-react';
import { theme } from '../styles/theme';

const Header = styled(SemanticHeader)`
  &&& {
    font-family: ${theme.fonts.headerFamily};
    font-weight: ${theme.fonts.headerWeight};
  }
`;

const CalloutIcon = styled.div`
  position: relative;
  border: 1px solid ${theme.colors.white};
  border-radius: 50%;
  margin: 0 auto;
  padding-top: 60px;
  width: 60px;
  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: ${theme.colors.white};
    width: auto;
  }
`;

const Article = styled.article`
  color: ${props => (props.inverted ? theme.colors.white : theme.colors.textColor)};
  font-size: ${props => (props.fontSize ? props.fontSize : '1rem')};
  margin-bottom: ${props => (props.spaced ? '2rem' : '0')};
  p.indented {
    text-indent: 2rem;
  }
`;

const CardGroup = styled(Card.Group)`
  &&& {
    margin: 1rem -0.75rem;
  }
`;

const LoaderContainer = styled(Segment)`
  &&& {
    border: none;
    box-shadow: none;
    min-height: 8rem;
    .loader:after {
      border-color: ${theme.colors.secondary} transparent transparent;
    }
  }
`;

// Article with Theme
const StyledArticle = styled.article`
  font-family: ${props => props.theme.fonts.bodyFamily};
  font-size: 1.2rem;
  letter-spacing: 0.5px;
  line-height: 1.5;
`;

const StyledStatsGroup = styled(Statistic.Group)`
  @media (max-width: ${props => props.theme.breakpoints.tablet}) {
    display: grid !important; // overwriting 'display: flex' set by semantic-ui
    grid-template: repeat(2, 1fr) / repeat(2, 1fr); // 2 x 2 boxes - eventually spaced
  }
`;

// WordPress Core Article Styles
const WordPressArticle = styled.article`
  margin: auto;
  margin-top: 0.5em;
  max-width: 65em;
  font-size: 1.5em;
  font-family: ${props => props.theme.fonts.bodyFamily};

  h1,
  h2,
  h3,
  h4,
  h5 {
    font-family: ${props => props.theme.fonts.headerFamily};
    font-weight: 900 !important;
    margin: 3rem 0 1.5rem;
  }

  p,
  li {
    line-height: 1.75;
    font-size: 1.2rem;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  .wp-block-image {
    margin-top: 2rem;
  }

  figure {
    margin: 1rem auto;
    overflow: hidden;
    &.aligncenter {
      border: none;
    }
  }

  a {
    &:hover {
      text-decoration: underline;
    }
  }

  .ui.embed,
  .media-element-container {
    border-radius: 0.25em;
    overflow: hidden;
    img {
      border-radius: 0.25em;
    }
  }

  .date {
    margin: 1rem 0;
    font-size: 1rem;
    color: ${props => props.theme.colors.darkGrey};
  }

  figcaption {
    text-align: center;
    font-size: 0.9rem;
    padding: 1rem 2rem;
  }

  .alignnone {
    margin: 5px 20px 20px 0;
  }

  .aligncenter,
  div.aligncenter {
    display: block;
    margin: 5px auto 5px auto;
    text-align: center;
  }

  .alignright {
    float: right;
    margin: 5px 0 20px 20px;
  }

  .alignleft {
    float: left;
    margin: 5px 20px 20px 0;
  }

  a img.alignright {
    float: right;
    margin: 5px 0 20px 20px;
  }

  a img.alignnone {
    margin: 5px 20px 20px 0;
  }

  a img.alignleft {
    float: left;
    margin: 5px 20px 20px 0;
  }

  a img.aligncenter {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .wp-block-embed__wrapper {
    position: relative;
    padding-top: 50%;
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .wp-caption {
    background: ${props => props.theme.colors.white};
    border: 1px solid ${props => props.theme.colors.lightgrey};
    max-width: 96%; /* Image does not overflow the content area */
    padding: 5px 3px 10px;
    text-align: center;
  }

  .wp-caption.alignnone {
    margin: 5px 20px 20px 0;
  }

  .wp-caption.alignleft {
    margin: 5px 20px 20px 0;
  }

  .wp-caption.alignright {
    margin: 5px 0 20px 20px;
  }

  .wp-caption img {
    border: 0 none;
    height: auto;
    margin: 0;
    max-width: 98.5%;
    padding: 0;
    width: auto;
  }

  .wp-caption p.wp-caption-text {
    font-size: 11px;
    line-height: 17px;
    margin: 0;
    padding: 0 4px 5px;
  }

  /* Text meant only for screen readers. */
  .screen-reader-text {
    border: 0;
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute !important;
    width: 1px;
    word-wrap: normal !important; /* Many screen reader and browser combinations announce broken words as they would appear visually. */
  }

  .screen-reader-text:focus {
    background-color: ${props => props.theme.colors.mediumgrey};
    clip: auto !important;
    clip-path: none;
    color: ${props => props.theme.colors.black};
    display: block;
    font-size: 1em;
    height: auto;
    left: 5px;
    line-height: normal;
    padding: 15px 23px 14px;
    text-decoration: none;
    top: 5px;
    width: auto;
    z-index: 100000;
  }

  .wp-block-code {
    font-size: 0.6em;
    border: 1px solid ${props => props.theme.colors.mediumgrey};
    border-radius: 4px;
  }

  .wp-block-code,
  .wp-block-preformatted pre {
    font-family: Menlo, Consolas, monaco, monospace;
    color: ${props => props.theme.colors.textColor};
  }

  .wp-block-quote {
    border-left: 2px solid ${props => props.theme.colors.teal};
    margin: 20px 0;
    padding-left: 1em;
    padding-top: 0;
    padding-bottom: 0;
  }
`;

// Large Container
const LargeContainer = styled.div`
  max-width: 1400px;
  margin: auto;
`;

export {
  Header,
  CalloutIcon,
  Article,
  CardGroup,
  LoaderContainer,
  StyledArticle,
  StyledStatsGroup,
  WordPressArticle,
  LargeContainer,
};
