import APIIcon from '../styles/auth0_icons/icon-api.svg';
import SDKIcon from '../styles/auth0_icons/icon-sdks.svg';
import MobileIcon from '../styles/auth0_icons/icon-mobile-app.svg';
import WebIcon from '../styles/auth0_icons/icon-web-app.svg';
import CLIIcon from '../styles/auth0_icons/icon-cli-script.svg';
import SPAIcon from '../styles/auth0_icons/icon-spa.svg';
import SocialIcon from '../styles/auth0_icons/icon-social-providers.svg';
import React from 'react';

export const clipboardCopy = stringToCopy => {
  navigator.clipboard.writeText(stringToCopy);
};

export const groupObjArrayByProp = (objArray, prop) =>
  objArray.reduce((acc, obj) => {
    const key = obj[prop].id;
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(obj);
    return acc;
  }, {});

export const getAppTypeIcon = name => {
  switch (name) {
    case 'API':
      return <APIIcon />;
    case 'Library':
      return <SDKIcon />;
    case 'Native':
      return <MobileIcon />;
    case 'Regular Web App':
      return <WebIcon />;
    case 'Script':
      return <CLIIcon />;
    case 'Single Page App':
      return <SPAIcon />;
    default:
      return <SocialIcon />;
  }
};

export default {
  clipboardCopy,
  groupObjArrayByProp,
  getAppTypeIcon,
};
