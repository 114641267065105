import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Accordion, Icon, List } from 'semantic-ui-react';
import ReactMarkdown from 'react-markdown';

interface IAccordionItem {
  accordionTitle: string;
  accordionDetails: string;
  accordionItemText: {
    key: string;
    title: string;
    details: string;
  }[];
  index?: number;
}

interface IProps {
  title: string;
  listItems: any[];
  accordionItems: IAccordionItem[];
  id?: string;
}

interface IState {
  activeIndex: number;
}

class AppAccordion extends Component<IProps, IState> {
  state = {
    activeIndex: -1,
  };

  handleAccordionClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, titleProps: any) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };

  listItems = (listItems: { key: string; title: string; details: string }[]) => (
    <List bulleted>
      {listItems.map(item => (
        <List.Item key={`item-${item.key}`}>
          <strong>{item.title}</strong>
          <ReactMarkdown source={item.details} escapeHtml={false} />
        </List.Item>
      ))}
    </List>
  );

  listAccordionItems = (accordionItems: IAccordionItem[], activeIndex: number) => (
    <Accordion fluid styled>
      {accordionItems.map((item, index) => (
        <Fragment key={item.accordionTitle}>
          <Accordion.Title
            active={activeIndex === index}
            index={index}
            onClick={this.handleAccordionClick}
            id={this.props.id + String(index)}
          >
            <Icon name="dropdown" />
            <Icon name="info circle" />
            {item.accordionTitle}
          </Accordion.Title>
          <Accordion.Content active={activeIndex === index}>
            <p>{item.accordionDetails}</p>
            {this.listItems(item.accordionItemText)}
          </Accordion.Content>
        </Fragment>
      ))}
    </Accordion>
  );

  render() {
    const { accordionItems } = this.props;
    const { activeIndex } = this.state;

    return <Fragment>{this.listAccordionItems(accordionItems, activeIndex)}</Fragment>;
  }

  static propTypes = {
    id: PropTypes.string,
    title: PropTypes.string,
    listItems: PropTypes.arrayOf(PropTypes.object),
    accordionItems: PropTypes.arrayOf(
      PropTypes.shape({
        accordionTitle: PropTypes.string.isRequired,
        accordionDetails: PropTypes.string.isRequired,
        accordionItemText: PropTypes.arrayOf(PropTypes.object).isRequired,
      })
    ),
  };

  static defaultProps = {
    title: '',
    listItems: [],
    accordionItems: [],
  };
}

export default AppAccordion;
