import React, { Fragment } from 'react';
import { Header, LabelGroup, Label } from 'semantic-ui-react';
import { statuses } from '../../constants';

const RequestFilter = ({ status, handleFilterChange }) => (
  <Fragment>
    <Header sub as="span" style={{ marginRight: '1em' }}>
      Filter by:
    </Header>
    <LabelGroup as="span">
      {statuses.map(s => {
        if (s !== status) {
          return <Label key={s} value={s} as="a" content={s} onClick={handleFilterChange} />;
        }
        return <Label key={s} value={s} as="a" content={s} color="teal" />;
      })}
    </LabelGroup>
  </Fragment>
);
export default RequestFilter;
